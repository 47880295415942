import {observable, action, computed} from "mobx";
import {Passenger} from "app/stores/passenger";

class PassengersStore {
    @observable passengers = [];

    constructor(root) {
        this.root = root;
    }

    @action createPassengers(passengersInfo) {
        const a = passengersInfo.map((p) => {
            return new Passenger(p.id, p.fullName, p.title, p.gender, p.passengerType);
        });

        this.passengers.replace(a);
    }

    @computed get passengersExcludeInfants() {
        return this.passengers.filter((p) => {
            return "infant" !== p.title.toLowerCase();
        });
    }

    @computed get totalSeatsPrice() {
        let total = 0;
        this.passengers.forEach((p) => {
            if (p) {
            p.seats.forEach((seat) => {
                total += (seat) ? seat.price : 0;
            })
        }
            else {
                console.log('error occurred');
            }
        })

        return total;
    }
}

export {PassengersStore}