import React from 'react';
import {Popup} from "app/desktop/ui/components/popup/popup";
import {inject,observer} from "mobx-react";
import { ReactComponent as StarIcon } from "app/desktop/images/icons/star-icon.svg";
import BlueBirdVipLogo from "app/desktop/ui/components/bluebird-vip-logo/bluebird.vip.logo";
import 'app/desktop/ui/components/dialogs/dlg-extra-services-details/dlg.extra.services.details.scss';

@inject ("dashboardUIStore", "extraServicesStore") @observer
class DlgExtraServicesDetails extends React.Component {
    render() {
        const dlg = this.props.dashboardUIStore.extraServicesDetailsDialog;
        const extrs = this.props.extraServicesStore;

        return (
            <Popup isOpen={dlg.isOpen} onClose={() => {
                dlg.closePopup();
            }}>
                <div className={"dlg-body dlg-extra-services-details"}>
                    <div className={"dlg-title"}><StarIcon />פרטי השירותים הנוספים</div>
                    <div className={"icon-print"} onClick={(e) => {
                        e.preventDefault();
                        window.print();
                    }}/>
                    <div className={"dlg-content"}>
                        <div className={"dlg-section"}>
                          <div className={"dlg-extra-services-details-title"}><BlueBirdVipLogo /><h2>בלובירד פריוריטי פס</h2></div>
                          <div className={"dlg-extra-services-details-type"}>סוג שׁירות</div>
                            {extrs.getService &&
                                <div>
                                    <div className={"service-title"}>{extrs.getService.title}</div>
                                    <div>{extrs.getService.text}</div>
                                </div>}
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export {DlgExtraServicesDetails}