import icon from "app/desktop/images/icons/icon-check-in.svg";
import React from "react";
import {inject, observer} from "mobx-react";

@inject('checkInUIStore') @observer
class CheckInHeader extends React.Component {
    render() {

        const chk = this.props.checkInUIStore;

        return(
            <>
        {
            chk.isConfirmationScreen ? (<div className={"clock-wrap checkedIn clock-header"}>
                <div className={"clock-hour checkedIn"}></div>
                <div className={"clock-minute checkedIn"}></div>
                <div className={"checkin-mark checkin-mark-green"}></div>
            </div>) : (
                <img alt={"clock"} src={icon}/>)
        }
            </>
        )}
}

export {CheckInHeader};