import React from 'react'
import classnames from 'classnames'
import 'app/desktop/ui/components/popup/popup.scss'
class Popup extends React.Component {
    render() {
        const css = classnames("cs-popup", {"open" : this.props.isOpen});

        const externalClick = (e) => {
            e.stopPropagation();
            if (this.props.closeOnExternal) {

                this.props.onClose();
            }
        }

        return (
            <div className={css}>
                <div className="cs-popup-bg" onClick={externalClick}/>
                <div className={"cs-popup-wrap"}>
                    {this.props.closeBtn && (
                        <div className={"cs-popup-close"} onClick={this.props.onClose}/>
                    )}
                    <div className={"dlg-scroll"}>
                        {this.props.children}
                    </div>
                    {this.props.footer && (
                        <div className={"sticky-footer"}>
                            <div className="wrap">{this.props.footer}</div>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

Popup.defaultProps = {
    isOpen : false,
    onClose : () => {},
    closeOnExternal: true,
    footer: null,
    closeBtn: true,
}

export {Popup}