import {inject, observer} from "mobx-react";
import React from "react";
import {FlightInfo} from "app/desktop/ui/components/dialogs/dlg-seat-selection/components/flight-info/flight.info";

@inject ( "seatsStore", "pnrStore") @observer
class FlightInfoContainer extends React.Component {
    render() {
        const s = this.props.seatsStore;
        const pnr = this.props.pnrStore;
        let data = '';

        if (s.currentFlight.flightIndex === 0) {
            data = (
                <FlightInfo
                    icon={"icon-departure"}
                    departureCity={pnr.date1DepartureCity}
                    arrivalCity={pnr.date1ArrivalCity}
                />
            );
        } else {
            data = (
                <FlightInfo
                    icon={"icon-arrival"}
                    departureCity={pnr.date2DepartureCity}
                    arrivalCity={pnr.date2ArrivalCity}
                />
            );
        }

        return (
            <div>{data}</div>
        );
    }
}

export {FlightInfoContainer};