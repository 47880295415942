import {action, computed, observable} from "mobx";
import transport from "app/services/transport";
import access from 'safe-access';
import {Passenger} from "app/stores/passenger.store";
import {getActivePNR} from "app/services/session.service";
import {Service} from "./service.store";
import ServiceTypes from "app/enums/service.types";

const MSG_WITHOUT_BAGGAGE = "ללא מזוודה";

const getCurrencySymbolByCode = (code) => {
    switch (code) {
        case 'EUR':
            return '€';
        case 'USD':
            return '$'
        default:
            console.error('not supported currency', code);
            return "";
    }
}

const getToDirectionGUID = (flightDataArr) => {
    return access(flightDataArr,'[0].guid');
}

const getBackDirectionGUID = (flightDataArr) => {
    return flightDataArr.length > 0 && access(flightDataArr,'[1].guid');
}



const isPassengerExist = (passengers = [], id) =>  {
    const found = passengers.find((v) => {
//        console.log("isPassengerExist", v, id);
        return (parseInt(v,10) === parseInt(id,10));
    });

    return !!found;
}

const isNotPriority = (obj) => {
    return !obj.hasOwnProperty('requiredServiceTypeCode');
};


const hasService = (pnrData, flightGuid, passengerId, serviceType) => {
    const services = access(pnrData, 'additionalServices') || [];
/*
    return services.filter(s => s.product === flightGuid).some((s) => {
        return s.type === serviceType && s.paxId.toString() === passengerId.toString();
    })
    */

    const f = services.filter(s => s.product === flightGuid).find((s) => {
        return s.type === serviceType && s.paxId.toString() === passengerId.toString();
    })

    return f ? f.name : false;
}

const addServiceToData = (data, guid, pid, serviceCode) => {
    data[guid] = data[guid] || {};
    data[guid][pid] = data[guid][pid] || [];
    data[guid][pid].push(serviceCode);
}


//creates passengers array without infant
const createData = (pnrData = null, data = {}) => {
    const passengers = access(pnrData, 'passengersInfo', []);
    const baggageArr = access(data, 'flightSsrs', []);
    const currencySymbol = getCurrencySymbolByCode(data.currency);

    const a = passengers
        .filter((p) => p.title.toLowerCase() !== 'infant')
        .map((p) => {
            const passengerName = p.title + ' ' + p.fullName;
            const baggageToOptions = [];
            const baggageBackOptions = [];

            baggageArr.forEach((b) => {
                const availableSsrs = access(b, 'availableSsrs', []);//b.hasOwnProperty("availableSsrs") && b.availableSsrs.length > 0 && access(b, 'availableSsrs[0]', {});

                availableSsrs.forEach((service) => {
                    if (isPassengerExist(access(service, 'passengers', []), p.id)) {
                        if (['XBAG 1 BAG PAID','CBAG 1 BAG PAID'].includes(service.code)) {
                            const baggageGuid = b.guid;
                            const baggageCode = service.code;
                            const baggageText = service.text;
                            const baggagePrice = service.price;

                            if (baggageGuid === getToDirectionGUID(pnrData.flights) && isNotPriority(service)) {
                                const baggageName = `${baggageText} בעלות של ${currencySymbol}${baggagePrice}`;
                                baggageToOptions.push(new Service(baggageGuid, baggageCode, baggageText, baggageName, baggagePrice, currencySymbol));
                            }

                            if (baggageGuid === getBackDirectionGUID(pnrData.flights) && isNotPriority(service)) {
                                const baggageName = `${baggageText} בעלות של ${currencySymbol}${baggagePrice}`;
                                baggageBackOptions.push(new Service(baggageGuid, baggageCode, baggageText, baggageName, baggagePrice, currencySymbol));
                            }
                        }

                    }
                })

            });
            baggageToOptions.sort((a,b) => {
                return (a.serviceType === ServiceTypes.CarryOnBag) ? -1 : 1;
            });

            baggageBackOptions.sort((a,b) => {
                return (a.serviceType === ServiceTypes.CarryOnBag) ? -1 : 1;
            });

            const guid1 = access(pnrData,'flights[0].guid');
            const guid2 = access(pnrData,'flights[1].guid');
            const hasTrolleyTo = hasService(pnrData, guid1, p.id, ServiceTypes.CarryOnBag);
            const hasTrolleyBack = hasService(pnrData, guid2, p.id, ServiceTypes.CarryOnBag);
            const hasBaggageTo = hasService(pnrData, guid1, p.id, ServiceTypes.FirstBaggage);
            const hasBaggageBack = hasService(pnrData, guid2, p.id, ServiceTypes.FirstBaggage);

            return new Passenger(p.id, 
                passengerName, 
                baggageToOptions, 
                baggageBackOptions, 
                hasTrolleyTo, 
                hasTrolleyBack,
                hasBaggageTo,
                hasBaggageBack);
        });

    return a;
};

class BaggageStore {
    @observable isLoading = false;
    @observable data = null;
    @observable passengers = [];
    @observable infoPopupOpened = false;

    constructor(root, transport) {
        this.root = root;
        this.transport = transport;
    }

    @action fetchBaggageOptions() {
        this.isLoading = true;
        this.data = null;

        transport.fetchBaggageData(getActivePNR())
            .then((response) => {
                // console.log("baggage response", response.data);
                this.data = response.data;
                this.isLoading = false;

                // create data
                const a = createData(this.root.pnrStore.data.data, response.data.data);
                // console.log("!! baggage !!",a);
                this.passengers.replace(a);

                // add available services
                this.root.extraServicesStore.refreshServices();
                this.root.extraServicesStore.addAvailableServices(response.data.data.flightSsrs);
            })
            .catch((e) => {
                // console.log("error",e);
                this.data = null;
                this.isLoading = false;
            })
    }

    @action resetSelectedBaggage() {
        this.passengers.forEach((p) => {
            p.resetSelected();
        })
    }

    @action reset() {
        this.isLoading = false;
        this.data = null;
        // this.isCheckIn = false;
        this.passengers = [];
    }

    @action informationPopup() {
        this.infoPopupOpened = !this.infoPopupOpened;
    }

    @action closePopup() {
        this.infoPopupOpened = false;
    }

    @computed get baggageToStats() {
        let trolleyCount = 0;
        let trolleyPrice = 0;

        let baggageCount = 0;
        let baggagePrice = 0;

        this.passengers.forEach((p) => {
            trolleyCount += p.selectedTrolleyToCount;
            trolleyPrice += p.selectedTrolleyPriceTo;
            baggageCount += p.selectedBaggageToCount
            baggagePrice += p.selectedBaggagePriceTo;
        });

        return {
            "trolleyCount" : trolleyCount,
            "trolleyPrice" : trolleyPrice,
            "baggageCount" : baggageCount,
            "baggagePrice" : baggagePrice
        };
    }

    @computed get baggageBackStats() {
        let trolleyCount = 0;
        let trolleyPrice = 0;

        let baggageCount = 0;
        let baggagePrice = 0;

        this.passengers.forEach((p) => {
            trolleyCount += p.selectedTrolleyBackCount;
            trolleyPrice += p.selectedTrolleyPriceBack;
            baggageCount += p.selectedBaggageBackCount
            baggagePrice += p.selectedBaggagePriceBack;
        });

        return {
            "trolleyCount" : trolleyCount,
            "trolleyPrice" : trolleyPrice,
            "baggageCount" : baggageCount,
            "baggagePrice" : baggagePrice
        };
    }

    @computed get baggageTable() {
        return this.passengers;
    }

    @computed get currency() {
        if (!this.data) {
            return "";
        }

        return getCurrencySymbolByCode(this.data.data.currency);

    }

    @computed get totalTable() {
        return this.passengers.filter((p) => {
            return (p.selectedBaggageCount > 0) || (p.selectedTrolleyCount > 0)
        })
    }

    @computed get totalBaggagePrice() {
        let price = 0;
        this.passengers.forEach((p) => {
            price += p.selectedBaggagePrice + p.selectedTrolleyPrice;
        });

        return price;
    }

    @computed get isBaggageAvailable() {
        const isToAvailable = this.passengers.some(p => p.baggageToOptions.length > 0);
        const isBackAvailable = this.passengers.some(p => p.baggageBackOptions.length > 0);

        //web check functionality
        if (this.root.isWebCheckIn) {
            const direction = this.root.checkInUIStore.curDirection;
            return (direction === 0) ? isToAvailable : isBackAvailable;
        }

        return isToAvailable || isBackAvailable;
    }


    @computed get isPaidBaggageSelected() {
        return this.totalBaggagePrice > 0;
    }

    @computed get isBaggageSelected() {
        return this.baggageTotalPriceByDirection > 0;
    }

    @computed get baggageSelectedByDirection() {
        return this.root.isWebCheckIn && access(this.root.checkInUIStore,'.curDirection') === 0 ? "selectedToBaggage" : "selectedBackBaggage";
    }

    @computed get baggageTotalPriceByDirection() {
        if (this.root.isWebCheckIn) {
            //web check functionality
            const direction = this.root.checkInUIStore.curDirection;

            if (direction === 0) {
                return this.passengers.reduce((acc,p) => {
                    return acc + p.selectedBaggagePriceTo + p.selectedTrolleyPriceTo
                },0)
            } else {
                return this.passengers.reduce((acc,p) => {
                    return acc + p.selectedBaggagePriceBack + p.selectedTrolleyPriceBack
                },0)
            }
        }
    }



    @computed get selectedBaggageData() {
        const extraSelected = this.root.extraServicesStore.addedServices;
        const data = {};
        const selectedPassengers = this.passengers;
        
        selectedPassengers.forEach(p => {
            const pid = p.id.toString();

            p.baggageToOptions.forEach((s) => {
                if (s.isSelected) {
                    addServiceToData(data, s.guid, pid, s.code);
                }
            })

            p.baggageBackOptions.forEach((s) => {
                if (s.isSelected) {
                    addServiceToData(data, s.guid, pid, s.code);
                }
            })
        });

        extraSelected.forEach((s) => {
            s.passengers.forEach((p) => {
                addServiceToData(data, s.guid, p.toString(), s.code);
            })
        });

        return data;
    }

    @computed get didAllUsersSelectAnyOption () {
        const a = this.passengers.filter((p) => {
            const notSelectedTo = p.selectedBaggagePriceTo === 0 && p.selectedTrolleyPriceTo && p.baggageToOptions.length > 0;
            const notSelectedBack = p.selectedBaggagePriceBack === 0 && p.selectedTrolleyPriceBack && p.baggageBackOptions.length > 0;
           return notSelectedTo || notSelectedBack;
        });

        return a.length === 0;
    }

    @computed get baggage1IsPaid() {
        return (passIndx => {
            let baggageObj = access(this.root.pnrStore.date1Baggage, `[${passIndx}].baggage[0]`);
            // let hasBaggage = /\d/.test(baggageObj);
            return (baggageObj && baggageObj["id"]) ? baggageObj.name : "";
        })
    }

    @computed get baggage2IsPaid() {
        return (passIndx => {
            let baggageObj = access(this.root.pnrStore.date2Baggage, `[${passIndx}].baggage[0]`);
            // let hasBaggage = /\d/.test(baggageObj);
            return (baggageObj && baggageObj["id"]) ? baggageObj.name : "";
        })
    }

    @computed get pnrHasPassengerWithBaggagePaid() {
        //web checkin functionality
        let firstFlight = this.root.pnrStore.date1Baggage.some(passenger=> passenger.baggage[0].hasOwnProperty("id"));
        let secondFlight = this.root.pnrStore.date2Baggage.some(passenger=> passenger.baggage[0].hasOwnProperty("id"));
        return this.root.isWebCheckIn && access(this.root.checkInUIStore,'.curDirection') === 0 ? firstFlight : secondFlight;
    }
}

export {BaggageStore}