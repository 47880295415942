import BaggageWarning from "app/common/ui/baggage.warn";
import {inject, observer} from "mobx-react";
import React from "react";

@inject("isWebCheckIn") @observer
class Header extends React.Component {
    render() {
        const {isWebCheckIn} = this.props;
        const headerTitle = isWebCheckIn ? "פירוט והוספת כבודה" : "הוספת כבודה לבטן המטוס";
        const headerMainClass = isWebCheckIn ? "main-checkin" : "main";

        return (
            <div className={"cs-deal-title"}>
                {!isWebCheckIn && <span className={"icon-baggage"}/>}
                <div className={headerMainClass}>{headerTitle}
                    {!isWebCheckIn ? <div><BaggageWarning/></div> : ""}
                </div>
                
            </div>
        );
    }
}
export {Header};