import {inject, observer} from "mobx-react";
import React from "react";
import { withRouter } from 'react-router-dom';

@inject("checkInUIStore", "pnrStore") @observer
class PdfPage extends React.Component {

    componentDidMount() {
        const { match } = this.props;
        const { params } = match;
        const { encryptedPnr } = params;
        this.props.pnrStore.performDownloadBoardingPass(encryptedPnr);
    }

    render() {
        return (
            this.props.checkInUIStore.isLoading && <div>Downloading...</div>
        );
    }
}

export default withRouter(PdfPage);