import React from 'react'
import {inject, observer} from "mobx-react";
import 'app/desktop/ui/pages/dashboard/components/passengers/passengers.scss';


@inject("pnrStore") @observer
class PassengersInfo extends React.Component {
    render () {
        const s = this.props.pnrStore;

        return (
            <div className={"cs-passenger-info"}>
                <div>
                    {s.passengers.map((p, index) =>{
                        return (
                            <div className={"p-row"} key={"p"+index}>
                                <span className={"num"}>{index+1}.</span> &nbsp;
                                <span className={"name"}>{p.title} {p.fullName || ""}</span>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}

export {PassengersInfo}
