import React from "react";
import {inject, observer} from "mobx-react";
import {TxtField} from "app/desktop/ui/components/txt-field/txt.field";
import {MainButton} from "app/desktop/ui/components/buttons/main-button/main.button";
import {Preloader} from "app/desktop/ui/components/preloader/preloader";
import {history} from "app/services/custom.router";
import {LoginInfo} from "app/desktop/ui/components/login-info/login.info";


@inject("authStore", "pnrStore") @observer
class SearchErrorMessage extends React.Component {
    render() {
        const {authStore} = this.props;

        return (authStore.searchError) ? <div className={"error"}>{authStore.searchError}</div> : "";
    }
}

@inject("authStore", "pnrStore") @observer
class SearchPage extends React.Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleSubmit(event) {
        event.preventDefault();
        if (this.props.authStore.searchInProgress) {
            console.log("already loading...")
        } else {
            this.props.authStore.performSearch();
        }
    }

    componentDidMount() {
        if (!this.props.authStore || !this.props.authStore.isAgentAuthorized) {
            history.push('/agent');
        }
    }

    render() {
        const {authStore} = this.props;

        const status = (authStore.searchInProgress) ? <Preloader/> : (authStore.searchError) ? <SearchErrorMessage/>  : "";

        return (
            <div className={"login-page"}>
                <div className={"header-row"}>
                    <div className={"container"}>
                        <LoginInfo/>
                    </div>
                </div>
                <div className={"cs-login-form"}>
                    <div className={"header-image"}/>
                    <div className={"form-body"}>
                        <div className={"form-header"}>
                            <div className={"line-1"}>חיפוש הזמנה</div>

                            <form action={"#"} onSubmit={this.handleSubmit}>
                                <div className={"rows"}>
                                    <div className={"row"}>
                                        <TxtField placeholder={"מספר הזמנה"}
                                                  value={authStore.searchPnr}
                                                  isError={authStore.searchError}
                                                  disabled={authStore.searchInProgress}
                                                  maxLength={8}
                                                  tooltip = {"מספר הזמנה"}
                                                  name={"pnr"}
                                                  onChange = {(e) => {
                                                      authStore.setSearchPnr(e.target.value);
                                                  }}/>
                                    </div>

                                    <div className={"row"}>
                                        <TxtField placeholder={"שם משפחה"}
                                                  value={authStore.searchLastName}
                                                  isError={authStore.searchError}
                                                  disabled={authStore.searchInProgress}
                                                  maxLength={64}
                                                  tooltip = {"שם משפחה"}
                                                  name={"last-name"}
                                                  onChange = {(e) => {
                                                      authStore.setSearchLastName(e.target.value);
                                                  }}/>
                                    </div>

                                    <div className={"row"}>
                                        <TxtField placeholder={"מספר טלפון של הלקוח"}
                                                  value={authStore.searchPhone}
                                                  type="number"
                                                  isError={authStore.searchError}
                                                  disabled={authStore.searchInProgress}
                                                  maxLength={64}
                                                  tooltip = {"מספר טלפון של הלקוח"}
                                                  name={"customer-email"}
                                                  onChange = {(e) => {
                                                      authStore.setSearchPhone(e.target.value);
                                                  }}/>
                                    </div>

                                    <div className={"row"}>
                                        {status}
                                        {authStore.showAgentLoginLink &&
                                        <div><br/><a href={"/agent"}><strong>כניסה למערכת</strong></a></div>
                                        }
                                    </div>

                                    <div className={"row"}>
                                        <div className={"button-placeholder"}>
                                            <MainButton  onClick={() => {authStore.performSearch();}}>חפש</MainButton>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export {SearchPage}