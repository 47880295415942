import {observable, action} from "mobx";
import {PopupStore} from "app/stores/popup.store";


class DashboardUIStore {
    @observable currentStep = 0;
    @observable flightDetailsDlg = new PopupStore();
    @observable paymentDlg = new PopupStore();
    @observable addBaggageDialog = new PopupStore();
    @observable addSeatsDialog = new PopupStore();
    @observable seatSelectionDialog = new PopupStore();
    @observable hotelDetailsDlg = new PopupStore();
    @observable extraServicesDialog = new PopupStore();

    @observable baggageDetailsDialog = new PopupStore();
    @observable extraServicesDetailsDialog = new PopupStore();
    @observable seatsDetailsDialog = new PopupStore();
    @observable seatsBooking = new PopupStore();
    @observable restrictedSeatsDlg = new PopupStore();

    @observable addBaggageCheckIn = new PopupStore();
    @observable addSeatsCheckIn = new PopupStore();
    @observable ticketCheckIn = new PopupStore();
    @observable closeCheckInConfirmation = new PopupStore();

    @observable sentRecipeDialog = new PopupStore();

    constructor(root) {
        this.root = root;
    }

    @action setStep(newStep) {
        this.currentStep = newStep;
    }

    @action
    closeAllPopups () {
        for (let key in this) {
            if (this[key] instanceof PopupStore) {
                this[key].closePopup();
            }
        }
    }
}

export {DashboardUIStore}