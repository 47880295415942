import React from 'react';
import "app/desktop/ui/components/modal-warning/modal.warning.scss";
class ModalWarning extends React.Component {
    render() {
        const { isOpen, image, extraClassName, buttonText1, buttonText1Icon, buttonText2, title, activeScreen, onClose, initPayment } = this.props;

        if (!isOpen) {
            return null;
        }

        console.log(image)

        const handleClickSkip = () => {
            activeScreen();
            onClose();
        };

        const handleClose = (initPayment) => {
            if (initPayment) {
               initPayment();
            }
            onClose();
        };

        return (
            <div className={"mw-modal"}>
                <div className={"mw-content"}>
                    <div className={"mw-imageWrapper"}>
                        <img className={"mw-image"} src={image} alt={title} />
                    </div>
                    <div className={`mw-section ${extraClassName}`}>
                    {this.props.children}
                    </div>
                    <div className={"mw-button-wrapper"}>
                        <button
                            onClick={handleClickSkip}
                            className={"mw-button mw-button2"}>{buttonText2}</button>
                        <button
                            onClick={()=> handleClose(initPayment)}
                            className={"mw-button mw-button1"}>
                            {buttonText1Icon && <span className={"symbol"}>+</span>}
                            <span>{buttonText1}</span></button>
                    </div>
                </div>
            </div>
        );
    }
}


ModalWarning.defaultProps = {
    isOpen : false,
    modal : true,
    title: '',
    image: '',
    text: '',
    extraClassName: '',
    buttonText1: '',
    buttonText2: '',
    buttonText1Icon: null,
    activeScreen: ()=> {}
}

export {ModalWarning};
