import axios from 'axios'
import access from 'safe-access'
import {history} from 'app/services/custom.router'
import {getActiveAccessToken, getAgentAccessToken} from "app/services/session.service";
import EventEmitter from 'events';

axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    // console.log("[REQUEST]");
    // console.log(config.url);

    const isAgent = config.url.indexOf('/agent')>=0;

    // console.log("isAgent",isAgent);

    let token = "";

    if (isAgent) {
        // console.info("taking agent access token...");
        token = getAgentAccessToken();
    } else {
        // console.info("taking regular...");
        token = getActiveAccessToken();
    }
    //const token = window.sessionStorage.getItem("access_token");
    // console.log("token", token);
    if (token && !config.ignoreAuth) {
        // console.log("set API token", token);
        config.headers["Authorization"] = "Bearer " + token;
    }

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});


export const eventEmitter = new EventEmitter();

axios.interceptors.response.use(function (response) {
    // console.log("[RESPONSE]" ,response);

    if (response.config.method === "post") {
    //    stores.transportStore.decrementCount();
    }

    return response;
}, function (error) {
    // console.log("[RESPONSE_ERROR]" ,error);
    // console.log("config", error.config);

    const isAgent = (access(error, 'config.url') || "").indexOf('/agent')>=0;

    // console.log("isAgent",isAgent);

    if (error.config.method === "post") {
    //    stores.transportStore.decrementCount();
    }

    const status = access(error, 'response.status');
//    const message = access(error, 'response.data.message');

    if (!isAgent) {
        if (status === 401 || status === 405) {
            //history.push("/login");
            setTimeout(()=> {
                window.location.href = "/login";
                eventEmitter.emit('unauthorized');
            }, 1500);
        }

        if (status === 503) {
            history.push("/maintenance")
        }
    }


    // Do something with response error
    return Promise.reject(error);
});