import React from 'react';
import 'app/desktop/ui/components/dropdown-with-label/dropdown.with.label.scss';
import classnames from 'classnames';
import access from "safe-access";

class Dropdown extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        };

        this.handleClick = this.handleClick.bind(this);
        this.getOptions = this.getOptions.bind(this);

        this.registerEvents = this.registerEvents.bind(this);
        this.unRegisterEvents = this.unRegisterEvents.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.closeDropdown = this.closeDropdown.bind(this);

        this.myRef = React.createRef();
    }

    handleClick() {
        if (this.props.isDisabled) {
            return;
        }

        if (!this.state.isOpen) {
            this.registerEvents();
        } else {
            this.unRegisterEvents();
        }

        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    registerEvents() {
        document.addEventListener('click', this.handleOutsideClick, false);
    }

    unRegisterEvents() {
        document.removeEventListener('click', this.handleOutsideClick, false);

    }

    handleOutsideClick(e) {
        // ignore clicks on the component itself
        const { current } = this.myRef;

        if (current && !current.contains(e.target)) {
            this.closeDropdown();
        }
    }


    closeDropdown() {
        this.setState({
            isOpen: false
        });

        this.unRegisterEvents();
    }

    getOptions() {
        return (
            <div className={"options"}>
                <div className={"options-wrap"}>
                    <ul>
                        {this.props.options.map((item, index) => {
                            return (<li key={"item" + index} title={item.name} onClick={this.props.onSelect.bind(this, item, index)}>{item.name}</li>)
                        })}
                    </ul>
                </div>
            </div>
        );
    }

    render() {
        const { isOpen } = this.state;
        const options = (isOpen) ? this.getOptions() : "";
        const { isDisabled, labelText, selectedText } = this.props;

        const css = classnames("dropdown-with-label", { "disabled": isDisabled, "open": isOpen });
        const labelCss = classnames("label", { "hide": isOpen });

        return (
            <div className={css} onClick={this.handleClick} ref={this.myRef}>
                {options}
                <div className={"wrap"}>
                    {labelText &&
                        <div className={labelCss}>{labelText}</div>
                    }
                    <div className={"text"}>{selectedText}</div>
                    <div className={"icon"} />
                </div>
            </div>
        );
    }
}

Dropdown.defaultProps = {
    labelText: "",
    selectedText: "",
    options: [],
    isDisabled: false,
    onSelect: () => { }
};

export {Dropdown}