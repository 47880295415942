import React from 'react'
import 'app/desktop/ui/pages/dashboard/components/baggage-table/sidebar.table.scss'
import {inject, observer} from "mobx-react";
import {BaggageSidebarTableInner} from "../../../../../../common/ui/baggage.sidebar.table.inner";

@inject("baggageStore", "pnrStore", "dashboardUIStore", "isWebCheckIn") @observer
class BaggageSidebarTable extends React.Component {
    render() {
        return <BaggageSidebarTableInner/>
    }
}

export {BaggageSidebarTable}