import React from 'react';
import InfoIcon from "app/desktop/ui/components/info-icon/info.icon";
import "app/desktop/ui/components/important-info/important-info.scss";
import BaggageWarning from 'app/common/ui/baggage.warn';

const ImportantInfo = ({ infoText, showWarning }) => {
    return (
        <div className={"imp-info-container"}>
            <InfoIcon red/>
            <div className={"imp-info-text"}>
                {infoText}

                {showWarning ? 
                <div>
                    <BaggageWarning/>
                </div> : null}
            </div>
        </div>
    )
}

export default ImportantInfo;