import React from 'react'
import {Popup} from "app/desktop/ui/components/popup/popup";
import {inject,observer} from "mobx-react";
import 'app/desktop/ui/components/dialogs/dlg-flight-details/dlg.flight.details.scss';
import {DealStatus} from "app/desktop/ui/components/deal-status/deal.status";
import {SeatsInfo} from "app/desktop/ui/components/dialogs/dlg-flight-details/seats.info";
import access from "safe-access";
import {BaggageTableInfo} from "app/desktop/ui/pages/dashboard/components/baggage-table/modal";
import BaggageWarn from 'app/common/ui/baggage.warn';

@inject("passengerDetailsStore", "pnrStore", "checkInUIStore") @observer
class SingleFlight extends React.Component {
    render() {
        const {props} = this;
        if (!props.departureDay) {
            return null;
        }

        const fNumber = access(props, 'flightData.legs[0].flightNumber');
        const carrier = access(props, 'flightData.legs[0].operatingCarrier');

        const carrierBlock = (carrier) ? <React.Fragment>&nbsp;&nbsp;&#8226;&nbsp;&nbsp;<span>מופעל על ידי</span>&nbsp;
            <span className={"f-number"}>&nbsp; {carrier}</span></React.Fragment> : "";


        const header = <div className={"large-title"}>{props.direction} <DealStatus {...props.statusData}/></div>;
        const showPlaneIcon = <div className={props.icon}/>

        const airline = <div className={`row airline`}>
            {props.airline}
        </div>;

        return (
            <div className={""}>
                {header}
                <div className={`row destinations`}>
                    {showPlaneIcon}
                    <div className={"dst"}>מ{props.departureCity}</div>
                    <div className={"icon-arrow-left"}/>
                    <div className={"dst"}>ל{props.arrivalCity}</div>
                </div>

                {airline}

                <div className={`row flight`}>
                    <span>המראה: יום</span>&nbsp;
                    <span className={"day"}>{props.departureDay}'</span>&nbsp;
                    <span className={"date"}>{props.departureDate}</span>&nbsp;
                    <span className={"day"}>בשעה</span>&nbsp;
                    <span>{props.departureTime}</span>&nbsp;
                    <span className={"dst"}>מ{props.departureAirport}</span>
                </div>

                <div className={`row flight`}>
                    <span>נחיתה: יום</span>&nbsp;
                    <span className={"day"}>{props.arrivalDay}'</span>&nbsp;
                    <span className={"date"}>{props.arrivalDate}</span>&nbsp;
                    <span className={"day"}>בשעה</span>&nbsp;
                    <span>{props.arrivalTime}</span>&nbsp;
                    <span className={"dst"}>ב{props.arrivalAirport}</span>
                </div>


                <div className={`row flight-number`}>
                    <span>מס' טיסה </span>
                    <span className={"f-number"}>{fNumber}</span>
                    {carrierBlock}
                </div>
            </div>
        )
    }
}

@observer
class SingleFlightCheckIn extends React.Component {
    render() {
        const {props} = this;
        if (!props.departureDay) {
            return null;
        }

        const fNumber = access(props, 'flightData.legs[0].flightNumber');
        const carrier = access(props, 'flightData.legs[0].operatingCarrier');

        const carrierBlock = (carrier) ? <React.Fragment>&nbsp;&nbsp;&#8226;&nbsp;&nbsp;<span>מופעל על ידי</span>&nbsp;
            <span className={"f-number"}>&nbsp; {carrier}</span></React.Fragment> : "";

        return (
            <div className={""}>
                <div className={`row destinations webcheck-row`}>
                    <div className={"dst"}>מ{props.departureCity}</div>
                    <div className={"icon-arrow-left"}/>
                    <div className={"dst"}>ל{props.arrivalCity}</div>
                </div>

                <div className={`row flight webcheck-row`}>
                    <span>המראה: יום</span>&nbsp;
                    <span className={"day"}>{props.departureDay}'</span>&nbsp;
                    <span className={"date"}>{props.departureDate}</span>&nbsp;
                    <span className={"day"}>בשעה</span>&nbsp;
                    <span>{props.departureTime}</span>&nbsp;
                    <span className={"dst"}>מ{props.departureAirport}</span>
                </div>

                <div className={`row flight webcheck-row`}>
                    <span>נחיתה: יום</span>&nbsp;
                    <span className={"day"}>{props.arrivalDay}'</span>&nbsp;
                    <span className={"date"}>{props.arrivalDate}</span>&nbsp;
                    <span className={"day"}>בשעה</span>&nbsp;
                    <span>{props.arrivalTime}</span>&nbsp;
                    <span className={"dst"}>ב{props.arrivalAirport}</span>
                </div>


                <div className={`row flight-number webcheck-row`}>
                    <span>מס' טיסה </span>
                    <span className={"f-number"}>{fNumber}</span>
                    {carrierBlock}

                    <div className={`row flight-number webcheck-row`}><span> חברת תעופה {props.airline}</span></div>
                </div>
            </div>
        )
    }
}

@inject ("pnrStore", "baggageStore") @observer
class BaggageInfo extends React.Component {
    render() {
        const pnr = this.props.pnrStore;
        const bg = this.props.baggageStore;
        const isBlueBird = this.props.airline && this.props.airline === "Blue Bird Airways";

        const passengersExist = bg.baggageTable.length > 0;
        const infoPopup = passengersExist && isBlueBird &&
            <div className={"cs-deal bg-info-wrapper"}>
            <div className={"baggage-table"}>
                <div className={"section"}>
            <div onClick={() => bg.informationPopup()} className={"baggage-info bg-info-margins"}>
                <div className={"icon-info-popup"}></div>
                <div className={"baggage-info-text"}>מידע מפורט על סוגי הכבודה</div>
            </div>
                </div>
            </div>
            </div>

        return (
            <>
                <BaggageTableInfo baggage={bg}/>
            <div className={"dlg-section"}>
                <div className={"row destinations"}>
                    <div className={"icon-baggage"}/>
                    <div className={"dst"}>פירוט כבודה <BaggageWarn/></div>
                </div>
                {infoPopup}

                <div className={"baggage-row header"}>
                    <div className={"b-num"}>&nbsp;</div>
                    <div className={"b-name"}>שם נוסע</div>
                    <div className={"b-type"}>כבודה כלולה</div>
                    <div className={"b-type"}>כבודה נוספת</div>
                </div>

                <div>
                    {this.props.baggage.map((i,index)=>{
                        
                        return (
                            <div className={"baggage-row"} key={"b"+index}>
                                <div className={"b-num"}>{index+1}.</div>&nbsp;
                                <div className={"b-name"}>{i.fullName}</div>&nbsp;
                                <div className={"b-type"}>
                                    {i.title === 'Infant' ? <div>תיק תינוק + עגלת תינוק</div> : <div>1 X פריט אישי</div> }
                                </div>
                                <div className={"b-type"}>
                                    {i.trolleyBaggage.map((b,bi) => {
                                        return <div key={"tb"+index+"-"+bi}>{b.name}</div>
                                    })}

                                    {i.baggage.map((b,bi) => {
                                        return pnr.checkInfant(index) ||
                                            <div key={"b"+index+"-"+bi}>{b.name}</div>
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
                </>
        )
    }
}

@inject ("dashboardUIStore","pnrStore") @observer
class DlgFlightDetails extends React.Component {
    render() {
        const dlg = this.props.dashboardUIStore.flightDetailsDlg;
        const s = this.props.pnrStore;
        // const baggage = s.date1Baggage;

        // console.log("baggage",baggage);

        return (
            <Popup isOpen={dlg.isOpen} onClose={() => {
                dlg.closePopup();
            }}>
                <div className={"dlg-body"}>
                    <div className={"dlg-title"}><div className={"icon-flight"}/> פרטי הטיסה</div>
                    <div className={"icon-print"} onClick={(e) => {
                        e.preventDefault();

                        window.print();
                    }}/>
                    <div className={"dlg-content dlg-flight-details"}>
                        <div className={"dlg-section"}>
                            <div className={"cs-flight"}>
                                <SingleFlight
                                    statusData={s.flight1StatusData}
                                    direction={"טיסת הלוך"}
                                    departureDay={s.flight1DepartureDayShort}
                                    departureDate={s.flight1DepartureDate}
                                    arrivalDay={s.flight1ArrivalDayShort}
                                    arrivalDate={s.flight1ArrivalDate}
                                    departureAirport={s.date1DepartureAirport}
                                    departureTime={s.date1DepartureTime}
                                    arrivalAirport={s.date1ArrivalAirport}
                                    arrivalTime={s.date1ArrivalTime}
                                    icon={"icon-departure"}
                                    departureCity={s.date1DepartureCity}
                                    arrivalCity={s.date1ArrivalCity}
                                    airline={s.date1Airline}
                                    flightData={s.flight1Data}

                                />

                                <BaggageInfo airline={s.date1Airline} baggage={s.date1Baggage}/>
                                <SeatsInfo passengers={s.date1Baggage} />

                            </div>

                            {s.isTwoWayFlight &&
                                <div className={"cs-flight"}>
                                    <SingleFlight
                                        statusData={s.flight2StatusData}
                                        direction={"טיסת חזור"}
                                        departureDay={s.flight2DepartureDayShort}
                                        departureDate={s.flight2DepartureDate}
                                        arrivalDay={s.flight2ArrivalDayShort}
                                        arrivalDate={s.flight2ArrivalDate}
                                        departureAirport={s.date2DepartureAirport}
                                        departureTime={s.date2DepartureTime}
                                        arrivalAirport={s.date2ArrivalAirport}
                                        arrivalTime={s.date2ArrivalTime}
                                        icon={"icon-arrival"}
                                        departureCity={s.date2DepartureCity}
                                        arrivalCity={s.date2ArrivalCity}
                                        airline={s.date2Airline}
                                        flightData={s.flight2Data}
                                    />

                                    <BaggageInfo airline={s.date2Airline} baggage={s.date2Baggage}/>
                                    <SeatsInfo passengers={s.date2Baggage} />
                                </div>
                            }




                        </div>

                    </div>


                </div>
            </Popup>
        )
    }
}

export {DlgFlightDetails, SingleFlight, SingleFlightCheckIn}
