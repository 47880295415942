const ServiceTypes = {
    "FirstBaggage" : 1,
    "OtherBaggage" : 2,
    "Seat" : 3,
    "TwoCheckedBags" : 4,
    "CarryOnBag" : 5,
    "Plf" : 6,
    "CovidTest" : 7,
    "FastLane_ASVC" : 8,
    "PriorityCheckin_PRIO" : 9,
    "CheckInTicketNumber" : 10,
    "CheckInSequenceNumber" : 11,
    "HandBag" : 12,
    "Unknown" : 100
}

export default ServiceTypes;