import React from 'react';
import classnames from 'classnames';
import "app/desktop/ui/components/buttons/blue-button/blue.button.scss";

class BlueButton extends React.Component {
    render() {

        const css = classnames("btn btn-blue", {"pink-button" : this.props.pinkbutton}, {"isCheckIn" : this.props.smallpadding}, {"mobile-width": this.props.mobile})
        return (
            <button className={css} onClick={this.props.onClick} {...this.props}>
                {this.props.children}
            </button>
        )
    }
}

BlueButton.defaultProps = {
    onClick : () => {}
}

class BlueButtonBig extends React.Component {
    render() {
        const css = classnames("btn btn-blue big", {"with-icon" : this.props.withIcon}, {"disabled" : this.props.disabled});
        return (
            <button className={css} onClick={this.props.onClick}>
                {this.props.children}
            </button>
        )
    }
}

BlueButtonBig.defaultProps = {
    onClick : () => {},
    withIcon : false,
    disabled : false,
    pinkButton : false
}


export {BlueButton, BlueButtonBig}