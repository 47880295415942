import React from "react";

class Header extends React.Component {
    render() {
        // const {isWebCheckIn} = this.props;
        const headerTitle = "פירוט ובחירת הושבה";
        const headerMainClass = "main-checkin";

        return (
            <div className={"cs-deal"}>
                <div className={"cs-deal-title"}>
                    <div className={headerMainClass}>{headerTitle}</div>
                </div>
            </div>
        );
    }
}
export {Header};