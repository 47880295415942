import {observable, action} from "mobx";
import {isEmailValid} from "app/services/validator";
import access from "safe-access";

class PasswordResetStore {
    @observable email = "";
    @observable isLoading = false;
    @observable error = "";
    @observable isSuccess = false;

    @observable newPassword = "";
    @observable newPasswordConfirm = "";
    @observable guid = "";
    @observable token = "";

    @observable changePasswordError = "";
    @observable changePasswordLoading = false;
    @observable changePasswordSuccess = false;

    constructor(root, transport) {
        this.root = root;
        this.transport = transport;
    }

    @action setEmail(value) {
        this.email = value;
    }

    @action setGuid(value) {
        this.guid = value;
    }

    @action setToken(value) {
        this.token = value;
    }

    @action setNewPassword(value) {
        this.newPassword = value;
    }


    @action setNewPasswordConfirm(value) {
        this.newPasswordConfirm = value;
    }

    @action performPasswordReset() {
        if (!this.email || !isEmailValid(this.email)) {
            this.error = "כתובת מייל לא תקינה";
            return;
        }


        const data = `"${this.email}"`;
        this.isLoading = true;
        this.error = "";
        this.transport.performPasswordReset(data)
            .then((response) => {
                // console.log(response);
                this.isSuccess = true;
            })
            .catch((e) => {
                console.log(e.response);

                if (e.response && e.response.status === 404) {
                    this.error = "כתובת מייל לא תקינה";
                } else {
                    this.error = "שגיאה";
                }

            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    @action performChangePassword() {
        if (!this.guid || !this.token) {
            this.changePasswordError = "תהליך החלפת סיסמא פג תוקף";
            return;
        }

        if (!this.newPassword || !this.newPasswordConfirm) {
            this.changePasswordError = "נא לכתוב סיסמא חדשה";
            return;
        }

        if (this.newPassword !== this.newPasswordConfirm) {
            this.changePasswordError = "אישור סיסמא לא זהה לסיסמא חדשה";
            return;
        }

        const data = {
            newPassword: this.newPassword,
            confirmPassword: this.newPasswordConfirm,
            id: this.guid,
            token: this.token
        }


        this.changePasswordLoading = true;
        this.changePasswordError = "";
        this.transport.performPasswordChange(data)
            .then((response) => {
                console.log(response);
                this.changePasswordSuccess = true;
            })
            .catch((e) => {
                console.log(e.response);
                const message = access(e,'response.data.error[0].message');

                if (message) {
                    this.changePasswordError = message;
                } else {
                    this.changePasswordError = "תהליך החלפת סיסמא פג תוקף";
                }
            })
            .finally(() => {
                this.changePasswordLoading = false;
            });
    }
}

export {PasswordResetStore}