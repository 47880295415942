import React from 'react';
import {inject, observer} from "mobx-react";
import { ReactComponent as StarIcon } from "app/desktop/images/icons/star-icon.svg";
import BlueBirdVipLogo from "app/desktop/ui/components/bluebird-vip-logo/bluebird.vip.logo";
import 'app/desktop/ui/pages/dashboard/components/extra-services/extra-services.details.scss';

@inject("dashboardUIStore","extraServicesStore", "pnrStore") @observer
class ExtraServicesDetails extends React.Component {

  render() {
    const s = this.props.pnrStore;

    if (!s.extraServicesInDeal.length) {
      return null;
    }

    return (
      <div className={"cs-section cs-extra-services-details"}>
        <div className={"section-title wrap"}>
          <StarIcon />
          פרטי השירותים הנוספים
            <div className={"purchased-service"}>{this.props.extraServicesStore.getService.title}</div>
        </div>
        <div className={"section-logo"}>
          <div className={"icon-v"}/>
          <div>
            <BlueBirdVipLogo />
          </div>
        </div>
        <div className={"section-popup"}>
          <a href={"#"} onClick={(e) => {
              e.preventDefault();
              this.props.dashboardUIStore.extraServicesDetailsDialog.openPopup();
          }}>הצג פירוט מלא</a>
        </div>
      </div>
    )
  }

}

export {ExtraServicesDetails}