import React from 'react'
import classnames from 'classnames';

class DashboardStep extends React.Component {
    render() {

        const css = classnames("cs-d-step" , {active: this.props.active}, {marked: this.props.marked}, {disabled: this.props.disabled});
        return (
            <div className={css} onClick={() => {
                if (!this.props.disabled) {
                    this.props.onClick()
                }

            }}>
                <div className={"num"}>{this.props.num}</div>
                <div className={"step-title"}>{this.props.title}</div>
                <div className={"edit-icon"}/>
                <div className={"icon-first"}/>
                <div className={"icon-first-edit"}/>
                <div className={"mark"}/>

            </div>
        )
    }
}

DashboardStep.defaultProps = {
    num : 0,
    title : "",
    marked : false,
    disbled : false,
    onClick : () => {}
}


export {DashboardStep}