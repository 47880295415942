import React from 'react'
import {inject,observer} from "mobx-react";
import ScreenTypes from "app/desktop/checkin/screen.types";
import PassengerDetailsScreen from "app/desktop/checkin/screens/passenger.details.screen";
import BaggageScreen from "app/desktop/checkin/screens/baggage.screen";
import SeatsScreen from "app/desktop/checkin/screens/seats.screen";
import PaymentScreen from "app/desktop/checkin/screens/payment.screen";
import IframeScreen from "../screens/iframe.screen";
import ConfirmScreen from "../screens/confirm.screen";

@inject('checkInUIStore') @observer
class ActiveScreen extends React.Component {
    render() {
        const s = this.props.checkInUIStore;

        switch (s.activeScreen) {
            case ScreenTypes.PASSENGER_DETAILS:
                return <PassengerDetailsScreen/>
            case ScreenTypes.BAGGAGE:
                return <BaggageScreen/>
            case ScreenTypes.SEATS:
                return <SeatsScreen/>
            case ScreenTypes.PAYMENT:
                return <PaymentScreen/>
            case ScreenTypes.IFRAME:
                return <IframeScreen/>
            case ScreenTypes.CONFIRMATION:
                return <ConfirmScreen/>

            default:
                return null;
        }
    }
}

export default ActiveScreen;