import React from 'react';
import 'app/desktop/ui/components/login-info/login.info.scss';
import {inject, observer} from "mobx-react";
import {history} from 'app/services/custom.router'
import {PNRStore} from "app/stores/pnr.store";

@inject("authStore") @observer
class LoginInfo extends React.Component {

    render() {
        const {authStore} = this.props;
        const isAgent = authStore.isAgentAuthorized;
        const accountHolder = authStore.isAgentAuthorized ? "" : this.props.authStore.accountHolder;

        return (
            <div className={"cs-header-login"}>
                <div className={"icon icon-account"}/>
                <div className={"account"}>{accountHolder}</div>
                <a href={"#"} onClick={(e) =>{
                    e.preventDefault();

                    this.props.authStore.logout();

                    if (isAgent) {
                        //history.push('/agent');
                        window.location.href = "/agent";
                    } else {
                        //history.push('/login');
                        window.location.href = "/login";
                    }
                }}>התנתק</a>
            </div>
        )
    }
}

export {LoginInfo}