import {action, computed, observable} from "mobx";

import ServiceTypes from 'app/enums/service.types';

class Service {
    @observable code = ""
    @observable text = "";
    @observable name = "";
    @observable price = "";
    @observable currency = "";

    @observable isSelected = false;
    @observable count = 1;

    constructor(guid, code, text, name, price, currency) {
        this.guid = guid;
        this.code = code;
        this.text = text;
        this.name = name;
        this.price = price;
        this.currency = currency;
    }

    @action toggleService() {
        console.log("toggleService");
        this.isSelected = !this.isSelected;
    }

    @computed get serviceType() {
        if (this.code === 'XBAG 1 BAG PAID') {
            return ServiceTypes.FirstBaggage;
        }

        if (this.code === 'CBAG 1 BAG PAID') {
            return ServiceTypes.CarryOnBag;
        }

        return ServiceTypes.Unknown;
    }

    @computed get serviceNameHeb() {
        switch (this.serviceType) {
            case ServiceTypes.FirstBaggage:
                return "כבודת בתשלום";
            case ServiceTypes.CarryOnBag:
                return "טרולי בתשלום";
        }

        return "";
    }
}

export {Service}