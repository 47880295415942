import React from 'react'
import {ModalDialog} from "app/desktop/ui/components/modal/modal";
import 'app/desktop/ui/components/dialogs/dlg-seat-booking/dlg.seats.booking.scss';
import {inject, observer} from "mobx-react";

@inject("dashboardUIStore") @observer
class DlgSeatsBooking extends React.Component {
    render() {
        const dlg = this.props.dashboardUIStore.seatsBooking;
        return (
            <ModalDialog isOpen={dlg.isOpen} modal={true} onClose={() => {
                dlg.closePopup();
            }}>
                <div className={"dlg-seats-booking"}>
                    <div className={"ui-loader"}/>
                </div>
            </ModalDialog>
        )
    }
}

export {DlgSeatsBooking}