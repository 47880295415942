import allStores from "./all.stores";
import {BaggageStore} from "./baggage.store";
import Transport from "../services/transport";
import {PaymentStore} from "./payment.store";
import {SeatsStore} from "./seats.store";
import CheckInUIStore from "./checkin.ui.store";
import PassengerDetailsStore from "./passenger.details.store";
import {PNRStore} from "./pnr.store";
import {SidebarStore} from "./sidebar.store";
import {PassengersStore} from "./passengers.store";
import {CountriesStore} from "./countries.store";
import {DashboardUIStore} from "./dashboard.ui.store";

class RootCheckInStore {
    constructor() {
        this.isWebCheckIn = true;
        this.legacyRootStore = allStores;

        this.authStore = allStores.authStore; // pointer to same store
        this.baggageStore = new BaggageStore(this, Transport);
        this.paymentStore = new PaymentStore(this, Transport);
        this.seatsStore = new SeatsStore(this, Transport);
        this.checkInPopup = allStores.checkInPopup;
        this.checkInUIStore = new CheckInUIStore(this, Transport);
        this.pnrStore = new PNRStore(this, Transport, this.authStore);
        this.sidebarStore = new SidebarStore(this);
        this.passengersStore = new PassengersStore(this);

        this.countriesStore = new CountriesStore(this, Transport);
        this.passengerDetailsStore = new PassengerDetailsStore(this);
        this.dashboardUIStore = new DashboardUIStore(this);

        // legacy stores
        this.extraServicesStore = allStores.extraServicesStore;
        this.hotelStore = allStores.hotelStore;
        this.alertsStore= allStores.alertsStore;



        /*
        this.authUIStore = new AuthUIStore(this);
        this.dashboardUIStore = new DashboardUIStore(this);

        this.sidebarStore = new SidebarStore(this);
        this.baggageStore = new BaggageStore(this, Transport);

        this.paymentStore = new PaymentStore(this, Transport);

        this.hotelStore = new HotelStore(this);
        this.seatsStore = new SeatsStore(this, Transport);
        this.stepsStore = new StepsStore(this);

        this.passwordResetStore  = new PasswordResetStore(this, Transport);

        this.checkInPopup = new PopupStore();
         */
    }
}



const allCheckinStores = new RootCheckInStore();

export default allCheckinStores;