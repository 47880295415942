import {inject, observer} from "mobx-react";
import React from "react";
import {StepTypes} from "app/stores/steps.store";
import {history} from "app/services/custom.router";
import {RegularButtonBig} from "app/desktop/ui/components/buttons/regular-button/regular.button";
import classnames from "classnames";
import { getPNRSource } from "app/services/session.service";

@inject("baggageStore", "pnrStore","seatsStore", "extraServicesStore") @observer
class FinalSidebar extends React.Component {
    render() {
        const totalPrice =  (this.props.baggageStore.totalBaggagePrice + this.props.seatsStore.totalPrice + this.props.extraServicesStore.totalPrice).toFixed(2);
        return (
            <div className={"cs-sidebar-row bottom"}>
                <div className={"col-txt-final"}>
                    סה״כ לתשלום
                </div>
                <div className={"col-txt-price num"}>
                    {this.props.pnrStore.currencySymbol}{totalPrice}
                </div>
            </div>
        );
    }
}

@inject ( 'dashboardUIStore', 'baggageStore', 'stepsStore','seatsStore', 'extraServicesStore') @observer

class Continue extends React.Component {
    render() {
        const baggageStore = this.props.baggageStore;
        const stepsStore = this.props.stepsStore;
        const seatsStore = this.props.seatsStore;
        const extraServicesStore = this.props.extraServicesStore;

        const pnrSource = getPNRSource();
        const disabaledAEROCRS = (pnrSource) && pnrSource === "AEROCRS";

        const onClickHandle = () => {

            if (disabaledAEROCRS) {
                return;
            }
            const currentStep = stepsStore.currentStep;

            const nextStep = stepsStore.nextStep;
            if (nextStep) {
                if (currentStep === StepTypes.BAGGAGE) {
                    if (baggageStore.isBaggageAvailable && !baggageStore.isPaidBaggageSelected) {
                        this.props.dashboardUIStore.addBaggageDialog.openPopup();
                    } else {
                        stepsStore.setStep(nextStep.type);
                        history.push(nextStep.path);
                    }
                } else if (currentStep === StepTypes.SEATS) {
                    if (seatsStore.totalPrice === 0 &&
                        seatsStore.areSeatsAvailable &&
                        !seatsStore.flightHasOnePassenger && !seatsStore.pnrWithAdultAndChild) {
                        this.props.dashboardUIStore.addSeatsDialog.openPopup();

                    } else {
                        stepsStore.setStep(nextStep.type);
                        history.push(nextStep.path);
                    }
                } else {
                    stepsStore.setStep(nextStep.type);
                    history.push(nextStep.path);
                }
            }
        }

        const skipHandle = () => {
            const currentStep = stepsStore.currentStep;

            if (currentStep === StepTypes.BAGGAGE) {
                this.props.baggageStore.resetSelectedBaggage();
                this.props.dashboardUIStore.addBaggageDialog.openPopup();
            }

            if (currentStep === StepTypes.SEATS) {
                this.props.seatsStore.resetSelectedSeats();

                //Don't open popup if pnr with one adult and child/children
                if (seatsStore.pnrWithAdultAndChild) {
                    const nextStep = stepsStore.nextStep;
                    stepsStore.setStep(nextStep.type);
                    history.push(nextStep.path);
                    return;
                }

                this.props.dashboardUIStore.addSeatsDialog.openPopup();
            }

            if (currentStep === StepTypes.EXTRA_SERVICES) {
                const nextStep = stepsStore.nextStep;
                stepsStore.setStep(nextStep.type);
                history.push(nextStep.path);
            }

        }

        const currentStep = stepsStore.currentStep;
        const btnNextText = (currentStep === StepTypes.INFORMATION) ? "המשך להוספה" : "שמור והמשך";

        let skipBtn = "";
        const cssNext = classnames("btn continue", {"disabled" : disabaledAEROCRS})
        switch (currentStep) {
            case StepTypes.BAGGAGE:
                if (baggageStore.totalBaggagePrice === 0) {
                    skipBtn = <RegularButtonBig onClick={skipHandle}>דלג</RegularButtonBig>;
                }
                break;

            case StepTypes.SEATS:
                if (seatsStore.totalPrice === 0) {
                    skipBtn = <RegularButtonBig onClick={skipHandle}>דלג</RegularButtonBig>;
                }
                break;

            case StepTypes.EXTRA_SERVICES:
                if (extraServicesStore.totalPrice === 0) {
                    skipBtn = <RegularButtonBig onClick={skipHandle}>דלג</RegularButtonBig>;
                }
                break;

            case StepTypes.INFORMATION:
            default:
        }

        return (
            <div className={"sidebar-row button"}>
                {skipBtn}
                <div className={cssNext} onClick={onClickHandle}><div className={"icon-arrow-next"}/> {btnNextText} </div>
            </div>
        );
    }
}

export {Continue, FinalSidebar};