import {inject, observer} from "mobx-react";
import React from "react";
import {ButtonContinue} from "app/desktop/ui/components/buttons/button-continue/button.continue";
import {BlueButtonBig} from "app/desktop/ui/components/buttons/blue-button/blue.button";
import {RegularButtonBig} from "app/desktop/ui/components/buttons/regular-button/regular.button";
import {NotAvailableSeats} from "app/desktop/ui/components/dialogs/dlg-seat-selection/dlg.seat.selection";

@inject ("dashboardUIStore", "seatsStore", "pnrStore") @observer
class NavigationButtons extends React.Component {

    render() {

        const dlg = this.props.dashboardUIStore.seatSelectionDialog;
        const s = this.props.seatsStore;
        const pnr = this.props.pnrStore;


        const skipButton = (s.hasNextFlight && s.flight1SeatsCount === 0) ? <ButtonContinue onClick={() => {
            s.setFetchSeatsType("skipAndFetchSeats");
            s.fetchSeats(true);
        }}>דלג לטיסת חזור</ButtonContinue> : "";

        const skipButton2 = ((s.isLastFlight && s.totalPrice === 0) && !s.tempReservedLength()) ? <ButtonContinue onClick={() => {
            s.setFetchSeatsType("finalFetchSeats");
            s.fetchSeats(true);
            dlg.closePopup();
        }}>דלג</ButtonContinue> : "";

        const saveButton = (s.hasNextFlight && s.flight1SeatsCount > 0) ? <BlueButtonBig withIcon onClick={() => {
            s.setFetchSeatsType("fetchSeatsAndContinue");
            s.fetchSeats(true);
        }}><div className={"icon-arrow-next-blue"}/>שמור והמשך </BlueButtonBig> : "";

        const saveButton2 = ((s.isLastFlight && s.totalPrice>0) || (s.totalPrice === 0 && s.tempReservedLength() && s.isLastFlight)) ? <BlueButtonBig onClick={() => {
            //this.props.dashboardUIStore.seatsBooking.openPopup();
            s.setFetchSeatsType("finalFetchSeats");
            s.fetchSeats(true);
            // s.fetchSeats();
        }}>סיים </BlueButtonBig> : "";

        const totalPrice = (s.totalPrice > 0) ? (<div className={"price-col"}>
            <div className="text">סה״כ תוספת תשלום<br/>עבור הוספת הושבה</div>
            <div className="price">{pnr.currencySymbol}{s.totalPrice}</div>
        </div>) : "";

        const seatErrorsTransform = {transform: "translate(-50%, -50%)"};


        return (

            <div className={"sticky-footer"}>
                <div className={"wrap"}>
                    <div className={"col-btn-back"}>
                        {(s.activeFlight === 1) ? <RegularButtonBig onClick={() => {
                            s.setActiveFlight(0);
                        }}>חזור <div className={"icon-back"}/> </RegularButtonBig> : ""}
                    </div>

                    <NotAvailableSeats text={s.errText} transform={seatErrorsTransform}/>
                    <div className={"col-btn-continue"}>
                        {totalPrice}
                        {skipButton}
                        {skipButton2}
                        {saveButton}
                        {saveButton2}
                    </div>
                </div>
            </div>
        );
    }
}

export {NavigationButtons}