import React from 'react'
import {RegularButton} from "app/desktop/ui/components/buttons/regular-button/regular.button";
import {BlueButton} from "app/desktop/ui/components/buttons/blue-button/blue.button";
import {inject, observer} from "mobx-react";
import {ModalDialog} from "app/desktop/ui/components/modal/modal";

import 'app/desktop/ui/components/dialogs/dlg-restricted-seats/dlg.restricted.seats.scss'

@inject ("dashboardUIStore", "seatsStore", "isWebCheckIn") @observer
class DlgRestrictedSeats extends React.Component {
    render() {
        const dlg = this.props.dashboardUIStore.restrictedSeatsDlg;
        const s = this.props.seatsStore;
        let isNotAdult;
        if (dlg.isOpen) {
            isNotAdult = !s.passengerIsAdult;
        }

        return (
            <ModalDialog isOpen={dlg.isOpen} onClose={() => {
                dlg.closePopup();
            }}>
                <div className={"dlg-restricted-seats"}>
                    <div className={"wrap"}>
                        <div className={"title"}>בחרתם מושבים מעולים!</div>
                        <div className={"sub-title"}>לשימת ליבכם - מדובר במושבים ביציאת חירום, אשר הנוסעים הבאים אינם רשאים לשבת בהם:</div>
                        <ul>
                            <li>תינוקות וילדים עד גיל 18</li>
                            <li>נוסעים בעלי מגבלת ניידות, שמיעה או ראייה.</li>
                            <li>נוסעים הנדרשים לסיוע מיוחד ו/או שאינם יכולים לפעול לפי הוראות הצוות במקרה אפשרי של פינוי חירום.</li>
                            <li>נוסעים הטסים עם חיות מחמד בתא הנוסעים.</li>
                            <li>נשים הרות ו/או כל נוסע המשמש כאחראי על נוסע אחר בטיסה.</li>
                            <li>נוסעים הנדרשים להארכת חגורת הבטיחות.</li>
                            <li>נוסעים הטסים עם חיית מחמד בתא הנוסעים.</li>
                        </ul>

                        <div className={"buttons"}>
                            <div className={"btn-1"}>
                                <RegularButton onClick={() => {
                                    dlg.closePopup();
                                }}>בטל בחירה</RegularButton>
                            </div>
                            <div className={"btn-2"}>
                                <BlueButton disabled={isNotAdult} onClick={() => {
                                    dlg.closePopup();
                                    this.props.seatsStore.activateRestrictedSeat();
                                }}>אני עומד/ת בתנאים בואו נמשיך</BlueButton>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalDialog>
        )
    }
}

export {DlgRestrictedSeats}