import {autorun, observable, action, computed} from "mobx";
import ScreenTypes from "app/desktop/checkin/screen.types";
import {getActivePNR} from "../services/session.service";
// import checkinInfo from 'app/desktop/checkin/checkin-popup/checkinInfo.json';
import access from "safe-access";
import {PopupStore} from "app/stores/popup.store";
import moment from "moment";
import {history} from "app/services/custom.router";


class CheckInUIStore {
    @observable activeScreen = ScreenTypes.PASSENGER_DETAILS;
    @observable title = "";
    @observable curDirection;
    @observable isLoading = false;
    @observable lastClicked = null;
    @observable randomlyPicked = true;
    @observable initialRandomSeatsActivated = false;
    @observable baggageWarningPopup = new PopupStore();
    @observable seatsWarningPopup = new PopupStore();
    @observable scrollRef = null;

    @observable isAllValid = true;
    @observable invalidFound = false;
    @observable invalidPassengerId = null;

    @observable boardingPass = {
        title: "",
        inputValue: "",
        type: "",
        btnText: "שלח"
    };
    @observable infoPopupOpened = false;
    @observable boardingPassLoading = false;

    constructor(root, transport) {
        this.root = root;
        this.transport = transport;

        autorun(() => {
            const isOpen = root.checkInPopup.isOpen;
            console.log("root.checkInPopup.isOpen", isOpen);
            if (isOpen && this.root.isWebCheckIn) {
                root.pnrStore.loadData(getActivePNR());
                root.countriesStore.fetchCountries();
            }
        })
    }

    @action setActiveScreen(screen) {
        this.activeScreen = screen;
    }

    @action deactivateAutomaticRandomSeatsExecution() {
        this.initialRandomSeatsActivated = true;
    }

    @computed get shouldOpenBaggagePopup() {
            //checks if no baggage paid before and no baggage selected and if there is available baggage at all
            return this.root.baggageStore.isBaggageAvailable &&
                !this.root.baggageStore.isBaggageSelected &&
                !this.root.baggageStore.pnrHasPassengerWithBaggagePaid;
    }


    @action moveToNextScreen(forceNext= undefined) {
        this.root.seatsStore.deleteErrorMsg();
        const screens = this.availableScreens;
        const currentScreenIndex = screens.indexOf(this.activeScreen);

        if (currentScreenIndex === -1) return;
        const nextScreen = screens[currentScreenIndex + 1];

        if (forceNext) {
            //go to the next screen without additional checking
            this.setActiveScreen(nextScreen);
            return;
        }

        switch (this.activeScreen) {
            case "BAGGAGE":
                this.root.seatsStore.setFetchSeatsType(this.initialRandomSeatsActivated ? 'webCheckFetchSeatsOnBack' : 'getRandomSeatsFetch');
                break;
            case "PASSENGER_DETAILS":
                if (!this.isAllPassengersValid()) return;
                break;
            case "SEATS":
                if (!this.allPassengersSelectedSeats) return;
                if (!this.root.pnrStore.currentFlightAllSeatsPaid) {
                    this.root.seatsStore.setFetchSeatsType("webCheckFetchSeats");
                    return this.root.seatsStore.fetchSeats(true);
                }
                if (!this.root.baggageStore.isBaggageAvailable || this.root.baggageStore.baggageTotalPriceByDirection <= 0) {
                    this.root.dashboardUIStore.ticketCheckIn.openPopup();
                    return;
                }
                break;
            case "CONFIRMATION" || currentScreenIndex === screens.length - 1:
                history.push('/dashboard');
                window.location.reload();
                return;
            default:
                console.log("active screen not detected");

        }

        //TODO: for some reason it's not fired
        // seats.store 509 line
        this.setActiveScreen(nextScreen);
    }


    @action moveToPrevScreen() {
        const screens = this.availableScreens;
        for (var i = 1; i < screens.length; i++) {
            if (screens[i] === this.activeScreen) {
                this.setActiveScreen(screens[i - 1]);
                break;
            }
        }
    }


    @computed get availableScreens() {
        // make dynamic array
        return [
            ScreenTypes.PASSENGER_DETAILS,
            ScreenTypes.BAGGAGE,
            ScreenTypes.SEATS,
            ScreenTypes.PAYMENT,
            ScreenTypes.IFRAME,
            ScreenTypes.CONFIRMATION
        ]
    }

    @action setLastScreen() {
        this.root.seatsStore.setFetchSeatsType("confirmationPageFetch");
        this.root.pnrStore.loadData(getActivePNR());
    }

    @computed get isIframeScreen() {
        return this.activeScreen === ScreenTypes.IFRAME;
    }

    @computed get isPaymentScreen() {
        return this.activeScreen === ScreenTypes.PAYMENT;
    }

    @computed get isConfirmationScreen() {
        return this.activeScreen === ScreenTypes.CONFIRMATION;
    }

    @computed get getCheckInGUID() {
        return access(this.root.pnrStore.data, '.data.flightCheckInInfo.guid');
    }

    //run on pnr loaded
    @action setTitle() {
        // const checkInStatus = checkinInfo.checkInStatus.name;
        if (access(this.root.pnrStore.checkInData, 'checkInStatus.name') === "Disabled") {
            return this.title = "ביצוע צ'ק אין אונליין";
        }
        this.title = access(this.root.pnrStore.checkInData, '.message');
    }

    @action setCurrentDirection(ind) {
        if (ind !== -1) {
            this.curDirection = ind;
        }
    }

    @computed get flightDirection() {
        if (this.curDirection === 0) {
            return "הלוך";
        }
        if (this.curDirection === 1) {
            return "חזור";
        }
    }

    @action setBoardingPassDetails(inputType) {
        const detailsMap = {
            phone: {
                type: "phone",
                id: 1,
                title: "לקבלת כרטיסי הטיסה\n הכנס מספר טלפון",
                inputValue: "",
                btnText: "שלח",
                label: "מס' הטלפון"
            },
            email: {
                type: "email",
                id: 2,
                title: "לקבלת כרטיסי הטיסה\n הכנס כתובת דוא\"ל",
                inputValue: "",
                btnText: "שלח",
                label: "כתובת דוא\"ל"
            },
            sent: {
                type: "sent",
                title: "כרטיסי הטיסה נשלחו\n בהצלחה",
                btnText: "סיום",
            },
            default: {
                title: "",
                inputValue: "",
                type: "",
                btnText: "שלח"
            }
        }

        const details = detailsMap[inputType] || detailsMap.default;
        this.boardingPass = {...this.boardingPass, ...details};
    }

    @action setBoardingPassValue(value) {
        return this.boardingPass = {...this.boardingPass, inputValue: value};
    }

    @action informationPopup() {
        this.infoPopupOpened = !this.infoPopupOpened;
    }

    @action closePopup() {
        this.infoPopupOpened = false;
    }

    @action setIsLoading(status) {
        this.isLoading = status;
    }

    @action setLastClicked(timeStamp) {
        this.lastClicked = timeStamp;
    }

    @action setRandomlyPicked(status) {
        this.randomlyPicked = status;
    }

    @action setBoardingPassLoading(status) {
        this.boardingPassLoading = status;
    }

    //initiate iframe payment on closing final popup warning
    @action navigateNextAndInitiatePayment() {
        this.moveToNextScreen();
        this.root.paymentStore.initiatePayment();
    }

    // create array with passengers without paid seats and check if all of them selected a seat
    @computed get allPassengersSelectedSeats() {
        return this.root.passengersStore.passengersExcludeInfants.filter(p => {
            return this.curDirection === 1 ?
                !this.root.pnrStore.seat2IsPaid(p.id-1) :
                !this.root.pnrStore.seat1IsPaid(p.id-1)
        })
            //TODO: check if it returns boundary warnings
            .every(p=> access(p,`.seats[${this.curDirection}]`));
    }

    @computed get somePassengerHasNoSeat() {
        let getServicesInfoByDirection = this.curDirection === 1 ? this.root.pnrStore.date2Baggage : this.root.pnrStore.date1Baggage;
        return this.root.passengersStore.passengersExcludeInfants.some(p => {
            let passengerHasPaidSeat = getServicesInfoByDirection.some(pass=> (pass.id === p.id) && pass.seats[0].id && pass.seats[0].id.toString()  === p.id);
            return p.seats[this.curDirection] === null && !passengerHasPaidSeat;
        });
    }

    @action setScrollRef = (ref) => {
        this.scrollRef = ref;
    };

    @action scrollToTop = () => {
        if (this.scrollRef) {
            const div = this.scrollRef;
            div.scrollTop = 0;
        }
    };

    @computed get invalidText() {
        return !this.isAllValid && "אחד מהנתונים שהזנת אינו תקין - אנא נסה שנית";
    }

    @action isAllPassengersValid() {
        this.setInvalidFound(false);
        this.invalidPassengerId = null;
        let isValid = true;
        this.root.passengerDetailsStore.passengers.forEach((p) => {
            p.validatePassenger();
            if (!p.isValid) {
                isValid = false;

                //Mobile functionality
                if (!this.invalidFound) {
                    this.setInvalidFound(true);
                    // console.log("found ", p)
                    //    should activate scroll here
                    this.invalidPassengerId = p.id;
                    console.log(this.invalidPassengerId)
                }


            }
        });
        this.isAllValid = isValid;
        return this.isAllValid;
    }

    @action setInvalidFound(status) {
        this.invalidFound = status;
    }

    @action autofillDetails() {
        let passengers = access(this.root.passengerDetailsStore, "passengers");
        passengers && passengers.forEach(p => {
            p.birthDay.value = p.passengerType === 3 ? moment().subtract(6, 'months').format('DD/MM/YYYY'):
                //set appropriate birthday value for all kind of passengers
                p.passengerType === 2 ? moment().subtract(7, 'years').format('DD/MM/YYYY') : "31/01/1995";

            p.gender.value = "Female";
            p.passportExpirationDate.value = "31/01/2040";
            p.passport.value = "123456789";
        });
    }

}

export default CheckInUIStore;