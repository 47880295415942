import axios from 'axios'

const BASE_URL = process.env.REACT_APP_API_URL;
const DEFAULT_CONFIG = { headers: { "Content-Type": "application/json" } };
//const MULTIPART_CONFIG = { headers: { "Content-Type": "multipart/form-data" } };
const URLENCODED_CONFIG = { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } }

const getStoredAuthKey = () => {
    const myStorage = window.localStorage;

    if (!myStorage) {
        return  null;
    }

    return myStorage.getItem("auth-key") || "";
}

const setAuthKey = (key) => {
    const myStorage = window.localStorage;

    if (!myStorage) {
        return  null;
    }
    // console.log("Save token to localStorage", key);
    myStorage.setItem("auth-key", key)

}

// AUTH
const PerformAuthorization = (data) => {
    const CONFIG = { headers: { "Content-Type": "application/json" }, ignoreAuth : true};
    return axios.post(BASE_URL + "/api/v1/bookings/",
        data,
        CONFIG);
};

const PerformAuthorizationStep2 = (data) => {
    const CONFIG = { headers: { "Content-Type": "application/json" }, ignoreAuth : true};
    return axios.post(BASE_URL + "/api/v1/bookings/login/",
        data,
        CONFIG);
};

const PerformAutoAuth = (encryptedPnr) => {
    const CONFIG = { headers: { "Content-Type": "application/json" }, ignoreAuth : true};
    return axios.get(BASE_URL + "/api/v1/bookings/pnr-token-login/" + encryptedPnr,
        CONFIG);
};

const getPNRData = (pnr) => {

    return axios.get(BASE_URL + "/api/v1/bookings/"+pnr, DEFAULT_CONFIG);
    //return axios.get('data/pnr2.json', DEFAULT_CONFIG);
}

const downloadTicket = (pnr) => {
    const url = BASE_URL + "/api/v1/bookings/" + pnr + "/files?docType=Itinerary";

    return axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
    });

}

const fetchBaggageData = (pnr) => {
    let url;
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        url = "/data/baggage.json";
    } else {
        url = BASE_URL + "/api/v1/bookings/"+ pnr + '/ancillaries/ssrs';
    }
    url = BASE_URL + "/api/v1/bookings/"+ pnr + '/ancillaries/ssrs';
    return axios.get(url, DEFAULT_CONFIG);
}

const performInitialPayment = (pnr, data) => {
    const CONFIG = { headers: { "Content-Type": "application/json" }};

    let url = BASE_URL + "/api/v1/bookings/"+ pnr + '/ancillaries';
    // let url = `https://test.my.kavei.co.il/api/v1/bookings/${pnr}/ancillaries`;

    return axios.post(url,
        data,
        CONFIG);
}

const notifyPaymentStatus = (data) => {
    let url = BASE_URL + "/api/v1/bookings/notifyPaymentStatus";
    // let url = "https://test.my.kavei.co.il/api/v1/bookings/notifyPaymentStatus";

    return axios.post(url,
        data)


}

const fetchSeatsData = (pnr,guid) => {
    let url;
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        url = "/data/seats.json";
    } else {
        url = BASE_URL + "/api/v1/bookings/"+ pnr + '/ancillaries/ssrs';
    }
    url = BASE_URL + "/api/v1/bookings/"+ pnr + '/ancillaries/seats?guid='+guid;

    //url = "/data/seats2.json";
    return axios.get(url, DEFAULT_CONFIG);
}

const PerformSeatsBooking = (pnr, guid, data) => {
    const url = BASE_URL + "/api/v1/bookings/"+ pnr + '/ancillaries/seats?guid='+guid;
    return axios.post(url,
        data,
        DEFAULT_CONFIG);

}

const performAgentAuth = (data) => {
    const url = BASE_URL + '/Token';

    return axios.post(url,
        data,
        URLENCODED_CONFIG
        );
}

function performSearch (data) {
    const url = BASE_URL + '/api/v1/bookings/agent/login';

    return axios.post(url,
        data,
        DEFAULT_CONFIG);
}

function performPasswordReset(data) {
    const url = BASE_URL + '/api/Account/SendResetPasswordEmail';

    return axios.post(url,
        data,
        DEFAULT_CONFIG);
}

function performPasswordChange(data) {
    const url = BASE_URL + '/api/Account/SetPassword';

    return axios.post(url,
        data,
        DEFAULT_CONFIG);
}

const fetchCountriesData = (data) => {
    let url = BASE_URL + '/api/v1/info/passportNationalities';
    return axios.get(url, DEFAULT_CONFIG);
}

const fetchRandomSeats = (pnr, guid, data) => {
    let url = BASE_URL + '/api/v1/bookings/' + pnr + '/ancillaries/pickSeatsRandomly?guid=' + guid;

    return axios.post(url,
        data,
        DEFAULT_CONFIG);
}

const downloadBoardingPass = (encryptedPnr) => {
    // https://test.customers-api.kavei.co.il
    let url = BASE_URL + '/api/v1/bookings/download-boardingpass/' + encryptedPnr;
    return axios.get(url, { headers: { "Content-Type": "application/pdf" }, responseType: "blob" });
}

const sendBoardingPass = (data) => {
    let url = BASE_URL + '/api/v1/bookings/send-boardingpass-link'
    return axios.post(url,
        data,
        DEFAULT_CONFIG)
}

const sendRecipeToEmail = (pnr, email) => {
    let url = BASE_URL + '/api/v1/bookings/' + pnr + '/send-confirmation?email=' + email;
    return axios.get(url, DEFAULT_CONFIG)
}

export default {
    PerformAuthorization,
    PerformAuthorizationStep2,
    PerformAutoAuth,
    getPNRData,
    setAuthKey,
    getStoredAuthKey,
    downloadTicket,
    fetchBaggageData,
    performInitialPayment,
    notifyPaymentStatus,
    fetchSeatsData,
    PerformSeatsBooking,
    performAgentAuth,
    performSearch,
    performPasswordReset,
    performPasswordChange,
    fetchCountriesData,
    fetchRandomSeats,
    downloadBoardingPass,
    sendBoardingPass,
    sendRecipeToEmail
}