import React from "react";
import ImportantInfo from "app/desktop/ui/components/important-info/important-info";
// import ImportantInfo from "app/mobile/ui/components/important-info/important-info";
import {inject, observer} from "mobx-react";

@inject("baggageStore") @observer
class ImportantInfoComponent extends React.Component {
    render() {
        return (
            <ImportantInfo
                infoText={
                    <>
                        שימו לב, זו קריאה אחרונה להוספת כבודה בתעריף מוזל. לאחר השלמת תהליך הצ'ק-אין,<br/>
                        ניתן יהיה להוסיף כבודה רק בשדה התעופה, ובתעריף גבוה משמעותית.<br/>
                        אנא וודאו כי הכבודה איתה אתם מתכננים להגיע לטיסה תואמת את
                        <a className={"baggage-info-text"} onClick={() => this.props.baggageStore.informationPopup()} rel="noopener noreferrer"> מדיניות הכבודה </a>
                        שלנו.
                    </>
                }
                iconName={"icon-info"}
                showWarning={true}
            />
        );
    }
}

export {ImportantInfoComponent}