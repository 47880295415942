import React from 'react';
import {TxtField} from "app/desktop/ui/components/txt-field/txt.field";
import {inject, observer} from "mobx-react";
import {MainButton} from "app/desktop/ui/components/buttons/main-button/main.button";
import {Preloader} from "app/desktop/ui/components/preloader/preloader";
import {history} from 'app/services/custom.router'

require('app/desktop/ui/pages/agent/agent.login.scss');

@inject("authStore") @observer
class ErrorMessage extends React.Component {
    render() {
        const {authStore} = this.props;

        return (authStore.agentErrorMessage) ? <div className={"error"}>{authStore.agentErrorMessage}</div> : "";
    }
}

@inject("authStore") @observer
class AgentLogin extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);


    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.props.authStore.isAgentAuthInProcess) {
            console.log("already loading...")
        } else {
            this.props.authStore.performAgentAuth();
        }

    }

    render() {
        const {authStore} = this.props;
        const status = (authStore.isAgentAuthInProcess) ? <Preloader/> : (authStore.agentErrorMessage) ? <ErrorMessage/>  : "";

        return (
            <div className={"login-page"}>
                <div className={"cs-login-form"}>
                    <div className={"header-image"}/>
                    <div className={"form-body"}>
                        <div className={"form-header"}>
                            <div className={"line-1"}>ברוכים הבאים</div>
                            <div className={"line-2"}>כניסת סוכנים</div>

                            <form action={"#"} onSubmit={this.handleSubmit}>
                                <div className={"rows"}>
                                    <div className={"row"}>
                                        <TxtField placeholder={"שם משתמש"}
                                                  value={authStore.username}
                                                  isError={authStore.dataError}
                                                  disabled={authStore.isLoading}
                                                  maxLength={64}
                                                  tooltip = {"שם משתמש"}
                                                  name={"agent-email"}
                                                  onChange = {(e) => {
                                                      authStore.setUsername(e.target.value);
                                                  }}/>
                                    </div>

                                    <div className={"row"}>
                                        <TxtField placeholder={"סיסמא"}
                                                  type={"password"}
                                                  value={authStore.password}
                                                  isError={authStore.dataError}
                                                  disabled={authStore.isLoading}
                                                  maxLength={64}
                                                  name={"agent-password"}
                                                  tooltip = {"סיסמא"}
                                                  onChange = {(e) => {
                                                      authStore.setPassword(e.target.value);
                                                  }}/>
                                    </div>

                                    <div className={"row reset-password"}>
                                        <a href={"/password-reset"} onClick={(e) => {
                                            e.preventDefault();
                                            history.push('/password-reset');
                                        }}><strong>שכחתי סיסמה</strong></a>
                                    </div>

                                    <div className={"row"}>
                                        {status}
                                    </div>

                                    <div className={"row"}>
                                        <div className={"button-placeholder"}>
                                            <MainButton  onClick={() => {authStore.performAgentAuth();}}>כניסה</MainButton>
                                        </div>
                                    </div>
                                </div>

                                <input type={"submit"} style={{"visibility":"hidden", "display":"none"}}/>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export {AgentLogin}