import access from 'safe-access';
import { AuthStoreErrors } from 'app/stores/auth.store';

function parseAndSetDataFromRedirect (authStore, props) {
    const {redirectData} = props.match?.params;
    if (!redirectData) {
        console.warn("no redirect data");
        return;
    }

    console.log('[RD]',redirectData);

    // decode data and create JSON
    try {
        const rdStr = atob(redirectData);
        const rd = JSON.parse(rdStr);
        console.log(rd);

        const phone = access(rd,'data.phone');
        const pnr = access(rd,'data.pnr');
        
        if (phone && pnr) {
            console.log("set phone & pnr",phone, pnr);
            authStore.setPhone(phone);
            authStore.setPNR(pnr);
        
            authStore.smsSent = true;
            authStore.errorStatus = AuthStoreErrors.NONE;
            authStore.authTime = new Date();   
        }
               
    }
    catch(e) {
        console.error(e);
    }

}

export {parseAndSetDataFromRedirect}