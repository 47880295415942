import React from "react";
import {inject, observer} from "mobx-react";
import {InfoPopup} from "../../../../../../common/pages/dashboard/components/baggage-table/baggage.info.button";
import {BaggageContentInner} from "../../../../../../common/ui/baggage.content.inner";

@inject("baggageStore", "isWebCheckIn", "pnrStore", "checkInUIStore") @observer
class BaggageContent extends React.Component {
    render() {
        const {isWebCheckIn, baggageStore, direction, secondFlight} = this.props;
        const passengersExist = baggageStore.baggageTable.length > 0;
        const oneWayFlight = this.props.pnrStore.isOneWayFlight;
        const directionTitle = !isWebCheckIn && passengersExist && !oneWayFlight && <div className="section-header">{direction}</div>;

        return (
            <>
                {directionTitle}
                <InfoPopup airline={this.props.airline}/>
                {baggageStore.baggageTable.map((p, index) => {
                    return (
                        secondFlight ?
                            <BaggageContentInner key={"p" + index}
                                               services={p.baggageBackOptions}
                                               p={p}
                                               index={index}
                                               hasTrolley={p.hasTrolleyBack}
                                               hasBaggage={p.hasBaggageBack}
                            /> :
                            <BaggageContentInner key={"p" + index}
                                               services={p.baggageToOptions}
                                               p={p}
                                               index={index}
                                               hasTrolley={p.hasTrolleyTo}
                                               hasBaggage={p.hasBaggageTo}
                            />
                    )
                })}
            </>
        )
    }
}
export {BaggageContent};

