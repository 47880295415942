import React from 'react'

class PageCancel extends React.Component{
    render() {
        return (
            <div className={"page-error"}>
                <div className={"message"}>התשלום בוטל על ידי הנוסע</div>
            </div>
        );
    }
}


export {PageCancel}