import {action, computed, observable} from "mobx";
import moment from "moment";
import access from "safe-access";
import {datesArray} from "app/services/dates.array";

const getPassengerNameById = (passengers, paxId) => {
    let n = "";
    const o = passengers.find((p) => {
        return (p.id.toString() === paxId.toString());
    });

    if (o) {
        return o.title + " " + o.fullName;
    }

    return n;
}

class HotelStore {
    @observable hotels = [];

    constructor (root) {
        this.root = root;
    }

    @action setData (hotels) {
        this.hotels = hotels;
    }
    @computed get isHotelData() {
        return this.hotels && this.hotels.length > 0;
    }

    @computed get checkInDate() {
        if (!this.hotels) {
            return "";
        }
        const d = this.hotels && this.hotels.length > 0 && moment(access(this.hotels, '[0].checkinDate'));
        return d && d.format('DD.MM.YY');
    }

    @computed get day1Short () {
        if (!this.hotels) {
            return "";
        }

        const d = this.hotels && this.hotels.length > 0 && moment(access(this.hotels, '[0].checkinDate'));
        return d && datesArray[d.format('d')];
    }


    @computed get checkOutDate() {
        if (!this.hotels) {
            return "";
        }

        const d = moment(access(this.hotels, '[0].checkoutDate'));
        return d.format('DD.MM.YY');
    }

    @computed get numberOfNights () {
        const d1 = moment(access(this.hotels, '[0].checkinDate'));
        const d2 = moment(access(this.hotels, '[0].checkoutDate'));

        if (d1 && d2) {
            return d2.diff(d1, 'days');
        } else {
            return "";
        }


    }

    @computed get day2Short () {
        if (!this.hotels) {
            return "";
        }

        const d = moment(access(this.hotels, '[0].checkoutDate'));
        return datesArray[d.format('d')];
    }

    @computed get hotelName() {
        if (!this.hotels) {
            return "";
        }

        return access(this.hotels, '[0].hotel');
    }

    @computed get hotelRemark() {
        if (!this.hotels) {
            return "";
        }

        return access(this.hotels, '[0].remark');
    }

    @computed get starsArray() {
        let rating = access(this.hotels,'[0].rating') || 0;
        const a = [];

        while (rating > 0 || a.length < 5) {
   //         console.log("rating", rating, a);
            if (rating >= 1) {
                a.push('full');
                rating--;
            } else if (rating > 0) {
                    a.push('half');
                    rating = rating - 0.5;
                } else {
                a.push('empty');
            }
        }

        return a;
    }

    @computed get hotelRooms() {
        if (!this.hotels) {
            return [];
        }

        return this.hotels.map((hotel,ind) => {
            return {
                "number" : ind+1,
                "boardBase" : hotel.boardBase,
                "roomType" : hotel.roomType
            }
        })
    }

    @computed get fullRooms() {
        if (!this.hotels) {
            return [];
        }

        const passengers = access(this.root.pnrStore.data, 'data.passengersInfo') || [];

        const rooms = this.hotels.map((room) => {
            const pArr = room.paxIds.map((paxId) => {
                return getPassengerNameById(passengers, paxId)
            })


            return {
                "boardBase" : room.boardBase,
                "roomType" : room.roomType,
                "passengers" : pArr
            }
        })

        return rooms;
    }
}

export {HotelStore}