import React from 'react'
import classnames from 'classnames'
import 'app/desktop/ui/components/generic-modal/generic.modal.scss'
import {Scrollbar} from "react-scrollbars-custom";
import {inject, observer} from "mobx-react";

class GenericModalPopup extends React.Component {
    render() {
        const css = classnames("generic-modal", {"open" : this.props.isOpen});

        const externalClick = (e) => {
            e.stopPropagation();
            if (this.props.closeOnExternal) {

                this.props.onClose();
            }
        }

        return (
            <div className={css}>
                <div className="cs-popup-bg" onClick={externalClick}/>
                <div className={"cs-popup-wrap"}>
                    {this.props.closeBtn && (
                        <div className={"cs-popup-close"} onClick={this.props.onClose}/>
                    )}
                    <div className={"cs-popup-content"}>
                        {this.props.children}


                    </div>
                </div>
            </div>
        )
    }
}

class Header extends React.Component {
    render() {
        return (
            <header>{this.props.children}</header>
        )
    }
}

class Footer extends React.Component {
    render() {
        return (
            <footer>{this.props.children}</footer>
        )
    }
}

@inject("checkInUIStore") @observer
class Section extends React.Component {
    constructor(props) {
        super(props);
        this.scrollDiv = React.createRef();
    }

    componentDidMount() {
        this.props.checkInUIStore.setScrollRef(this.scrollDiv.current);
    }

    render() {
        if (this.props.disableCustomScroll) {
            return (
            <section>
                <div className={"scroll-window"}>
                    <div className={"scroll-window-wrap"}>
                        <div className={"scroll-window-scroll"} ref={this.scrollDiv}>
                        {this.props.children}
                        </div>
                    </div>
                </div>
            </section>
            );
        }
        return (
            <section>
                <Scrollbar noDefaultStyles>
                    {this.props.children}
                </Scrollbar>
            </section>
        )
    }
}

Section.defaultProps = {
    disableCustomScroll : false
}

GenericModalPopup.defaultProps = {
    isOpen : false,
    onClose : () => {},
    closeOnExternal: true,
    footer: null,
    closeBtn: true,
}

const GenericModal = {
    Popup: GenericModalPopup,
    Header : Header,
    Section : Section,
    Footer : Footer
}

export default GenericModal