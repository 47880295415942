function iframeOnLoad(paymentStore, ref) {
    console.log("iframeOnLoad", paymentStore, ref)
    try {
        // console.log("iframe loaded", this.myRef.current.contentWindow.location.href);

        const url = ref.current.contentWindow.location.href;

        if (url && (url.indexOf('success') > -1 || url.indexOf('booking/loading') > -1)) {
            // console.log("success");
            paymentStore.iframeResult = "success";
            paymentStore.sendPaymentStatus("success");
        }

        if (url && url.indexOf('error') > -1) {
            // console.log("error");
            paymentStore.iframeResult = "error";
            paymentStore.sendPaymentStatus("error");
        }

        if (url && url.indexOf('cancel') > -1) {
            // console.log("cancel");
            paymentStore.iframeResult = "cancel";
            paymentStore.sendPaymentStatus("cancel");
        }
    } catch (e) {
        console.log("can't get iframe url");
        paymentStore.iframeResult = "creditguard";
    }
}

export default iframeOnLoad;