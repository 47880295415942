import {inject, observer} from "mobx-react";
import React from "react";

@inject ( "seatsStore") @observer
class MainHeader extends React.Component {
    render() {
        const s = this.props.seatsStore;
        const direction = (s.currentFlight.flightIndex === 0) ? "הלוך" : "חזור";

        return (
            <div className={"dlg-title"}>
                <div className={"icon-seats-page"}/> בחר מושב לטיסה {direction}
            </div>

        )
    }
}

export {MainHeader};