import access from 'safe-access';
import {observable, computed, action} from "mobx";
import {history} from 'app/services/custom.router'
import {
    clearActiveSession,
    getActiveAccessToken,
    getActivePNR, getAgentAccessToken, setAccessToken,
    setAgentAccessToken
} from "app/services/session.service";
const maxSMSTimeSeconds = 60;

const AuthStoreErrors = {
    NONE : "NONE",
    "AUTHORIZATION_ERROR" : "AUTHORIZATION_ERROR",
    "SMS_VERIFICATION_ERROR" : "SMS_VERIFICATION_ERROR",
    "OTHER" : "OTHER"
};

class AuthStore {
    @observable phone = "";
    @observable pnr = "";
    @observable smsCode = "";

    @observable username = "";
    @observable password = "";
/*
    @observable phone = "0542403586";
    @observable pnr = "50008521";
    @observable smsCode = "99999";
*/
    @observable smsSent = false;

    @observable dataError = false;

    @observable token = "";
    @observable pnrSource = "";
    @observable encryptedPnr = "";


    @observable isLoading = false;
    @observable isError = false;

    @observable isAgentAuthInProcess = false;
    @observable isAgentAuthError = false;
    @observable agentErrorMessage = "";

    @observable searchPnr = "";
    @observable searchPhone = "";
    @observable searchLastName = "";
    @observable searchInProgress = false;
    @observable searchError = "";

    @observable errorStatus = AuthStoreErrors.NONE;
    @observable errorMessage = "";
    @observable authTime = null;
    @observable remainingTime = null;
    @observable showAgentLoginLink = false;

    constructor(root, transport) {
        this.root = root;
        this.transport = transport;

        this.timer1 = setInterval(()=> {
            if (!this.authTime) {
                return;
            }

            if (window.sessionStorage.getItem("pnr")) {
                clearInterval(this.timer1);
            }

            const diff = maxSMSTimeSeconds - Math.round((new Date() - this.authTime) / 1000);

            this.remainingTime = (diff > 0) ? diff : 0;
        },1000);

        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            // dev code

            this.pnr = "50054418";
            this.phone = "0542290559";

            this.searchPnr = "50053760";
            this.searchPhone = "0547855369";
            this.searchLastName = "SHALVI";


/*
            this.phone = "0542403586";
            this.pnr = "50008521";
            this.smsCode = "99999"

           this.phone = "0543000615";
           this.pnr = "50010809";
*/


//             this.phone = "0543000615";
  //           this.pnr = "50010802";


           // this.pnr = "50010864";
           // this.phone = "0508550503";


        } else {
            // production code
        }

        const token =getActiveAccessToken();
        const pnr =  getActivePNR();
        // console.log("load token", token);
        if (token && pnr) {
            console.log("taking token & pnr from session storage");
        }
        /*
        if (token) {
            this.token = token;

            window.sessionStorage.setItem("access_token",token);
        }
         */
    }

    @action setSearchPnr(value) {
        this.searchPnr = value;
    }

    @action setSearchPhone(value) {
        this.searchPhone = value;
    }

    @action setSearchLastName(value) {
        this.searchLastName = value;
    }

    @action performSearch() {
        if (this.searchInProgress) {
            return;
        }

        if (!this.searchPnr || !this.searchPhone || !this.searchLastName) {
            this.searchError = "אנא מלאו פרטים";
            return;
        }

        const data = {
            "pnr" : this.searchPnr,
            "phone" : this.searchPhone,
            "lastName" : this.searchLastName
        };

        this.searchInProgress = true;
        this.searchError = "";
        this.showAgentLoginLink = false;

        this.transport.performSearch(data)
            .then((response) => {
                // console.log("search complete", response.data);

                const token = access(response, 'data.data.token');
                const status = access(response, 'data.status');

                if (status === 'OK' && token) {
                    setAccessToken(token);

                    this.token = token;
                    this.transport.setAuthKey(token);
                    window.sessionStorage.setItem("pnr", this.searchPnr);
                    history.push('/dashboard');

                }
            })
            .catch((e) => {
                console.error(e);

                // const status = access(e, 'response.status');
                let message = access(e,'response.data.message');

                if (message) {
                    this.searchError = "אנא התחברו למערכת מחדש";
                    this.showAgentLoginLink = true;
                    return;
                }

                /*
                if (status === 401) {
                    this.searchError = "אנא התחברו למערכת מחדש";
                    this.showAgentLoginLink = true;
                    return;
                }

                 */

                // console.log("error response", access(e,'response.data'));
                const msgError = access(e, 'response.data.error_description') || "אנא בדקו את הפרטים";
                this.searchError = msgError;
            })
            .finally(() => {
                this.searchInProgress = false;
            })
    }

    @action setUsername (newUsername) {
        this.username = newUsername;
    }

    @action setPassword (newPassword) {
        this.password = newPassword;
    }

    @action performAgentAuth() {

        this.isAgentAuthInProcess = true;
        this.agentErrorMessage = "";

        const params = new URLSearchParams();
        params.append("grant_type", "password");
        params.append("username" , this.username);
        params.append("password", this.password);

        this.transport.performAgentAuth(params).then((response) => {
            // console.log("response", response.data);
            const accessToken = access(response,'data.access_token');

            if (accessToken) {
                setAgentAccessToken(accessToken);
                history.push('/search');
            } else {
                this.agentErrorMessage = "General error";
            }


        }).catch((e) => {
            // console.error(e);
            // console.log("response",e.response.data);
            const msgError = access(e, 'response.data.error_description') || "General error";
            this.agentErrorMessage = msgError;

            //const errMsg=  access()
        }).finally(() => {
            this.isAgentAuthInProcess = false;

        })
    }

    @action performAutoLogin(encryptedPnr) {
        this.isLoading = true;
        clearActiveSession();

        this.transport.PerformAutoAuth(encryptedPnr)
            .then(response => {

            //         this.errorStatus = AuthStoreErrors.NONE;
            //         this.token = response.data.data.token;
            //         this.pnrSource = access(response.data,'.data.pnrSource');
            //         this.transport.setAuthKey(response.data.data);
                    window.sessionStorage.setItem("access_token",response.data.data.token);
                    window.sessionStorage.setItem("pnr", response.data.data.pnr);
                 ///   window.sessionStorage.setItem("pnr-source", response.data.data.pnrSource);
                    window.sessionStorage.removeItem("agent_access_token");
                    history.push('/dashboard');
        }).catch(e => {
            console.log(e);
            this.encryptedPnr = "";
            window.sessionStorage.removeItem("encryptedPnr");
            //history.push('/login');
            window.location.href = "/login";
        }).finally(()=> {
            this.isLoading = false;
        })
    }

    @action performInitialAuth() {
        this.isLoading = true;
        this.token = "";


        
        this.transport.PerformAuthorization({
            pnr : this.pnr,
            phone : this.normalizedPhone
        })
            .then((response) => {
                // console.log("end",response);

                const status = access(response,'data.status');

                this.isLoading = false;
                //set pnr from the response for the AEROSRS users
                if (response || access(response.data, ".data.pnrSource") !== "ISO") {
                    this.pnr = access(response.data, ".data.pnr");
                }
                if (status === 'ERROR') {
                    this.isLoading = false;
                    this.errorStatus = AuthStoreErrors.OTHER;
                    const errors = access(response, 'data.error');
                    const errorsLength = errors && errors.length > 0;
                    this.errorMessage = (errorsLength && access(errors,'[0].message')) || "שגיאת שרת";
                } else {

                    try {
                        // const pnrSource = access(response,'data.data.pnrSource');
                        // const redirectData = response.data;
                        // redirectData.data.phone = this.phone;
                        // const rdString = btoa(JSON.stringify(redirectData));
    
                        // redirect to BLUEBIRD
                        // if (pnrSource === "ISO" && process.env.REACT_APP_THEME ==='bluebird') {
                        //     window.location.href = process.env.REACT_APP_ISO_URL + "/rd-login/"+rdString;
                        // }
                        //
                        // if (pnrSource === "AEROCRS" && process.env.REACT_APP_THEME !== 'bluebird') {
                        //     window.location.href = process.env.REACT_APP_AEROCRS_URL + "/rd-login/"+rdString;
                        // }
                    }
                    catch(e) {
                        console.error(e);
                    }
                    
                    this.smsSent = true;
                    this.errorStatus = AuthStoreErrors.NONE;
                    this.authTime = new Date();
                }


                //this.token = response.data;

                // this.performAuthStep2();


                /*
                this.transport.setAuthKey(response.data);
                window.sessionStorage.setItem("access_token",response.data);
                this.isLoading = false;
                this.isError = false;
                */


            })
            .catch((e) => {
                // console.log("e!",e);
                this.token = "";
                this.isLoading = false;
                this.dataError = true;
                this.errorStatus = AuthStoreErrors.AUTHORIZATION_ERROR;
            });
    }

    @action performSmsVerification() {

        if (this.isLoading) {
            console.log("already in process...");
            return;
        }

        this.isLoading = true;

        this.transport.PerformAuthorizationStep2({
            pnr : this.pnr,
            smsCode : this.smsCode
        })
            .then((response) => {
                // console.log("step2 end",response);

                const status = access(response,'data.status');

                this.isLoading = false;

                if (status === 'ERROR') {
                    this.isLoading = false;
                    this.errorStatus = AuthStoreErrors.SMS_VERIFICATION_ERROR;

                } else {
                    this.errorStatus = AuthStoreErrors.NONE;
                    this.token = response.data.data.token;
                    this.pnrSource = access(response.data,'.data.pnrSource');
                    this.transport.setAuthKey(response.data.data);
                    window.sessionStorage.setItem("access_token",response.data.data.token);
                    window.sessionStorage.setItem("pnr", this.pnr);
                //    window.sessionStorage.setItem("pnr-source", this.pnrSource);
                    window.sessionStorage.removeItem("agent_access_token");
                    history.push('/dashboard');
                }


            })
            .catch((e) => {
                // console.log("e!",e);
                this.token = "";
                this.isLoading = false;
                this.dataError = true;
                if( e.response ){
                    //console.log(e.response.data); // => the response payload
                    const errorParam = access(e, 'response.data.error[0].parameter');
                    // console.log("errorParam",errorParam);
                    if (errorParam === 'SmsCode') {
                        this.errorStatus = AuthStoreErrors.SMS_VERIFICATION_ERROR;
                    } else {
                        this.errorStatus = AuthStoreErrors.OTHER;
                        this.errorMessage = "שגיאת שרת";
                    }
                } else {
                    this.errorStatus = AuthStoreErrors.OTHER;
                    this.errorMessage = "שגיאת שרת";

                }

            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    @action cancelSmsVerification() {
        this.smsCode = "";
        this.smsSent = false;
    }

    @action setPhone(phone) {
        this.phone = phone;
        this.dataError = false;
    }

    @action setPNR(pnr) {
        this.pnr = (pnr && pnr.length>8) ? pnr.substr(0,8) : pnr;
        this.dataError = false;
    }

    @action setSmsCode(code) {
        let fixedCode;
        if (code && code.length>5) {
            fixedCode = code.substr(0,5);
        } else {
            fixedCode = code;
        }
        this.smsCode = fixedCode;

        this.dataError = false;
    }

    @action logout() {
        console.log("logout");
        this.smsSent = false;

        clearActiveSession();

        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            this.smsCode = "";
            this.pnr = "";
            this.phone = "";
        } else {
            this.smsCode = "";
            this.pnr = "";
            this.phone = "";
        }

        this.root.paymentStore.reset();
        this.root.seatsStore.reset();
        this.root.extraServicesStore.reset();
        this.root.baggageStore.reset();
        this.root.pnrStore.reset();
        this.root.dashboardUIStore.closeAllPopups();
        this.root.checkInPopup.closePopup();
    }

    @computed get isAuthenticated() {
        return !!this.token;
    }

    @computed get accountHolder() {
        const d = this.root.pnrStore.data;

        if (!d) {
            return "";
        }

        return access(d,'data.passengersInfo[0].fullName');
    }

    @computed get normalizedPhone() {
        return this.phone.replace(/\D/g,"");
    }

    @computed get remainingTimeText() {
        const min = Math.floor(this.remainingTime / 60);
        const sec = this.remainingTime - min * 60;

        return (min.toString().length >= 2 ? min : "0" + min) + ":" + (sec.toString().length >= 2 ? sec : "0" + sec);
    }

    @computed get isAgentAuthorized() {
        return !!getAgentAccessToken();
    }
}

export {AuthStore, AuthStoreErrors}