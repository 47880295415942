import React from 'react';
import { ReactComponent as BlueBirdLogo } from "app/desktop/images/BlueBird-logo.svg";
import { ReactComponent as VIPIcon } from "app/desktop/images/icons/vip-icon.svg";
import 'app/desktop/ui/components/bluebird-vip-logo/bluebird.vip.logo.scss';

const BlueBirdVipLogo = () => (
  <div className={"cs-bluebird-vip-logo"}>
    <VIPIcon /><BlueBirdLogo />
  </div>
);

export default BlueBirdVipLogo;