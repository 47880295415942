import React from 'react';
import {observer} from "mobx-react";
import 'app/desktop/ui/components/deal-status/deal.status.scss';


@observer
class DealStatus extends React.Component {
    render() {

        if (!this.props.warn) {
            return (
                <div className={"flight-status"}>
                    <div className={"icon-confirmation"}/>
                    {this.props.text}</div>
            )
        } else
                return (
                    <div className={"flight-status warn"}>
                        <div className={"icon-in-progress"}/>
                        {this.props.text}</div>
                )
    }
}

DealStatus.defaultProps = {
    warn : false,
    text : ""
}

export {DealStatus}