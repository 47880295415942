import {action, autorun, observable, computed} from "mobx";
import {
    isBirthdayValidAndAgeInRange,
    isEmailValid,
    isPassportValid,
    isPhoneNumberValid,
    isValidPassportExpiry, isBlankString
} from "../services/validator";
import access from "safe-access";
import moment from "moment/moment";
import PassengerType from "../enums/passenger.types";


const findPassengerIndex = (passengers, passenger, compareFn) => {
    return passengers && passengers.findIndex(item => compareFn(item, passenger));
}

export const passengerFindIndex = (passengers, passenger) => {
    return findPassengerIndex(passengers, passenger, (item, passenger) => item.number === passenger.number);
}

export const passengerFindId = (passengersInfo, passenger) => {
    return findPassengerIndex(passengersInfo, passenger, (item, passenger) => parseInt(item.id) === passenger.number);
}

const setPassengerIndex = (passengerArray, passengerObj) => {
    const passInd = passengerFindIndex(passengerArray, passengerObj);
    if (passInd === -1) {
        console.log("The passenger is not found");
        return null;
    }
    return passInd;
}


const FieldTypes = {
    BIRTHDAY: "BIRTHDAY",
    PASSPORT_NUMBER: "PASSPORT_NUMBER",
    PASSPORT_EXPIRATION: "PASSPORT_EXPIRATION",
    MOBILE_PHONE: "MOBILE_PHONE",
    EMAIL: "EMAIL",
    NATIONALITY: "NATIONALITY",
    COUNTRY: "COUNTRY",
    GENDER: "GENDER"
}

const fieldKeysArray = ['birthDay', 'gender', 'nationality', 'country', 'passport', 'passportExpirationDate'];
const ignoreFieldsForInfant = [FieldTypes.MOBILE_PHONE, FieldTypes.EMAIL];

class Field {
    @observable value = "";
    @observable showError = false;
    @observable errMsg = "";

    @observable minAge = null;
    @observable maxAge = null;

    fieldType;

    constructor(root, fieldType) {
        this.root = root;
        this.fieldType = fieldType;
    }

    @action setValue(value) {
        this.value = value;
        this.showError = false;
        this.errMsg = '';
    }

    @action validate() {
        if (!this.isValid) {
            this.showError = true;
            this.errMsgs();
            this.root.root.root.alertsStore.showAlert("אחד מהנתונים שהזנת אינו תקין - אנא נסה שנית", "error");
        }
        // else {
        //     this.showError = false;
        //     this.errMsg = '';
        // }
    }

    @action errMsgs() {
        switch (this.fieldType) {
            case FieldTypes.EMAIL:
                this.errMsg = "* האימייל שהוזן אינו תקין";
                break;
            case FieldTypes.BIRTHDAY:
                this.errMsg = "* תאריך הלידה שהוזן אינו תקין";
                break;
            case FieldTypes.PASSPORT_NUMBER:
                this.errMsg = "* מספר הדרכון שהוזן אינו תקין";
                break;
            case FieldTypes.PASSPORT_EXPIRATION:
                this.errMsg = "* התאריך שהוזן אינו תקין. אנא תקן את התאריך או פנה לסוכן הנסיעות שלך (אם אין ברשותך דרכון בתוקף)";
                break;
            case FieldTypes.MOBILE_PHONE:
                this.errMsg = "* מספר הטלפון שהוזן אינו תקין";
                break;
            case FieldTypes.NATIONALITY:
                this.errMsg = "* שדה זה חובה למלא";
                break;
            case FieldTypes.COUNTRY:
                this.errMsg = "* שדה זה חובה למלא";
                break;
            case FieldTypes.GENDER:
                this.errMsg = "* חייבים לבחור מגדר";
                break;
            default:
                this.errMsg = "* שדה זה חובה למלא";
        }
    }

    @computed get isValid() {
        switch (this.fieldType) {
            case FieldTypes.EMAIL:
                return isEmailValid(this.value);
            case FieldTypes.BIRTHDAY:
                return isBirthdayValidAndAgeInRange(this.value, this.minAge, this.maxAge);
            case FieldTypes.PASSPORT_NUMBER:
                return isPassportValid(this.value);
            case FieldTypes.PASSPORT_EXPIRATION:
                return isValidPassportExpiry(this.value);
            case FieldTypes.MOBILE_PHONE:
                return isPhoneNumberValid(this.value);
            case FieldTypes.NATIONALITY:
                return this.value.hasOwnProperty("name");
            case FieldTypes.COUNTRY:
                return this.value.hasOwnProperty("name");
            case FieldTypes.GENDER:
                return this.value === "Male" || this.value === "Female";
            default:
                return this.value.trim() !== "";
        }
    }

    @computed get isEmptyValue() {
        return (isBlankString(this.value));
    }

}

class Passenger {
    @observable number;
    @observable gender = new Field(this, FieldTypes.GENDER);
    @observable birthDay = new Field(this, FieldTypes.BIRTHDAY);
    @observable nationality = new Field(this, FieldTypes.NATIONALITY);
    @observable country = new Field(this, FieldTypes.COUNTRY);
    @observable passport = new Field(this, FieldTypes.PASSPORT_NUMBER);
    @observable passportExpirationDate = new Field(this, FieldTypes.PASSPORT_EXPIRATION);
    @observable mobilePhone = new Field(this, FieldTypes.MOBILE_PHONE);
    @observable email = new Field(this, FieldTypes.EMAIL);
    @observable allowReceivingAds = false;
    fullName;

    constructor(number, p, root) {
        this.number = number;
        this.fullName = p.fullName;
        this.title = p.title
        this.id = p.id;
        this.passengerType = p.passengerType;
        this.root = root;

        this.country.setValue({displayOrder: 1, nationalityCode: 'IL', name: 'ישראל'});

        switch (this.passengerType) {
            case PassengerType.ADULT:
                this.birthDay.minAge = 14;
                this.birthDay.maxAge = 120;
                break;

            case PassengerType.CHILD:
                this.birthDay.minAge = 2;
                this.birthDay.maxAge = 18;
                break;

            case PassengerType.INFANT:
                this.birthDay.minAge = 0;
                this.birthDay.maxAge = 2;
                break;

            default:
                // don't set
                console.log("no need to set min / max dates")
        }

        // set default nationality
        this.nationality.setValue({
            "displayOrder": 1,
            "nationalityCode": "IL",
            "name": "ישראל"
        });

        autorun(()=> {
            //set initial value
            this.root.setInitialFieldValue("phone", "mobilePhone", this);
            this.root.setInitialFieldValue("email", "email", this);
            this.root.setInitialFieldValue("birthDate", "birthDay", this);
            this.root.setInitialFieldValue("gender", "gender", this);
        });

    }

    @action validatePassenger() {
        fieldKeysArray.forEach((f) => {
            if (!(this.passengerType === PassengerType.INFANT && ignoreFieldsForInfant.includes(this[f].fieldType))){
                this[f].validate();
            }
        });

        // both 'mobilePhone' and 'email' are mandatory for first passenger
        if (this.number === 1) {
            this.mobilePhone.validate();
            this.email.validate();
        } else {
            if (!this.mobilePhone.isEmptyValue) {
                this.mobilePhone.validate();
            } else {
                this.mobilePhone.showError = false;
            }

            if (!this.email.isEmptyValue) {
                this.email.validate();
            } else {
                this.email.showError = false;
            }

        }
    }

    @computed get isValid() {
        let isValid = true;
        let firstInvalidKey = null;

        fieldKeysArray.forEach((f) => {
            if (!(this.passengerType === PassengerType.INFANT && ignoreFieldsForInfant.includes(this[f].fieldType))) {
                if (!this[f].isValid) {
                    // console.log("field ", this[f], "is invalid");
                    isValid = false;

                    if (firstInvalidKey === null) {
                        firstInvalidKey = f;
                    }
                }
            }
        });

        if (this.number === 1) {
            if (!this.mobilePhone.isValid) {
                isValid = false;
            }

            if (!this.email.isValid) {
                isValid = false;
            }
        } else {
            const isPhoneValid = (this.mobilePhone.isEmptyValue || this.mobilePhone.isValid);
            const isEmailValid = (this.email.isEmptyValue || this.email.isValid);

            if (!isPhoneValid || !isEmailValid) {
                isValid = false;
            }
        }

        return isValid;
    }
}

class PassengerDetailsStore {

    @observable passengers = [];

    constructor(root) {
        this.root = root;

        autorun(() => {
            const ps = this.root.passengersStore;

            // create passengers only on first PNR loading
            // TODO: check how will be affected by agent usage
            if (ps && this.passengers.length === 0) {
                ps.passengers.map((p, ind) => {
                    // console.log("create passenger", p);

                    this.passengers.push(new Passenger(ind + 1, p, this))
                });
            }
        })
    }

    @action setPassengerAttribute(attributeType, option, passengerObj) {
        const passInd = setPassengerIndex(this.passengers, passengerObj);
        if (passInd !== null) {
            this.passengers[passInd][attributeType] = option;
        }
    }

    @action setAttributeField(attributeType, value, passengerObj) {
        const passInd = setPassengerIndex(this.passengers, passengerObj);
        if (passInd !== null) {
            const passenger = this.passengers[passInd][attributeType];
            passenger.setValue(value);
        }
    }

    @action validateField(attributeType, passengerObj) {
        const passInd = setPassengerIndex(this.passengers, passengerObj);
        if (passInd !== null) {
            const passenger = this.passengers[passInd][attributeType];
            if (passenger.value.length !== 0) {
                passenger.validate();
            }
        }
    }

    @action setInitialFieldValue(pnrAttributeName, passengerDetailsAttributeName, curPassenger) {

        const passInfo = this.root.pnrStore.data.data.passengersInfo;
        const passInd = passengerFindId(passInfo, curPassenger);
        const isAdult = access(passInfo[passInd], '.passengerType') === 1;

        if (passInd !== -1) {

            if (pnrAttributeName === "birthDate" && isAdult) {
                return;
            }
            const valueFromPnr = passInfo[passInd][pnrAttributeName];
            const passenger = this.passengers[passInd][passengerDetailsAttributeName];

            if (passenger.value) {
                //don't set initial value if the value changed
                return;
            }

            if (pnrAttributeName === "gender") {
                if (passenger.value || passenger.value === "Undetermined" ) {
                    return;
                }
            }

            if (valueFromPnr) {
                passenger.setValue(valueFromPnr);
                // passenger.validate();
            }
        } else {
            console.log("Id not found");
        }
    }

    @action updateCheckbox = (attributeType, passengerObj) => {
        const option = !passengerObj.allowReceivingAds;
        this.setPassengerAttribute(attributeType, option, passengerObj);
    }

    @computed get createDataForRequest() {
        return this.passengers.map(passenger => ({
            "PassengerSequenceID": passenger.number.toString(),
            "PassportNumber": passenger.passport.value,
            "EmailAddress": passenger.email.value,
            "PhoneNumber": passenger.mobilePhone.value,
            "PassportExpiryString": passenger.passportExpirationDate.value && moment(passenger.passportExpirationDate.value, 'DD/MM/YYYY').format("YYYY-MM-DD"),
            "PassportIssueCountryCode": passenger.country?.value.nationalityCode,
            "Nationality": passenger.nationality?.value.nationalityCode,
            "Gender": passenger.gender?.value,
            "BirthdateString": passenger.birthDay.value && moment(passenger.birthDay.value, 'DD/MM/YYYY').format("YYYY-MM-DD"),
            "AllowReceivingAds": passenger.allowReceivingAds
        }));
    }

}

export default PassengerDetailsStore;