import {inject, observer} from "mobx-react";
import React from "react";

@inject('isWebCheckIn') @observer
class Header extends React.Component {
    render() {
        const {isWebCheckIn} = this.props;
        const title = !isWebCheckIn ? (
            <>
                <div className={"icon-payment"}/>
                <span className={"main"}>פרטי תשלום</span>
            </>
        ) : <div className={"main-checkin"}>פרטי המשלם</div>
        return (
            <div className={"cs-deal-title"}>
                {title}
            </div>
        );
    }
}

export {Header};