import React from 'react'
import 'app/desktop/ui/components/buttons/cancel-button/cancel.button.scss'

class CancelButton extends React.Component {
    render() {
        return (
            <div className={"btn btn-cancel"} onClick={this.props.onClick}>
                {this.props.children}
            </div>
        )
    }
}

CancelButton.defaultProps = {
    onClick : () => {}
}

export {CancelButton}