import React from 'react'
import 'app/desktop/ui/components/dialogs/dlg-seat-selection/components/flight-info/flight.info.scss'

const FlightInfo = (props) => {
        return (
            <div className={"flight-info"}>
                <div className={props.icon}/>
                <div className={"dst"}>מ{props.departureCity}</div>
                <div className={"icon-arrow-left"}/>
                <div className={"dst"}>ל{props.arrivalCity}</div>
            </div>
        )
}

export {FlightInfo}