import React from 'react';
import 'app/desktop/ui/pages/page-maintain/page.maintain.scss';
import {inject, observer} from "mobx-react";

const Loading = () => {
    return (
            <div className={"gear"}/>
    )
}

@inject("authStore", "pnrStore") @observer
class MaintainPage extends React.Component {
    render() {
        return (
            <div className={"login-page"}>
                <div className={"cs-login-form"}>
                    <div className={"header-image"}/>
                    <div className={"form-body"}>
                        <div className={"form-header"}>
                            <h1>נחזור בקרוב!</h1>
                            <h2>מצטערים על אי הנוחות.</h2>
                            <Loading/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export {MaintainPage}