import {inject, observer} from "mobx-react";
import React from "react";
import {truncatedText} from "../../services/validator";
import ServiceTypes from "app/enums/service.types";

let BackpackLogo, BigTrolleyLogo, TrolleyLogo;
if (process.env.REACT_APP_THEME === 'bluebird') {
    BackpackLogo = require('app/desktop/images/bluebird/backpack.svg');
    TrolleyLogo = require('app/desktop/images/bluebird/trolley.svg');
    BigTrolleyLogo = require('app/desktop/images/bluebird/big-trolley.svg');
} else {
    BackpackLogo = require('app/desktop/images/backpack.svg');
    TrolleyLogo = require('app/desktop/images/trolley.svg');
    BigTrolleyLogo = require('app/desktop/images/big-trolley.svg');
}

@inject("pnrStore") @observer
class BaggageContentInner extends React.Component {
    render() {
        const { p, index } = this.props;
        const services = this.props.services || [];
        const showWarning = this.props.pnrStore.showBaggageWarning;
        return (
            <div className={"bg-passenger-wrapper"} key={"p" + index}>
                <div className={"row name-index-wrap"}>
                    <div className={"col c-num"}>
                        <div className={"p-num num"}>{index + 1}</div>
                    </div>
                    <div className={"bg-name-wrap"}>
                        <div title={p.fullName}>{truncatedText(p.fullName, 25)}</div>
                    </div>
                </div>
                <div className="bg-container">
                    <div className="bg-wrap">
                    <div className={"bg-col"}>
                        <div className={"bg-row"}>
                            <div className={"icon"}>
                                <div className={"wrap"}>
                                    <img src={BackpackLogo} className={"icon-backpack"}/>
                                    <div className={"icon-confirmation green-mark"}/>
                                </div>
                            </div>
                            <div className={"bg-info"}>
                                <div className={"title"}>כבודה כלולה</div>
                                <div className={"text"}>פריט אישי</div>
                            </div>
                        </div>
                    </div>
                    {this.props.hasTrolley ?
                        <div className={"bg-col"}>
                            <div className={"bg-row"}>
                                <div className={"icon"}>
                                    <div className={"wrap"}>
                                        <img src={TrolleyLogo} className={"icon-big-trolley"}/>
                                        {showWarning ? "" : <div className={"icon-confirmation green-mark"}/>}
                                    </div>
                                </div>
                                <div className={"bg-info"}>
                                    <div className={"title"}>כבודה כלולה</div>
                                    <div className={"text"}>טרולי</div>
                                </div>
                            </div>
                        </div> : ""
                    }

                    {services.map((s, ind) => {
                        return (
                            <div className={"bg-col"} key={"col"+ind}>
                                <div className={"bg-row"}>
                                    <div className={"icon"}>
                                        <div className={"wrap"}>
                                            {(s.serviceType === ServiceTypes.FirstBaggage) ?
                                                <img src={BigTrolleyLogo} className="icon-big-trolley"/> : ""
                                            }

                                            {(s.serviceType === ServiceTypes.CarryOnBag) ?
                                                <img src={TrolleyLogo} className="icon-big-trolley"/> : ""
                                            }
                                            {(s.isSelected ? <div className={"icon-confirmation green-mark"}/> : "")}

                                        </div>
                                    </div>
                                    <div className={"bg-info"}>
                                        <div className={"title"}>{s.serviceNameHeb}</div>
                                        <div className={"text"}>{s.text}</div>
                                        <input type={"checkbox"} checked={s.isSelected} onChange={() => {
                                        s.toggleService()
                                    }}/>
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                    {this.props.hasBaggage ?
                        <div className={"bg-col"}>
                            <div className={"bg-row"}>
                                <div className={"icon"}>
                                    <div className={"wrap"}>
                                        <img src={BigTrolleyLogo} className={"icon-big-trolley"}/>
                                        <div className={"icon-confirmation green-mark"}/>
                                    </div>
                                </div>
                                <div className={"bg-info"}>
                                    <div className={"title"}>כבודה כלולה</div>
                                    <div className={"text"}>{this.props.hasBaggage}</div>
                                </div>
                            </div>
                        </div> : ""
                    }                    
                </div>
                </div>
            </div>
        )
    }
}

export {BaggageContentInner}