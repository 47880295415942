import React from 'react'
import {PaymentPageWebCheck} from "app/desktop/ui/pages/dashboard/components/payment/payment.page";

class PaymentScreen extends React.Component {
    render() {
        return (
            <div>
                <PaymentPageWebCheck/>
            </div>
        )
    }
}

export default PaymentScreen;