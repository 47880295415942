import {action, computed, observable} from "mobx";
import {getActivePNR} from "app/services/session.service";
import access from 'safe-access';
import {history} from "../services/custom.router";
import ScreenTypes from "app/desktop/checkin/screen.types";

const sortFlights = (a, b) => {
    if (a.flightIndex > b.flightIndex) {
        return 1;
    } else if (a.flightIndex < b.flightIndex) {
        return -1
    } else {
        return 0;
    }
}


const createPlaneSeats = (data) => {
    // const d = new Date();
    const rows = {};

    const seats = data.seats || [];
    const restrictedSeats = data.restrictedSeats || [];

    seats.forEach((seat) => {
        const row = parseInt(seat.seatNumber.slice(0, -1), 10);
        const place = seat.seatNumber.substr(-1, 1);
        const price = access(data, 'prices.' + seat.category);
        //  console.log("createPlaneSeats", row, place, price);
        if (!rows[row]) {
            rows[row] = {
                "row": row,
                "seats": {}
            };
        }

        const restricted = restrictedSeats.find((rSeat) => {
            return rSeat === seat.seatNumber;
        });

        rows[row]['seats'][place] = new UISeat(seat.seatNumber,
            price,
            seat.available,
            seat.category,
            row,
            place,
            !!restricted,
            data.currency.symbol);
    });

    // normalize data
    const normalizedRows = [];

    // convert to array
    for (const row in rows) {
        normalizedRows.push(rows[row]);
    }

    // sort by row number
    normalizedRows.sort((a, b) => {
        return (a.row < b.row) ? -1 : 1;
    });

    // sort places
    /*
    normalizedRows.forEach((row) => {
        row.seats.sort((a,b) => {
            return (a.place < b.place) ? -1 : 1;
        })
    })
     */

    // console.log("rows", normalizedRows);
    // console.log("create time", new Date() - d)

    return normalizedRows;
}

const findSeat = (planeSeats, number) => {
    for (const planeSeat of planeSeats) {
        const foundSeat = Object.values(planeSeat.seats).find(seat => seat.number === number);
        if (foundSeat) {
            return foundSeat;
        }
    }
    return null;
}

class UISeat {
    @observable selected = false;

    constructor(number, price, available, category, row, place, restricted, currency) {
        this.number = number;
        this.price = price;
        this.available = available;
        this.category = category;
        this.row = row;
        this.place = place;
        this.restricted = restricted
        this.currency = currency;
    }

    @action setSelected(selected) {
        this.selected = selected;
    }
}

class SeatPassenger {
    @observable fullName = "";
    @observable seat = null;

    constructor(fullName) {
        this.fullName = fullName;
    }

    @action clearSeat() {
        if (this.seat) {
            this.seat.setSelected(false);
        }
        this.seat = null;
    }

    @computed get price() {
        if (this.seat) {
            return this.seat.price;
        } else {
            return 0;
        }
    }
}

class SeatsFlight {
    @observable data = null;
    @observable passengers = [];
    @observable activeIndex = 0;
    @observable planeSeats = [];
    @observable flightIndex = 0;

    constructor(root, data, flightIndex) {
        this.root = root;
        this.data = data;
        this.flightIndex = flightIndex;

        const passengersInfo = access(root.pnrStore.data, 'data.passengersInfo');

        this.passengers.replace(passengersInfo.map((p) => {
            return new SeatPassenger(p.fullName);
        }));

        this.planeSeats.replace(createPlaneSeats(data));
    }

    @action setActive(index) {
        this.activeIndex = index;
    }

    @action setNextActive() {
        if (this.activeIndex >= this.root.passengersStore.passengersExcludeInfants.length - 1) {
            return;
        }

        const nextIndex = this.activeIndex + 1;
        const isLastElement = nextIndex === this.root.passengersStore.passengersExcludeInfants.length - 1;

        let seatIsPaid;
        if (this.flightIndex === 0) {
            seatIsPaid = index => this.root.pnrStore.seat1IsPaid(index);
        } else if (this.flightIndex === 1) {
            seatIsPaid = index => this.root.pnrStore.seat2IsPaid(index);
        } else {
            console.log('flights not found');
            return;
        }

        if (!isLastElement || !seatIsPaid(nextIndex)) {
            this.setActive(nextIndex);

            // If the current seat is paid, skip to next one
            if (seatIsPaid(this.activeIndex)) {
                this.setNextActive();
            }
        }
    }

    @action resetSelectedSeats() {
        this.passengers.forEach((p) => {
            p.clearSeat();
        })
    }

    @computed get seatPriceA() {
        return access(this.data, "prices.1") || 0;
    }

    @computed get seatPriceB() {
        return access(this.data, "prices.2") || 0;
    }

    @computed get seatPriceC() {
        return access(this.data, "prices.3") || 0;
    }

    @computed get currencySymbol() {
        return access(this.data, 'currency.symbol') || "";
    }

    @computed get priceForFlight() {
        let price = 0;
        this.passengers.forEach((p) => {
            price += p.price;
        })

        return price;
    }
}

class SeatsStore {
    @observable activeFlight = 0;
    @observable selectedRestrictedSeat = null;
    @observable isBooking = false;
    @observable finishedBookingSuccess = false;
    @observable flights = [];
    @observable fetchSeatsType = null;
    @observable showErrorMessageStatus = false;
    @observable errText = "";
    @observable seatsData = null;
    @observable paidFlights = [];
    @observable currentPassengerType = null;

    constructor(root, transport) {
        this.root = root;
        this.transport = transport;
    }

    @action showErrorMessage(status, text, constantMsg = false) {
        this.showErrorMessageStatus = status;
        this.errText = text;
        if (constantMsg) {
            return;
        }
        setTimeout(() => {
            this.showErrorMessageStatus = false;
            this.errText = "";
        }, 3000);
    }

    @action deleteErrorMsg() {
        this.showErrorMessageStatus = false;
        this.errText = "";
    }

    @action selectSeatsProcess(status) {
        this.isBooking = status;
    }

    @action setActiveFlight(num) {
        this.activeFlight = num;
    }

    @action setSelectedRestrictedSeat(seat) {
        this.selectedRestrictedSeat = seat;
    }

    @action setFlight1ActiveIndex(ind) {
        if (this.flight1) {
            this.flight1.setActive(ind);
        }
    }


    @action setFlight2ActiveIndex(ind) {
        if (this.flight2) {
            this.flight2.setActive(ind);
        }
    }

    @computed get passengerIsAdult() {
        const flight = access(this.flight1, '.activeIndex');
        const passengers = this.root.passengersStore.passengersExcludeInfants;
        return typeof flight === "number" && access(passengers[flight], '.passengerType') === 1;
    }

    @action activateRestrictedSeat() {
        const flight = this.flight1;
        const passengers = this.root.passengersStore.passengersExcludeInfants;

        const seat = passengers[flight?.activeIndex].seats[flight.flightIndex];
        // console.log("active seat", passengers[flight.activeIndex], flight.flightIndex, seat);

        if (seat) {
            seat.setSelected(false);
        }

        if (this.root.isWebCheckIn) {
            //web checkin functionality
            if (this.root.checkInUIStore.randomlyPicked) {
                this.root.checkInUIStore.setRandomlyPicked(false);
                this.root.passengersStore.passengersExcludeInfants.forEach(passenger => passenger.clearSeat(this.root.checkInUIStore.curDirection));
                this.root.seatsStore.setFetchSeatsType("webCheckFetchAfterReplacingRandomSeat");
                this.root.seatsStore.fetchSeats(true);
            }
            //delete all randomly picked seats
        }

        passengers[flight.activeIndex].setSeat(flight.flightIndex, this.selectedRestrictedSeat);
        this.selectedRestrictedSeat.setSelected(true);
        flight.setNextActive();
    }

    @action fetchSeats(deleteSeat = false) {
        //pass deleteSeat in fetchSeats when we need to manually delete some seats from temporary reserved
        // this.selectSeatsProcess(true);
        if (this.fetchSeatsType !== null) {
            this.selectSeatsProcess(true);
        }
        const isFlightsArrNotEmpty = this.root.pnrStore.data.data.flights.length > 0;
        const firstGUID = isFlightsArrNotEmpty && access(this.root.pnrStore, 'data.data.flights[0].guid')
        const secondGUID = isFlightsArrNotEmpty && access(this.root.pnrStore, 'data.data.flights[1].guid');
        //webcheckin functionality
        const {isWebCheckIn} = this.root;
        const checkInDirection = this.root.checkInUIStore.curDirection;
        const firstFlightWebCheck = checkInDirection === 0 && firstGUID;
        const secondFlightWebCheck = checkInDirection === 1 && secondGUID;


        //in webCheckin only current direction guid will be fetched
        const guid1 = isWebCheckIn ? firstFlightWebCheck : firstGUID,
            guid2 = isWebCheckIn ? secondFlightWebCheck : secondGUID;

        this.flights.replace([]);

        const promises = [];

        if (guid1) {
            const promise1 = this.transport.fetchSeatsData(getActivePNR(), guid1)
                .then((response) => {
                    const data = access(response.data, 'data.seats') || [];
                    const newSeatFlight = new SeatsFlight(this.root, response.data.data, 0);
                    if (!data || data.length === 0) {
                        console.log('there is no data found');
                    } else {
                        if (access(response.data.data, '.allPassengersHaveASeat')) {
                            //put flight object to paidFlights observable array If all passengers have seats. Use this obj later in webCheckin to show plane map
                            this.paidFlights = [...this.paidFlights, newSeatFlight];
                            const sortedArray = this.paidFlights.slice().sort(sortFlights);
                            this.paidFlights.replace(sortedArray);
                        } else {
                            this.flights.push(newSeatFlight);
                            const sortedArray = this.flights.slice().sort(sortFlights);
                            this.flights.replace(sortedArray);
                            !deleteSeat && this.temporaryReservedSeats(sortedArray, 0);
                        }
                    }
                });
            promises.push(promise1);
        }

        if (guid2) {
            const promise2 = this.transport.fetchSeatsData(getActivePNR(), guid2)
                .then((response) => {
                    const data = access(response.data, 'data.seats') || [];
                    const newSeatFlight = new SeatsFlight(this.root, response.data.data, 1);
                    if (!data || data.length === 0) {
                        // this.flight2 = null;
                        console.log("second flight seats data not found")
                    } else {
                        if (access(response.data.data, '.allPassengersHaveASeat')) {
                            this.paidFlights = [...this.paidFlights, newSeatFlight];
                            const sortedArray = this.paidFlights.slice().sort(sortFlights);
                            this.paidFlights.replace(sortedArray);
                        } else {
                            this.flights.push(newSeatFlight);
                            const sortedArray = this.flights.slice().sort(sortFlights);
                            this.flights.replace(sortedArray);
                            !deleteSeat && this.temporaryReservedSeats(sortedArray, 1);
                        }
                    }
                });
            promises.push(promise2);
        }

        if (promises.length > 0) {
            Promise.allSettled(promises)
                .then(([response1, response2]) => {
                    switch (this.fetchSeatsType) {
                        case "fetchSeatsAndContinue":
                            this.fetchSeatsAndContinue();
                            break;
                        case "skipAndFetchSeats":
                            this.skipAndFetchSeats();
                            break;
                        case "finalFetchSeats":
                            this.finalFetchSeats();
                            break;
                        case "webCheckFetchSeats":
                            this.webCheckFetchSeats();
                            break;
                        case "webCheckFetchSeatsOnBack":
                            this.webCheckFetchSeatsOnBack();
                            break;
                        case "getRandomSeatsFetch":
                            this.setRandomSeats();
                            break;
                        case "confirmationPageFetch":
                            this.confirmationPageFetch();
                            break;
                        case "webCheckFetchAfterReplacingRandomSeat":
                            this.clearSeatsFetchProcessData();
                            break;
                        default:
                            console.log("No fetch seats type chosen");
                            this.selectSeatsProcess(false);
                    }

                })
                .catch((e) => {
                    console.log("error", e);
                    this.setFetchSeatsType(null);
                    this.selectSeatsProcess(false);
                })
                .finally(() => {
                    // any cleanup or final steps here
                });
        }


    }

    @action setFetchSeatsType(fetchType) {
        this.fetchSeatsType = fetchType;
    }

    @action
    performSeatsBooking(deleteSeat = false) {
        const {root, transport} = this;
        const passengers = root.passengersStore.passengersExcludeInfants;
        const pnr = window.sessionStorage.getItem("pnr") || root.authStore.pnr;
        const {isWebCheckIn} = root;
        const webCheckDirection = isWebCheckIn && root.checkInUIStore.curDirection; // Determine the check-in direction if web check-in is active

        this.selectSeatsProcess(true);

        const bookingPromises = [];

        for (let i = 0; i < 2; i++) {  // 0 for first flight, 1 for second flight
            const isFirstFlight = i === 0;
            // Determine if current iteration is for the first or second flight, and check the necessary conditions to proceed
            const flightCondition = isFirstFlight ?
                (isWebCheckIn ? (webCheckDirection === 0 && (this.selectedSeatsTotalPriceIsZero || this.tempReservedLength())) : (this.flight1SeatsCount > 0 || this.tempReservedLength(0))) :
                (isWebCheckIn ? (webCheckDirection === 1 && (this.selectedSeatsTotalPriceIsZero || this.tempReservedLength())) : (this.flight2SeatsCount > 0 || this.tempReservedLength(1)));

            if (!flightCondition) continue; // Skip to next iteration if conditions are not met for current flight

            const guid = isFirstFlight ? root.pnrStore.flight1Guid : root.pnrStore.flight2Guid;
            const seats = {};

            for (let p of passengers) {
                const seat = isFirstFlight ? p.seat1 : p.seat2; // Get the seat info for the current flight and passenger

                if (!seat && deleteSeat) continue; // Skip to next iteration if seat is not available and deletion is flagged

                if (seat) seats[p.id] = seat.number; // Add seat number to the seats object if seat is available
            }

            const data = {seats};  // Prepare data object for the PerformSeatsBooking call
            const booking = transport.PerformSeatsBooking(pnr, guid, data)
                .then((res) => {
                    console.log(res);
                })
                .catch(e => console.error(e));

            bookingPromises.push(booking); // Collect the booking promise for later handling
        }

        Promise.all(bookingPromises)
            .then((responses) => {
                console.log("All the seats are successfully updated");
                this.seatsBookingPerformed();
            })
            .catch(e => console.log(e))
            .finally(() => this.selectSeatsProcess(false));
    }

    @action seatsBookingPerformed() {
        if (this.root.isWebCheckIn) {
            if (this.fetchSeatsType === "webCheckFetchSeats") {
                this.setFetchSeatsType(null);
                //show final warning popup if picked free seats and no baggage
                //TODO: check the scenario when someone else picked the chosen seats
                if (this.totalPriceByDirection === 0 && this.areSeatsAvailable && this.selectedSeatsTotalPriceIsZero && this.root.baggageStore.baggageTotalPriceByDirection <= 0) {
                    if (this.flightHasOnePassenger ||
                        //check if only there is one adult and seats picked randomly
                        (this.pnrWithAdultAndChild && this.root.checkInUIStore.randomlyPicked )) {
                        // const tempReservedArray = access(sortedArray, `[${index}].data.orderTemporaryReservedSeats`);
                        this.root.dashboardUIStore.ticketCheckIn.openPopup();
                        return;
                    }
                    this.root.dashboardUIStore.addSeatsCheckIn.openPopup();
                    this.root.checkInUIStore.seatsWarningPopup.openPopup();
                    return;
                    //show seats warning popup if added baggage and not picked seats/ picked only free seats
                } else if (this.totalPriceByDirection === 0 && this.areSeatsAvailable && this.root.baggageStore.baggageTotalPriceByDirection > 0) {
                    if (this.flightHasOnePassenger ||
                        //check if only there is one adult and seats picked randomly
                        (this.pnrWithAdultAndChild && this.root.checkInUIStore.randomlyPicked )) {
                        this.root.checkInUIStore.moveToNextScreen("forceNext");
                        return;
                    }
                    this.root.dashboardUIStore.addSeatsCheckIn.openPopup();
                    this.root.checkInUIStore.seatsWarningPopup.openPopup();
                    return;
                }
                //webcheckin functionality
                if (this.root.checkInUIStore.activeScreen === this.root.checkInUIStore.availableScreens[3]) {
                    return;
                }

                // this.root.checkInUIStore.setActiveScreen(ScreenTypes.PAYMENT);
                this.root.checkInUIStore.moveToNextScreen("forceNext");
            }

        }
    }

    @computed get currentFlight() {
        if (this.root.isWebCheckIn) {
            //web checkin functionality
            let curDirection = this.root.checkInUIStore.curDirection;
            let flightInSeatsStore;
            if (this.root.pnrStore.currentFlightAllSeatsPaid) {
                flightInSeatsStore = this.paidFlights?.find(flight => flight.flightIndex === curDirection);
                //will return false if flightInSeatsStore is null or undefined
                return flightInSeatsStore ?? false;
            }
            flightInSeatsStore = this.flights?.find(flight => flight.flightIndex === curDirection);
            return flightInSeatsStore ?? false;
        }
        console.log("currentFlight", this.activeFlight, this.flights);
        if (this.activeFlight > this.flights.length - 1) {
            return null;
        }

        return this.flights[this.activeFlight];
    }

    @computed get hasNextFlight() {
        return (this.activeFlight < this.flights.length - 1);
    }

    @computed get isLastFlight() {
        return (this.activeFlight === this.flights.length - 1);
    }

    @computed get isFlightAllSeatsPaid() {
        return this.flights.length > 0 && this.flights[0].flightIndex === 1;
    }

    @computed get flight1() {
        if (this.flights.length > 0) {
            if (this.activeFlight === 1) {
                return this.flight2;
            }
            return this.flights[0];
        }

        return null;
    }

    @computed get flight2() {
        if (this.flights.length > 1) {
            return this.flights[1];
        }
        if (this.isFlightAllSeatsPaid) {
            return this.flights[0];
        }

        return null;
    }

    @action resetSelectedSeats() {
        if (this.flight1) {
            this.flight1.resetSelectedSeats();
        }
        if (this.flight2) {
            this.flight2.resetSelectedSeats();

        }
    }

    @computed get areSeatsAvailable() {
        return (this.flight1 || this.flight2);
    }

    @computed get firstNotPaid() {
        return this.root.passengersStore.passengersExcludeInfants.findIndex(obj => !this.root.pnrStore.seat1IsPaid(obj.id - 1));
    }

    @computed get firstNotPaidReturn() {
        return this.root.passengersStore.passengersExcludeInfants.findIndex(obj => !this.root.pnrStore.seat2IsPaid(obj.id - 1));
    }

    //TODO: fix the code for no web checkin. We need to find a better way to provide an activeFlight
    // temporary the computed returns function that accepts activeFlight parameter that is undefined if not passed which is not the best practice for mobx
    @computed get tempReservedLength() {
        let tempSeats;
        if (this.root.isWebCheckIn) {
            tempSeats = access(this.flights[this.root.checkInUIStore.curDirection], '.data.orderTemporaryReservedSeats');
        } else {
            tempSeats = access(this.flights[this.activeFlight], '.data.orderTemporaryReservedSeats');
        }
        return (activeFlight = undefined) => {
            if (typeof activeFlight === 'number') {
                tempSeats = access(this.flights[activeFlight], '.data.orderTemporaryReservedSeats');
            }
            return tempSeats && tempSeats.length > 0;
        }
    }

    @action temporaryReservedSeats(sortedArray, indx) {
        if (!sortedArray || sortedArray.length === 0) {
            console.log('the flights array is empty');
            return;
        }

        const index = sortedArray.length === 1 ? 0 : indx;
        const tempReservedArray = access(sortedArray, `[${index}].data.orderTemporaryReservedSeats`);

        if (!tempReservedArray || tempReservedArray.length === 0) {
            return;
        }

        return tempReservedArray.map(seatObj => {
            const {passengersExcludeInfants} = this.root.passengersStore;
            const finduser = passengersExcludeInfants.findIndex(passObj => passObj.id === seatObj.seatPassengerID);

            if (finduser === -1 || passengersExcludeInfants[finduser].seats[indx]) {
                return null;
            }
            //prevents setting seats from webcheckin to main flow
            //TODO: fix the issue that if you choose manually seat in web check it will not be prevented of setting the seat to main flow
            if (seatObj.isRandomlyReserved && !this.root.isWebCheckIn) {
                return;
            }
            return passengersExcludeInfants[finduser].setSeat(indx, this.currentSeat(seatObj.seatNumber));
        });
    }

    @action skipAndFetchSeats() {
        try {
            // this.selectSeatsProcess(true);
            this.setActiveFlight(1);
        } catch (e) {
            console.log("error occurred while fetching seats: " + e);
        } finally {
            // this.selectSeatsProcess(false);
            this.selectSeatsProcess(false);
            this.setFetchSeatsType(null);
        }
    }

    @action finalFetchSeats() {
        try {
            // if (this.reservedByOtherUser(1)) {
            if (this.reservedByOtherUser(this.currentFlight.flightIndex)) {
                this.root.alertsStore.showAlert("לפחות אחד מהמושבים אינו פנוי יותר", 'error');
                this.showErrorMessage(true, "לפחות אחד מהמושבים אינו פנוי יותר");

                //TODO: move it to this.reservedByOtherUser
                this.root.passengersStore.passengersExcludeInfants.forEach(passenger => passenger.clearSeat(this.currentFlight.flightIndex));

                if (this.currentFlight.flightIndex === 0) {
                    this.setFlight1ActiveIndex(this.findFirstNotPaid(0));
                } else {
                    this.setFlight2ActiveIndex(this.find2FirstNotPaid(0));
                }
                return;
            }
            this.performSeatsBooking();
            this.root.dashboardUIStore.seatSelectionDialog.closePopup();
        } catch (e) {
            console.log("error occurred while fetching seats: " + e);
            this.setFlight2ActiveIndex(this.find2FirstNotPaid(0));
            this.root.dashboardUIStore.seatSelectionDialog.closePopup();
        } finally {
            this.selectSeatsProcess(false);
            this.setFetchSeatsType(null);
        }
    }

    @action webCheckFetchSeats() {
        try {
            const curDirection = access(this.root.checkInUIStore, '.curDirection');
            if (this.reservedByOtherUser(curDirection)) {
                this.root.alertsStore.showAlert("לפחות אחד מהמושבים אינו פנוי יותר", 'error');
                this.showErrorMessage(true, "לפחות אחד מהמושבים אינו פנוי יותר");
                this.selectSeatsProcess(false);
                console.log("the chosen seat is not available");
                return;
            }
            this.performSeatsBooking(true);
        } catch (e) {
            console.log("error occurred while fetching seats: " + e);
        } finally {
            // this.selectSeatsProcess(false);
            console.log("web check final")
            // this.setFetchSeatsType(null);
        }
    }

    @action webCheckFetchSeatsOnBack() {
        const chk = this.root.checkInUIStore;
        //just finish loading if press back from payment page to seats page
        if (chk.activeScreen === chk.availableScreens[2]) {
            this.performSeatsBooking(true);
        }
        this.selectSeatsProcess(false);
        this.setFetchSeatsType(null);
    }

    @action confirmationPageFetch() {
        //Execute before navigating to the web check confirmation page
        try {
            const {checkInUIStore: chk} = this.root;
            const screens = chk.availableScreens;
            return chk.setActiveScreen(screens[screens.length - 1]);
        } catch (e) {
            console.log("error occurred while navigating to confirmation page " + e);
        } finally {
            this.selectSeatsProcess(false);
            console.log("confirmation page fetch seats finished")
            this.setFetchSeatsType(null);
        }
    }

    @action clearSeatsFetchProcessData() {
        this.selectSeatsProcess(false);
        this.setFetchSeatsType(null);
    }

    @action deleteAllReservedSeats() {
        const pnr = window.sessionStorage.getItem("pnr") || this.root.authStore.pnr;
        const guid = this.root.checkInUIStore.getCheckInGUID;
        this.transport.PerformSeatsBooking(pnr, guid, {"seats": {}})
            .then((res) => {
                history.push('/dashboard');
                window.location.reload();
            })
            .catch(e => console.log(e));
    }

    @computed get flightByFlightIndx() {
        return (indx) => {
            return this.flights.find(flight => flight.flightIndex === indx);
        }
    }

    @computed get reservedByOtherUser() {
        return (flightIndex) => {
            let selectedSeatsArray = this.root.passengersStore.passengersExcludeInfants
                .filter(passenger => passenger.seats[flightIndex])
                .map(passenger => passenger.seats[flightIndex].number);

            // let reservedArray = access(this.flights[flightIndex], '.data.temporaryReservedSeats');
            let allSeats = access(this.flightByFlightIndx(flightIndex), '.data.seats');
            let unavailableSeats = [];
            if (allSeats && allSeats.length > 0) {
                unavailableSeats = allSeats.filter(seat => !seat.available);
                return unavailableSeats.some(seat => selectedSeatsArray.includes(seat.seatNumber));
                // return unavailableSeats.filter(seat => selectedSeatsArray.includes(seat.seatNumber));
            } else {
                console.log("error")
            }
            // return unavailableSeats.filter(seat => !seat.available).some(seat => selectedSeatsArray.includes(seat.seatNumber));
        }
    }

    @action fetchSeatsAndContinue() {
        try {
            if (this.reservedByOtherUser(0)) {
                // if (this.reservedByOtherUser(0) && this.reservedByOtherUser(0).length > 0) {
                this.root.alertsStore.showAlert("לפחות אחד מהמושבים אינו פנוי יותר", 'error')
                this.showErrorMessage(true, "לפחות אחד מהמושבים אינו פנוי יותר");
                return;
            }
            this.setActiveFlight(1);
            this.setFlight2ActiveIndex(this.find2FirstNotPaid(0));
        } catch (e) {
            console.log(e);
        } finally {
            this.selectSeatsProcess(false);
            this.setFetchSeatsType(null);
        }
    }

    @action reset() {
        this.activeFlight = 0;
        this.selectedRestrictedSeat = null;
        this.isBooking = false;
        this.finishedBookingSuccess = false;
        this.flights = [];
        this.fetchSeatsType = null;
        this.showErrorMessageStatus = false;
    }

    // @computed get setSeatFromTemporary () {
    //     return this.temporaryReservedSeats;
    // }

    /*
    @computed get passengers() {
        if (!this.flight1 || !this.flight2) {
            return [];
        }

        const a = this.flight1.passengers.map((p,ind) => {
            const p2 = this.flight2.passengers[ind];

            return {
                "fullName" :  p.fullName,
                "seat1": p.seat ? p.seat.number : "",
                "seat2": p2.seat ? p2.seat.number : "",
                "totalPrice" : p.price + (p2.seat ? p2.price : 0)
            }
        })

        return a;
    }

     */

    @computed get currentSeat() {
        return (seatNumber => {
            let seat = access(this.flights[this.activeFlight], `planeSeats`);
            let row = seatNumber.replace(/\D+/g, '') * 1;
            let letter = seatNumber[seatNumber.length - 1];
            if (seat && seat.length > 0) {
                return seat.find(rowObj => rowObj.row === row).seats[letter];
                // .seats.find(seatObj => seatObj.number === seatNumber);
                //                                        seatNumber === "13B" => 13 and "B"
                // return seat.find(seatObj => seatObj.seatNumber === seatNumber);
            } else {
                return null;
            }
        })
    }

    //Total price of seats
    @computed get flight1TotalPrice() {
        let price = 0;
        //webcheck in functionality
        if (this.root.isWebCheckIn && this.root.checkInUIStore.randomlyPicked) {
            return 0;
        }
        const passengers = this.root.passengersStore.passengersExcludeInfants;
        passengers.forEach((p) => {
            price += p.seat1Price;
        })

        return Math.round(price * 100) / 100;
    }

    //TODO: check if this function not doing the same as the one above
    @computed get totalPriceByDirection() {
        if (this.root.isWebCheckIn) {
            //web checkin functionality
            if (!this.root.pnrStore.currentFlightAllSeatsPaid) {
                return this.root.checkInUIStore.curDirection === 0 ? this.flight1TotalPrice : this.flight2TotalPrice;
            } else {
                return 0;
            }
        }
    }


    @computed get selectedSeatsTotalPriceIsZero() {
        //TODO: rename the function
        return this.root.isWebCheckIn ? this.root.passengersStore.passengersExcludeInfants.some(pass => pass.seats[this.root.checkInUIStore.curDirection]) : console.log("handle free seats in main flow");
    }

    //show how many passengers have picked seats in webcheck
    @computed get selectedSeatsCount() {
        //    webcheckin functionality
        return this.root.passengersStore.passengersExcludeInfants.filter(pass => pass.seats[this.root.checkInUIStore.curDirection]).length;
    }

    @computed get flight1SeatsCount() {
        const passengers = this.root.passengersStore.passengersExcludeInfants;
        return passengers.filter((p) => {
            return p.seat1Price > 0
        }).length;
    }

    @computed get flight2TotalPrice() {
        let price = 0;
        if (this.root.isWebCheckIn && this.root.checkInUIStore.randomlyPicked) {
            //web checkin functionality
            return 0;
        }
        const passengers = this.root.passengersStore.passengersExcludeInfants;
        passengers.forEach((p) => {
            price += p.seat2Price;
        })

        return Math.round(price * 100) / 100;
    }

    @computed get flight2SeatsCount() {
        const passengers = this.root.passengersStore.passengersExcludeInfants;
        return passengers.filter((p) => {
            return p.seat2Price > 0
        }).length;
    }

    @computed get totalPrice() {
        console.log("totalPrice", this.flight1TotalPrice, this.flight2TotalPrice);
        return Math.round((this.flight1TotalPrice + this.flight2TotalPrice) * 100) / 100;
    }

    @computed get currentDirection() {
        const toFlight = "הלוך";
        const backFlight = "חזור";

        const isFlightStart = this.currentFlight && access(this.currentFlight, '.flightIndex') === 0;

        if (this.root.isWebCheckIn) {
            //web checkin functionality
            const curDirection = access(this.root.checkInUIStore, '.curDirection') === 0;
            return isFlightStart || curDirection ? toFlight : backFlight;
        }

        return isFlightStart ? toFlight : backFlight;
    }

    @computed get postData() {
        const data = {};
        const passengers = this.root.passengersStore.passengersExcludeInfants;

        const {isWebCheckIn} = this.root;
        const webCheckDirection = isWebCheckIn && this.root.checkInUIStore.curDirection;

        //checks if at least one passenger for current flight picked a seat by checking seat1 price
        //for main system:
        const firstFlight = isWebCheckIn ? (webCheckDirection === 0 && this.selectedSeatsTotalPriceIsZero) : this.flight1SeatsCount > 0;
        //TODO: check this.selectedSeatsTotalPriceIsZero
        // const firstFlight = isWebCheckIn? (webCheckDirection === 0 && this.
        // selectedSeatsTotalPriceIsZero) : this.flight1SeatsCount > 0;

        if (firstFlight) {
            const guid = this.root.pnrStore.flight1Guid;
            const d = {};

            passengers.forEach((p, index) => {
                if (p.seat1) {
                    d[p.id] = p.seat1.number;
                }
            })
            data[guid] = d;
        }

        const secondFlight = isWebCheckIn ? (webCheckDirection === 1 && this.selectedSeatsTotalPriceIsZero) : this.flight2SeatsCount > 0;

        if (secondFlight) {
            const guid = this.root.pnrStore.flight2Guid;
            const d = {};
            passengers.forEach((p, index) => {
                if (p.seat2) {
                    d[p.id] = p.seat2.number;
                }
            })
            data[guid] = d;
        }

        console.log("!!! data !!!", data);
        return data;
    }

    @action findFirstNotPaid(index) {
        return !this.root.pnrStore.seat1IsPaid(index) ? index : this.firstNotPaid
    }

    @action find2FirstNotPaid(index) {
        return !this.root.pnrStore.seat2IsPaid(index) ? index : this.firstNotPaidReturn
    }


    @action setRandomSeats() {
        console.log("set random seats")
        this.selectSeatsProcess(true);
        const guid = this.root.checkInUIStore.getCheckInGUID;
        let passengers = this.root.passengersStore.passengersExcludeInfants;
        //Todo: support for sending selected IDs instead of sending them all
        let data = {};
        if (passengers && passengers.length > 0) {
            passengers = passengers.map(pas => pas.id); //Mapping passengers to their IDs
        }
        data = {
            ...data,
            "PassengersIDs": passengers // Adding the passengers' IDs to the data payload.
        };
        // Looping through each passenger and setting their seat.
        //the server side will ignore the ids that are already paid
        this.transport.fetchRandomSeats(getActivePNR(), guid, data)
            .then((response) => {
                console.log("random seats response", response.data);
                this.seatsData = response.data.data;
                // pStore.passengersExcludeInfants[flight.activeIndex].setSeat(flight.flightIndex, seat);
                this.root.passengersStore.passengersExcludeInfants.forEach(p => {
                    if (this.seatsData.hasOwnProperty(p.id)) {
                        const curFlightObject = access(this.currentFlight, '.planeSeats');
                        const curSeatObj = findSeat(curFlightObject, this.seatsData[p.id]); // // Finding the corresponding seat object for the passenger.
                        curSeatObj.price = 0;
                        //TODO: fix the workaround

                        // const removePrice = curSeatObj && {...curSeatObj, price : 0};
                        p.setSeat(this.root.checkInUIStore.curDirection, curSeatObj); // Assigning the seat to the passenger.
                    }
                })
                // Deactivating automatic random seat assignment if it's the first time.
                if (!this.root.checkInUIStore.initialRandomSeatsActivated) {
                    this.root.checkInUIStore.deactivateAutomaticRandomSeatsExecution();
                }
            })
            .catch((e) => {
                console.log("error", e);
                this.seatsData = null;
            })
            .finally(() => {
                console.log("perform it anyway at the end");
                this.selectSeatsProcess(false);
                this.setFetchSeatsType(null);
            })
    }

    @action setPassengerSelectingSeat(id) {
        if (!id) {
            this.currentPassengerType = null;
            return;
        }
        this.currentPassengerType = id;
    }

    @computed get flightHasOnePassenger() {
        const passengersArr = access(this.currentFlight, 'passengers');
        return passengersArr.length === 1;
    }

    @computed get didChildPickedRestrictedSeat() {
        const arrIndex = this.root.isWebCheckIn ? this.root.checkInUIStore.curDirection : this.activeFlight;
        return this.root.passengersStore.passengersExcludeInfants.some(p => p.passengerType === 2 && access(p.seats[arrIndex], '.restricted'));
    }

    // @computed get allPassengersSeatNextToEachOther() {
    //     // filter passengers array with only passengers that selected seats
    //     let selectedSeatsArr = this.root.passengersStore.passengersExcludeInfants.filter(p => {
    //         return p.seats[this.root.checkInUIStore.curDirection] !== null;
    //     }).map(filteredPas => {
    //         return filteredPas.seats[this.root.checkInUIStore.curDirection].number;
    //     });
    //
    //     // Helper function to determine if two seats are adjacent
    //     const areSeatsAdjacent = (seat1, seat2) => {
    //         const seatNumber1 = seat1.slice(0, -1);
    //         const seatLetter1 = seat1.slice(-1);
    //
    //         const seatNumber2 = seat2.slice(0, -1);
    //         const seatLetter2 = seat2.slice(-1);
    //
    //         return seatNumber1 === seatNumber2 && Math.abs(seatLetter1.charCodeAt(0) - seatLetter2.charCodeAt(0)) === 1;
    //     }
    //     //sort array of passengers seats
    //     const sortedSeats = selectedSeatsArr.sort();
    //
    //
    //     return sortedSeats.some((seat, index) => {
    //         if (index === sortedSeats.length - 1) return false; // skip the last seat
    //
    //         return areSeatsAdjacent(seat, sortedSeats[index + 1]);
    //     });
    // }

    @computed get pnrHasOneAdult() {
        return this.root.passengersStore.passengersExcludeInfants.filter(p=> p.passengerType === 1).length === 1;
    }

    @computed get pnrWithAdultAndChild() {
        return this.pnrHasOneAdult && this.root.passengersStore.passengersExcludeInfants.some(p => p.passengerType === 2);
    }

}
export {SeatsStore}