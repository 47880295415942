import React from 'react'
import { inject, observer } from "mobx-react";
import 'app/desktop/ui/components/dialogs/dlg-seat-selection/components/plane/plane.scss';
import classnames from 'classnames';

@inject("pnrStore") @observer
class Seat extends React.Component {
    generateCssMain = (category, selected, reserved, available, paidSeats) => {
        return classnames("seat", {
            "selected": selected || reserved || (!available && paidSeats),
            "disabled": !available && !paidSeats,
            "type-a": category === "1" && !selected && !reserved,
            "type-b": category === "2" && !selected && !reserved,
            "type-c": category === "3" && !selected && !reserved,
        })
    };

    generateCss = (category, selected, reserved, available, paidSeats) => {
        return classnames("seat-icon", {
            "type-a": category === "1",
            "type-b": category === "2",
            "type-c": category === "3",
            "selected": selected || reserved || (!available && paidSeats),
            "disabled": !available && !paidSeats
        })
    };

    render() {
        const { pnrStore: pnr, category, place, number, selected, available } = this.props;

        if (!place) {
            return null;
        }
        const paidSeats = pnr.flightSeatsByIndex.includes(number);
        const reserved = pnr.reservedSeats.includes(number);
        const cssMain = this.generateCssMain(category, selected, reserved, available, paidSeats);
        const css = this.generateCss(category, selected, reserved, available, paidSeats);

        return (
            <div className={cssMain} onClick={() => {
                if ((!selected && !reserved) && available) {
                    this.props.onClick();
                }
            }}>
                <div className={css}><div className={"txt"}>{number}</div> </div>
                <div className={"tooltip"}> מושב&nbsp;<span className={"seat-num"}>{number}</span>&nbsp;-&nbsp;<span className={"price"}>{this.props.currency}{this.props.price}</span></div>
            </div>
        )
    }
}

Seat.defaultProps = {
    onClick: () => { }
}

@inject("seatsStore", "dashboardUIStore", "passengersStore", "pnrStore", "isWebCheckIn", "checkInUIStore") @observer
class Plane extends React.Component {
    //flight obj is taken from the seatsStore
    handleSeatClick = (seat, flight, pStore) => {
        if (this.props.pnrStore.currentFlightAllSeatsPaid) {
            console.log("you can not set the seat if all seats for current flight is paid");
            return;
        }
        // console.log("click", seat, flight.passengers, flight.passengers[flight.activeIndex]);
        if (seat.restricted) {
            this.props.seatsStore.setSelectedRestrictedSeat(seat);
            this.props.dashboardUIStore.restrictedSeatsDlg.openPopup();
        } else {
            const currentSeat = pStore.passengersExcludeInfants[flight.activeIndex]?.seats[flight.flightIndex];
            if (currentSeat && currentSeat.number) {
                currentSeat.setSelected(false);
            }
            if (this.props.isWebCheckIn) {
                //web checkin functionality
                if (this.props.checkInUIStore.randomlyPicked) {
                    this.props.checkInUIStore.setRandomlyPicked(false);
                    pStore.passengersExcludeInfants.forEach(passenger => passenger.clearSeat(this.props.checkInUIStore.curDirection));
                    this.props.seatsStore.setFetchSeatsType("webCheckFetchAfterReplacingRandomSeat");
                    this.props.seatsStore.fetchSeats(true);
                }
                //delete all randomly picked seats
            }

            let activeFlight;
            if (pStore && pStore.passengersExcludeInfants && flight && flight.activeIndex != null) {
                activeFlight = pStore.passengersExcludeInfants[flight.activeIndex];
            }
            if (activeFlight) {
                activeFlight.setSeat(flight.flightIndex, seat);
                seat.setSelected(true);
                flight.setNextActive();
            } else {
                flight.setActive(0);
            }
        }
    }

    getRowCss = (aircraftType, row) => {
        if (aircraftType === 'B733') {
            return (row.row !== 12) ? "row" : "row extend";
        } else {
            return (row.row !== 15 && row.row !== 16) ? "row" : "row extend";
        }
    }

    render() {
        const { flight, passengersStore: pStore } = this.props;

        // if (!this.props.dashboardUIStore.seatSelectionDialog.isOpen) {
        //    return null;
        // }
        //
        if (!flight) {
            return null;
        }

        const aircraftType = flight.data.aircraft;
        const css = classnames("plane", aircraftType);
        return (
            <div className={css}>
                <div className={"wrap"}>
                    <div className={"seats-map"}>
                        <div className={"row legend"}>
                            <div className={"col c-seat"}>A</div>
                            <div className={"col c-seat"}>B</div>
                            <div className={"col c-seat"}>C</div>
                            <div className={"col c-rnum"}>&nbsp;</div>
                            <div className={"col c-seat"}>D</div>
                            <div className={"col c-seat"}>E</div>
                            <div className={"col c-seat"}>F</div>
                        </div>
                        <div className={"seat-rows"}>
                            {flight.planeSeats.map((row, rowIndex) => {
                                let css = this.getRowCss(aircraftType, row);

                                return (
                                    <div className={css} key={"plane-row-" + rowIndex}>
                                        <div className={"col c-seat"}><Seat {...row.seats.A} onClick={() => this.handleSeatClick(row.seats.A, flight, pStore)} /></div>
                                        <div className={"col c-seat"}><Seat {...row.seats.B} onClick={() => this.handleSeatClick(row.seats.B, flight, pStore)} /></div>
                                        <div className={"col c-seat"}><Seat {...row.seats.C} onClick={() => this.handleSeatClick(row.seats.C, flight, pStore)} /></div>
                                        <div className={"col c-rnum"}>{row.row}</div>
                                        <div className={"col c-seat"}><Seat {...row.seats.D} onClick={() => this.handleSeatClick(row.seats.D, flight, pStore)} /></div>
                                        <div className={"col c-seat"}><Seat {...row.seats.E} onClick={() => this.handleSeatClick(row.seats.E, flight, pStore)} /></div>
                                        <div className={"col c-seat"}><Seat {...row.seats.F} onClick={() => this.handleSeatClick(row.seats.F, flight, pStore)} /></div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export { Plane }
