import React from 'react'

class PageError extends React.Component {
    render() {
        return (
            <div className={"page-error"}>
                <div className={"message"}>מצטערים, לא הצלחנו להשלים את רכישתך. אנא פנה לשירות הלקוחות לצורך השלמת התהליך</div>
            </div>
        );
    }
}


export {PageError}