import React from 'react';
import { BlueButton } from "app/desktop/ui/components/buttons/blue-button/blue.button";
import 'app/desktop/ui/components/dialogs/dlg-extra-services/ExraService/extra-service.scss';

const Service = ({ title, text, textASVC, added, price, priceASVC, totalPrice, onToggle, currencySymbol, disabled, purchasedPRIO }) => (

    //
  <div className={"extra-service"}>
    <div className={"extra-col-1"}>
      <h4>{title}</h4>

      <p className={"first-service"}>{text}</p>
        {!purchasedPRIO && <strong>בעלות של <span className={"currency"}>&#x20AC;</span><span className={"price"}>{price}</span> לנוסע.</strong>}


    </div>
    <BlueButton onClick={onToggle} disabled={disabled}>
      {(disabled) &&
          <div className={"generic-tooltip"}>
          <div className={"text"}>
              {purchasedPRIO ? "שים לב, לא ניתן להסיר את" : "ניתן להוסיף שירות אחד בלבד, עלייך להסיר את"}
              <br />
              {purchasedPRIO ? "השירות לאחר הרכישה." : "השירות שנבחר במידה ותרצה לבחור שירות זה בלבד."}
          </div>
      </div>}
      {added ? "הסר" : "הוסף"}</BlueButton>

      {(purchasedPRIO && !textASVC) &&
          <div className={"purchased-mark"}>
              <div className={"icon-v purchased-icon"}></div>
          </div>
      }
  </div>
);

export default Service;