import React from 'react'
import {inject, observer} from "mobx-react";
import {RegularButtonBig} from "app/desktop/ui/components/buttons/regular-button/regular.button";
import {history} from 'app/services/custom.router'

@inject("stepsStore") @observer
class TableFooter extends React.Component {
    render() {

        const onClick =(e) => {

            e.preventDefault();
            const previousStep = this.props.stepsStore.previousStep;
            this.props.stepsStore.setStep(previousStep.type);
            history.push(previousStep.path);
        }
        return (
            <div className={"cs-deal-footer"}>
                <div className={"wrap"}>
                    <div className={"col-1"}>
                        <RegularButtonBig onClick={onClick}>חזור <div className={"icon-back"}/> </RegularButtonBig>
                    </div>

                    <div className={"col-2"}>
                        <div className={"text"}>
                            {this.props.children}
                        </div>

                        <div className={"price"}>
                            {this.props.currency}{this.props.price}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export {TableFooter}