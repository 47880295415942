import {observable, action} from "mobx";

const AuthUIStoreSteps = {
    "LOGIN" : "LOGIN",
    "PHONE_VERIFICATION" : "PHONE_VERIFICATION"
}

class AuthUIStore {
    @observable step = AuthUIStoreSteps.LOGIN;

    constructor(root) {
        this.root = root;
    }

    @action setStep(newStep) {
        this.step = newStep;
    }
}

export {AuthUIStore}