import React from 'react'
import 'app/desktop/ui/pages/page-success/page.success.scss'
import {RegularButton} from "app/desktop/ui/components/buttons/regular-button/regular.button";
import {inject, observer} from "mobx-react";
import {history} from "app/services/custom.router";
import {getActivePNR, getAgentAccessToken} from "app/services/session.service";
import {ButtonContinue} from "app/desktop/ui/components/buttons/button-continue/button.continue";

@inject ("dashboardUIStore", "pnrStore", "baggageStore", "isWebCheckIn", "checkInUIStore", "paymentStore") @observer
class PageSuccess extends React.Component {

    componentDidMount() {

        if ( window.location !== window.parent.location ) {
            // The page is in an iframe
        } else {
            // The page is not in an iframe
            const pnr = getActivePNR();
            // console.log("fetch data on success", pnr);

            if (pnr) {
                this.props.pnrStore.loadData(getActivePNR());
            }
        }
    }

    render() {
        const recipeDialog = this.props.dashboardUIStore.sentRecipeDialog;
        const pay = this.props.paymentStore;
        const {isWebCheckIn} = this.props;

        if (isWebCheckIn) {
            return null;
        }

        const handleSendRecipeEmail = () => {
            recipeDialog.openPopup();
            pay.setRecipeMessageDetails("email");

        };

        const isAgent = getAgentAccessToken();
        return (
            <div className={"page-success"}>
                <div className={"message"}>
                    <div className={"success-image"}/>

                    <div className={"success-text"}>
                    התשלום בוצע בהצלחה,<br/>
                    <span className={"small"}>התוספות עודכנו בהזמנתך</span>
                    </div>
                </div>


                <div className={"button-placeholder"}>
                    <RegularButton onClick={(e) => {
                        handleSendRecipeEmail();
                    }}>לקבלת אישור
                        הרכישה במייל</RegularButton>
                </div>

                {!isAgent &&
                    <div className={"customers-text"}>מסמכי הזמנה מעודכנים יישלחו לכתובת המופיעה בהזמנה
                        (לקוחות שביצעו את ההזמנה באמצעות סוכנות נסיעות - המסמכים יישלחו לסוכנות).<br/>
                        לקוחות המעוניינים בקבלה עבור רכישת השירותים, ניתן לפנות אלינו באמצעות דף
                        <a className={"default-link"} target="_blank" rel="noopener noreferrer" href={"https://www.kavei.co.il/GeneralInfo/ContactUs.aspx"}> 'צור קשר' באתר קווי חופשה </a>
                        תחת 'בירורים כספיים'</div>
                }


            </div>
        )
    }
}

export {PageSuccess}