import {ModalWarning} from "app/desktop/ui/components/modal-warning/modal.warning";
import BaggageCheckIn from "app/desktop/images/baggage-checkin.png";
import React from "react";
import {inject, observer} from "mobx-react";


@inject ("dashboardUIStore", "checkInUIStore") @observer
class BaggageModal extends React.Component {
    render() {
        const dlg = this.props.dashboardUIStore.addBaggageCheckIn;
        const chk = this.props.checkInUIStore;

        return (
            <div className={"add-baggage-wrap"}>
                <ModalWarning
                    title={"baggage"}
                    isOpen={dlg.isOpen}
                    onClose={() => {
                        dlg.closePopup()
                    }}
                    image={BaggageCheckIn}
                    buttonText1='הזמן מזוודה'
                    buttonText1Icon
                    buttonText2='הבנתי, אנו מגיעים ללא מזוודה'
                    dashboardStore ={dlg}
                    activeScreen ={()=> chk.moveToNextScreen()}>
                    <p>אין בעיה, שימו לב,<br/> עלות הוספת מזוודה בשדה התעופה <br/><strong>גבוהה משמעותית מהזמנה באתר</strong></p>
                </ModalWarning>
            </div>
        )
    }
}

export {BaggageModal};