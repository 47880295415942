import React from 'react';
import {inject, observer} from "mobx-react";

import 'app/desktop/ui/pages/dashboard/components/hotel-details/hotel.details.scss';
import {DealStatus} from "app/desktop/ui/components/deal-status/deal.status";

@inject("dashboardUIStore","hotelStore", "pnrStore") @observer
class HotelDetails extends React.Component {
    render() {
        const s = this.props.hotelStore;
        if (!s.isHotelData) {
            return null;
        }

        return (
            <div className={"cs-section"}>
                <div className={"section-title"}>
                    <div className={"icon icon-hotel"}/>
                    פרטי המלון
                    <DealStatus {...this.props.pnrStore.hotelsStatusData}/>
                </div>
                    <div className={"cs-hotel"}>
                        <div className={"hotel-name"}><span>{s.hotelName}</span>
                            <div className={"hotel-stars"}>
                                <div className={"wrap"}>
                                {s.starsArray.map((star, index) => {
                                    return <div className={"icon star " + star} key={'star'+ index}/>
                                })}
                                </div>
                            </div>
                        </div>

                        <div className={"hotel-dates"}>
                            <span>כניסה: יום</span>&nbsp;
                            <span>{s.day1Short}’</span>&nbsp;
                            <span className={"date"}>{s.checkInDate}</span>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span>יציאה: יום</span>&nbsp;
                            <span>{s.day2Short}’</span>&nbsp;
                            <span className={"date"}>{s.checkOutDate}</span>
                        </div>

                        <div className={"hotel-rooms"}>
                            {s.hotelRooms.map((room, ind) => {
                                return (
                                    <div className={"hotel-room"} key={"room_"+ind}>
                                        <div className={"col num"}>חדר:  <span className={"num"}>{room.number}</span></div>
                                        <div className={"col room-type"}>סוג חדר: <span className={"num"}>{room.roomType}</span></div>
                                        <div className={"col room-type"}>בסיס אירוח: {room.boardBase}</div>
                                    </div>
                                )
                            })}
                        </div>



                    </div>
                <div className={"details-col-hotel"}>
                    <a href={"#"} onClick={(e) => {
                        e.preventDefault();
                        this.props.dashboardUIStore.hotelDetailsDlg.openPopup();
                    }}>הצג פירוט מלא</a>
                </div>

            </div>
        )
    }
}

export {HotelDetails}