import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";


import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import * as serviceWorker from './serviceWorker';
import {Provider} from 'mobx-react'
import 'mobx-react/batchingForReactDom'

import './app/services/data.service';
import allStores from "./app/stores/all.stores";
// import { isMobile } from "app/services/env.service";

let App;
if (process.env.REACT_APP_MODE === 'mobile') {
    App = require('app/mobile/App').default;
} else {
    App = require('app/desktop/App').default;
}


if (process.env.NODE_ENV && process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: "https://260c60e2499a4e7fa622cc0c09d5a54a@o1088777.ingest.sentry.io/6103546",
        integrations: [new Integrations.BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

console.log("checking if auth");
/*
if (!allStores.authStore.isAuthenticated) {
    console.log("trying to auth");
}
allStores.authStore.performAuth();
*/

ReactDOM.render(
  <React.StrictMode>
    <Provider {...allStores}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
