import React from 'react'
import "app/desktop/ui/components/buttons/main-button/main.button.scss"
import classnames from 'classnames'

class MainButton extends React.Component {
    render() {
        return (
            <button className={"btn btn-main"} disabled={this.props.disabled} onClick={this.props.onClick}>
                {this.props.children}
            </button>
        )
    }
}

MainButton.defaultProps = {
    onClick : () => {},
    disabled: false,
}


class PaymentButton extends React.Component {
    render() {
        const tooltipMsg = this.props.tooltip;
        const css = classnames("btn-payment ",{disabled : this.props.disabled});
        const tooltip = (this.props.disabled) ?
                <div className={"generic-tooltip"}><div className={"text"}>{tooltipMsg}</div></div> : "";
        return (
            <div className={css} onClick={this.props.onClick}>
                {tooltip}
                <div className={"wrap"}>
                <div className={"icon-lock"}/>
                {this.props.children}
                </div>
            </div>
        )
    }
}

PaymentButton.defaultProps = {
    onClick : () => {},
    disabled : false
}

export {MainButton, PaymentButton}