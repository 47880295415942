import {observable, action} from "mobx";


class PopupStore {
    @observable isOpen = false;
    @observable x = 0;
    @observable y = 0;

    constructor(modal = true) {
        this.modal = modal;
    }

    @action setPosition (x,y) {
        this.x = x;
        this.y = y;
    }
    @action openPopup () {
        this.isOpen = true;
    }

    @action closePopup() {
        this.isOpen = false;
    }

}

export {PopupStore}