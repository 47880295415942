import React from 'react'
import {inject, observer} from "mobx-react";
import "app/desktop/ui/pages/dashboard/components/deal-details/deal.details.scss";
import {FlightDetails} from "app/desktop/ui/pages/dashboard/components/flight-details/flight.details";
import {PassengersInfo} from "app/desktop/ui/pages/dashboard/components/passengers/passengers";
import {getActivePNR} from "app/services/session.service";
import {HotelDetails} from "app/desktop/ui/pages/dashboard/components/hotel-details/hotel.details";
import {ExtraServicesDetails} from "app/desktop/ui/pages/dashboard/components/extra-services/extra-services.details";
import {StepTypes} from "app/stores/steps.store";
import {DealStatus} from "app/desktop/ui/components/deal-status/deal.status";
// import {ServicesStatus} from "app/desktop/ui/pages/dashboard/components/services-status/services.status";
import {Scrollbar} from "react-scrollbars-custom";

@inject("pnrStore", "authStore",'stepsStore') @observer
class DealDetails extends React.Component {
    componentDidMount() {
        this.props.stepsStore.setStep(StepTypes.INFORMATION)
    }

    render() {
        if (!this.props.pnrStore.data) {
            return  null;

        }
        return (
            <div className={"cs-deal"}>
                <div className={"cs-deal-title deal-info"}>
                    <span className={"main"}>פירוט הזמנה -</span>&nbsp;
                    <span className={"num"}>{getActivePNR()}</span>
                    {this.props.pnrStore.orderStatus &&
                        <span className={"flight-status warn order-warn-process"}>חלק מהשירותים בהזמנה נמצאים בתהליך אישור.

                        ניתן יהיה לרכוש תוספות עם השלמת הליך האישור.</span>}
                </div>

                <div className={"cs-deal-content"}>
                    <Scrollbar noDefaultStyles>
                        <div className={"cs-section"}>
                            <div className={"section-title"}>
                                <div className={"icon icon-flight"}/>
                                פרטי הטיסה

                                <DealStatus {...this.props.pnrStore.flightStatusData}/>
                            </div>

                            <FlightDetails/>

                           {/*<ServicesStatus/>*/}
                        </div>

                        <HotelDetails/>
                        <ExtraServicesDetails />

                        <div className={"cs-section"}>
                            <div className={"section-title"}>
                                <div className={"icon icon-passenger"}/>
                                פרטי הנוסעים</div>

                            <PassengersInfo/>
                        </div>
                    </Scrollbar>
                </div>
            </div>
        )
    }
}

export {DealDetails}