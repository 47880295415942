import React from 'react'
import classnames from "classnames";
import 'app/desktop/ui/components/modal/modal.scss';

class ModalDialog extends React.Component {
    render() {
        const css = classnames("cs-modal",{"modal" : this.props.modal}, {"open" : this.props.isOpen});
        return (
            <div className={css}>
                <div className={"cs-modal-wrap"}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}


ModalDialog.defaultProps = {
    isOpen : false,
    modal : true
}

export {ModalDialog}
