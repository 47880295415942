import {inject, observer} from "mobx-react";
import React from "react";
import {FlightInfoContainer} from "app/desktop/ui/components/dialogs/dlg-seat-selection/components/flight-info/flight.info.container";
import classnames from "classnames";
import {Passengers} from "app/desktop/ui/components/dialogs/dlg-seat-selection/components/passengers/passengers";
import {Legend} from "app/desktop/ui/components/dialogs/dlg-seat-selection/components/legend/legend";
import {Plane} from "app/desktop/ui/components/dialogs/dlg-seat-selection/components/plane/plane";
import {ScreenWrapper} from "app/desktop/ui/components/dialogs/dlg-seat-selection/components/dlg.seat.popup";
import {Preloader} from "app/desktop/ui/components/preloader/preloader";

@inject ( "seatsStore", "isWebCheckIn", "pnrStore", "passengersStore", "checkInUIStore", "dashboardUIStore") @observer
class SeatsContent extends React.Component {
    componentDidMount() {
        if (this.props.isWebCheckIn && !this.props.pnrStore.currentFlightAllSeatsPaid) {
            //do fetch seats with deleting random seats or not depending on boolean in param
            this.props.seatsStore.selectSeatsProcess(true);
            this.props.seatsStore.fetchSeats(this.props.checkInUIStore.initialRandomSeatsActivated);
        }
    }

    render() {
        const {isWebCheckIn} = this.props;
        const s = this.props.seatsStore;

        const showDirection = !isWebCheckIn && <FlightInfoContainer/>;
        const css = classnames("row", {"web-check-space" : isWebCheckIn});

        const cssWrapper = classnames("dlg-seats", {
            "dlg-seats-all-paid" : this.props.pnrStore.currentFlightAllSeatsPaid
        })

        if (this.props.isWebCheckIn && !this.props.dashboardUIStore.seatSelectionDialog.isOpen === false) {
            return null;
        }

        if (isWebCheckIn && s.isBooking) {
            return (
                <ScreenWrapper>
                    <div className={"popup-loader"}>
                        <Preloader/>
                    </div>
                </ScreenWrapper>
            )
        }


        return (
            <div className={cssWrapper}>
                <div className={css}>
                    <div className="col-1">
                        {showDirection}
                        <Passengers flight={s.currentFlight}/>
                    </div>
                    <div className="col-2">
                        <Legend flight={s.currentFlight}/>
                        <Plane flight={s.currentFlight}/>
                    </div>
                </div>
            </div>
        );
    }
}

export {SeatsContent};