import {action, computed, observable} from "mobx";

const type = {
  ASVC: 'ASVC',
  PRIO: 'PRIO',
};

class ExtraServicesStore {
  constructor(root) {
    this.root = root;
  }

  @observable availableServices = [];
  @observable addedServices = [];

  @computed get areExtraServicesAvailable() {
    return this.availableServices.length && !(this.purchasedPRIO || this.purchasedASVC);
  }

  @computed get priorityPRIO() {
    return this.availableServices.find(({ code }) => code.includes(type.PRIO));
  }

  @computed get priorityASVS() {
    return this.availableServices.find(({ code }) => code.includes(type.ASVC));
  }

  @computed get totalPrice() {
    return this.addedServices.reduce((accumulator, y) => accumulator + y.price * this.root.passengersStore.passengersExcludeInfants?.length, 0);
  }

  @computed get services() {
    return {
      9: {
        title: "שירות Priority בדלפקי הצ'ק אין",
        text: `דלפק צ'ק אין לנוסעי ה-Priority בלבד, בו תוכלו למסור את הכבודה ולקבל כרטיסי עלייה למטוס, ללא המתנה.`
      },
      8: {
        title: "שירות משולב - Priority בדלפקי הצ'ק אין + בידוק ביטחוני מהיר",
        text: "דלפק צ'ק אין לנוסעי ה-Priority בלבד, בו תוכלו למסור את הכבודה ולקבל כרטיסי עלייה למטוס, ללא המתנה, בשילוב מסלול בידוק ביטחוני מהיר."
      }
    }
}

  @action addAvailableServices(flights) {
    const newServices = flights.flatMap(flight =>
        flight.availableSsrs.filter(ssr => [type.PRIO, type.ASVC].includes(ssr.code)).map(ssr => ({
          ...ssr,
          guid: flight.guid
        }))
    );

    this.availableServices = newServices;
  }

  @computed get purchasedPRIO() {
    const extraservices = this.root.pnrStore.extraServicesInDeal;
    return extraservices.filter((service) => service.type === 9).length > 0;
  }

  @computed get purchasedASVC() {
    const extraservices = this.root.pnrStore.extraServicesInDeal;
    return extraservices.filter((service) => service.type === 8).length > 0;
  }

  @action addService(service) {
    this.addedServices.push(service);
  }

  @action removeService(code) {
    this.addedServices = this.addedServices.filter(service => service.code !== code);
  }

  @action refreshServices() {
    this.addedServices = [];
  }

  @action toggleService(service) {
    if (this.addedServices.some(addedService => addedService.code === service.code)) {
      this.removeService(service.code);
      if (service.code === "ASVC" && this.priorityPRIO) {
        this.removeService("PRIO");
      }
      return;
    }
    if (service.code === "ASVC" && this.priorityPRIO) {
      this.refreshServices();
      this.addService(this.priorityPRIO);
    }
    this.addService(service);
  }

  @action reset() {
    this.availableServices = [];
    this.addedServices = [];
  }

  @computed get getService() {
    const extraservices = this.root.pnrStore.extraServicesInDeal;
    const hasType8 = extraservices.some((service) => service.type === 8);
    const hasType9 = extraservices.every((service) => service.type === 9);

    if (hasType8) {
      return this.services[8];
    } else if (hasType9 && !hasType8) {
      return this.services[9];
    } else {
      return null; // or return a default service object
    }
  }

}

export {ExtraServicesStore}