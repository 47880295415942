import React from 'react';
import "app/desktop/ui/pages/dashboard/components/flight-details/flight.details.scss";
import {inject, observer} from "mobx-react";


const SingleFlight = (props) => {
    if (!props.day) {
        return null;
    }

    return (
        <div className={"cs-flight"}>
            <div className={"cs-deal-row"}>
                <span className={"day"}>{props.direction}:&nbsp;</span>
                <span className={"day"}>יום</span>&nbsp;
                <span className={"day"}>{props.day}'</span>&nbsp;
                <span className={"date"}>{props.date}</span>
            </div>
            <div className={"cs-deal-row small"}>
                <span>המראה: </span>
                <span>{props.departureAirport}</span>&nbsp;בשעה&nbsp;
                <span className={"time"}>{props.departureTime}</span>
            </div>
            <div className={"cs-deal-row small"}>
                <span>נחיתה: </span>
                <span>{props.arrivalAirport}</span>&nbsp;בשעה&nbsp;
                <span className={"time"}>{props.arrivalTime}</span>
            </div>
        </div>
    )
}

@inject("pnrStore","dashboardUIStore") @observer
class FlightDetails extends React.Component {
    render() {
        const s = this.props.pnrStore;

        return (
          <div className={"cs-flight-data"}>
              <SingleFlight
                  direction={"טיסת הלוך"}
                  day={s.flight1DepartureDayShort}
                  date={s.flight1DepartureDate}
                  departureAirport={s.date1DepartureCity}
                  departureTime={s.date1DepartureTime}
                  arrivalAirport={s.date1ArrivalCity}
                  arrivalTime={s.date1ArrivalTime}
                  flightData={s.flight1Data}
              />

              {(s.isTwoWayFlight) &&
                  <SingleFlight
                      direction={"טיסת חזור"}
                      day={s.flight2DepartureDayShort}
                      date={s.flight2DepartureDate}
                      departureAirport={s.date2DepartureCity}
                      departureTime={s.date2DepartureTime}
                      arrivalAirport={s.date2ArrivalCity}
                      arrivalTime={s.date2ArrivalTime}
                      flightData={s.flight2Data}
                  />
              }
              {!s.isTwoWayFlight &&
                  <div className={"cs-flight"}/>
              }


              <div className={"details-col"}>
                <a href={"#"} onClick={(e) => {
                    e.preventDefault();
                    this.props.dashboardUIStore.flightDetailsDlg.openPopup();
                }}>הצג פירוט מלא</a>
              </div>
          </div>
        );
    }
}

export {FlightDetails}