import React from 'react'
import 'app/desktop/ui/components/buttons/button-continue/btn.continue.scss'

class ButtonContinue extends React.Component {
    render() {
        return (
            <div className={"btn continue"} onClick={this.props.onClick}>{this.props.children} </div>
        )
    }
}

ButtonContinue.defaultProps = {
    onClick : () => {}
}

export {ButtonContinue}