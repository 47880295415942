const getActivePNR = () => {
    if (window && window.sessionStorage) {
        return window.sessionStorage.getItem("pnr");
    }

    return null;
}

const getActiveAccessToken = () => {
    if (window && window.sessionStorage) {
        return window.sessionStorage.getItem("access_token");
    }

    return null;
}

const isSessionExists = () => {
    return (getActivePNR() && getActiveAccessToken());
}

const clearActiveSession = () => {
    if (window && window.sessionStorage) {
        window.sessionStorage.removeItem("access_token");
        window.sessionStorage.removeItem("pnr");
        window.sessionStorage.removeItem("agent_access_token");
        window.sessionStorage.removeItem("pnr-source");
        window.sessionStorage.removeItem("encryptedPnr");
    }
}

function clearPnrSession () {
    if (window && window.sessionStorage) {
        window.sessionStorage.removeItem("access_token");
        window.sessionStorage.removeItem("pnr");
        window.sessionStorage.removeItem("pnr-source");
        // window.sessionStorage.removeItem("encryptedPnr");
    }
}

function getAgentAccessToken () {
    if (window && window.sessionStorage) {
        return window.sessionStorage.getItem("agent_access_token");
    }

    return null;
}

function setAgentAccessToken(token) {
    if (window && window.sessionStorage) {
        return window.sessionStorage.setItem("agent_access_token", token);
    }
}
function setAccessToken(token) {
    if (window && window.sessionStorage) {
        return window.sessionStorage.setItem("access_token", token);
    }
}

function setPNRSource(pnrSource) {
    window.sessionStorage.setItem("pnr-source", pnrSource);
}

function getPNRSource() {
    return window.sessionStorage.getItem("pnr-source");
}

export {
    getActiveAccessToken,
    getActivePNR,
    isSessionExists,
    clearActiveSession,
    getAgentAccessToken,
    setAgentAccessToken,
    setAccessToken,
    clearPnrSession,
    setPNRSource,
    getPNRSource
}