import React from 'react'
import {inject, observer} from "mobx-react";
import 'app/desktop/ui/components/dialogs/dlg-seat-selection/dlg.seat.selection.scss';
import classnames from "classnames";
import {Preloader} from "app/desktop/ui/components/preloader/preloader";
import ImportantInfo from "app/desktop/ui/components/important-info/important-info";
import {MainHeader} from "app/desktop/ui/components/dialogs/dlg-seat-selection/components/headers/header.main";
import {Header} from "app/desktop/ui/components/dialogs/dlg-seat-selection/components/headers/header";
import {SeatsContent} from "app/desktop/ui/components/dialogs/dlg-seat-selection/components/seats.content";
import {ScreenWrapper} from "app/desktop/ui/components/dialogs/dlg-seat-selection/components/dlg.seat.popup";


@inject("seatsStore") @observer
class NotAvailableSeats extends React.Component {
    render() {
        const s = this.props.seatsStore;
        const cssSeatsNotAvailable = classnames("seats-taken", {"hide-seats-taken": s.showErrorMessageStatus});

        return (
            <div className={cssSeatsNotAvailable} style={this.props.transform}>
                {this.props.text}
            </div>
        );
    }
}

NotAvailableSeats.defaultProps = {
    transform: {transform: 'translate(-50%, -50%) translateY(-10px)'}
}


@inject("dashboardUIStore", "seatsStore", "pnrStore") @observer
class DlgSeatSelectionWebCheck extends React.Component {

    render() {


        const infoText = "בחירת המושבים נעשית באופן אקראי עבור הנוסעים בהזמנה.\n" +
            "ניתן לבצע שינויים בהושבה בתשלום.\n" +
            "שימו לב! לאחר סיום תהליך הצ'ק-אין לא ניתן לבצע שינויים בהושבה.";

        const allPaidMsg = <div className={"allPaidMsg"}> * שים לב! כבר התבצעה רכישת הושבה ולכן לא ניתן לבצע שינויים בהושבה.</div>;

        const showInfo = this.props.pnrStore.currentFlightAllSeatsPaid ? allPaidMsg :
            <ImportantInfo infoText={infoText}/>;

        return (
            <div>
                <Header/>
                {showInfo}
                <SeatsContent/>
            </div>
        );
    }
}


@inject("dashboardUIStore", "seatsStore", "pnrStore") @observer
class DlgSeatSelection extends React.Component {

    render() {
        const dlg = this.props.dashboardUIStore.seatSelectionDialog;
        const s = this.props.seatsStore;


        if (s.isBooking) {
            return <ScreenWrapper>
                <div className={"popup-loader"}>
                    <Preloader/>
                </div>
            </ScreenWrapper>
        }

        // function find2FirstNotPaid (index) {
        //     return !pnr.seat2IsPaid(index) ? index : s.firstNotPaidReturn }


        if (!s.areSeatsAvailable || !s.currentFlight) {
            return null;
        }


        return (
            <ScreenWrapper>
                <div className={"cs-popup-close"} onClick={() => {
                    dlg.closePopup(); }}/>
                <div className={"dlg-scroll"}>
                    <div className={"dlg-body"}>
                        <MainHeader/>
                        <SeatsContent/>
                    </div>
                </div>
            </ScreenWrapper>
        );
    }
}

export {
    NotAvailableSeats,
    DlgSeatSelectionWebCheck,
    DlgSeatSelection
};