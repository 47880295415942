import React from 'react'
import {inject, observer} from "mobx-react";
import classnames from 'classnames'
import 'app/desktop/ui/components/dialogs/dlg-seat-selection/components/passengers/passengers.scss'
import {truncatedText} from "app/services/validator";

@inject("pnrStore", "passengersStore", "seatsStore", "isWebCheckIn", "checkInUIStore") @observer
class CurrentFlight extends React.Component {

    componentDidMount() {
        if (this.props.isWebCheckIn) {
            this.props.flightIndex === 0 ?
                this.props.seatsStore.setFlight1ActiveIndex(this.props.seatsStore.findFirstNotPaid(this.props.index)) :
                this.props.seatsStore.setFlight2ActiveIndex(this.props.seatsStore.find2FirstNotPaid(this.props.index));
        }
    }

    render() {
        const {passenger, index, orderedSeat, activeIndex, flightIndex, curSymbol, flight} = this.props;
        let css;

        if (orderedSeat) {
            css = classnames("passenger", {"seatPaid": orderedSeat},
                {"allPaid" : this.props.pnrStore.currentFlightAllSeatsPaid});
        } else {
            css = classnames("passenger", {active: activeIndex === index && !orderedSeat});
        }
        const seat = passenger.seats[flightIndex];
        const price = (seat) ? seat.price : 0;

        const direction = this.props.seatsStore.currentDirection;
        const checkOrderedSeats = () => {
            return orderedSeat ? <React.Fragment>
                    <div className={"seat-num"}>{orderedSeat}</div>
                </React.Fragment> :
                seat ? <React.Fragment>
                    <div className={"seat-num"}>{seat.number}</div>
                    <div className={"btn-remove"} onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        //TODO: do cleanups and refactor here
                        if (this.props.isWebCheckIn) {
                            if (this.props.checkInUIStore.randomlyPicked) {
                                this.props.checkInUIStore.setRandomlyPicked(false)
                                this.props.passengersStore.passengersExcludeInfants.forEach(passenger => passenger.clearSeat(this.props.checkInUIStore.curDirection));
                                this.props.seatsStore.setFetchSeatsType("webCheckFetchAfterReplacingRandomSeat");
                                this.props.seatsStore.fetchSeats(true);
                            }
                        }
                        passenger.clearSeat(flightIndex);

                    }}/>
                </React.Fragment> : "";
        }

        return (
            <div className={css} key={"row-" + index} onClick={(e) => {
                e.preventDefault();
                flight.setActive(index);
            }}>
                <div className={"row header"}>
                    <div className={"col c-num"}/>
                    <div className={"col c-name"}>שם הנוסע</div>
                    <div className={"col c-seat"}>{direction}</div>
                    <div className={"col c-price"}>סה״כ</div>
                </div>
                <div className={"row"}>
                    <div className={"col c-num"}>
                        <div className={"p-num num"}>{index + 1}</div>
                    </div>
                    <div className={"col c-name"}>
                        <div className={"grey-box"} title={passenger.fullName}>{truncatedText(passenger.fullName)}</div>
                    </div>

                    <div className={"col c-seat"}>
                        <div className={"grey-box"}>{checkOrderedSeats()}</div>
                    </div>

                    <div className={"col c-price"}>
                        <span className={"num"}>{curSymbol}{price}</span>
                    </div>

                </div>
            </div>
        )
    }
}

@inject("pnrStore", "passengersStore", "isWebCheckIn", "checkInUIStore", "seatsStore") @observer
class Passengers extends React.Component {

    render() {
        const pnr = this.props.pnrStore;
        const chkUI = this.props.checkInUIStore;
        const s = this.props.seatsStore;
        const flight = this.props.flight;
        const {isWebCheckIn} = this.props;
        if (!flight && !isWebCheckIn) {
            return null;
        }
        //show error message if not allthe passengers picked seat in the web check in
        //TODO: DO I need to implement it on mobile?
        if (!s.isBooking && s.seatsData && isWebCheckIn && chkUI.activeScreen === chkUI.availableScreens[2]) {
            chkUI.somePassengerHasNoSeat ?
                s.showErrorMessage(true, "שים לב, יש לבחור הושבה לכלל הנוסעים", true) :
                s.deleteErrorMsg();
            }

        const paidText = pnr.oneDirectionSeatPaid &&
            <div className={"warning-sticky"}>
                <div className={"row header paid-text"}>
                    שים לב! לא ניתן לבצע שינויים לאחר רכישת הושבה.
                </div>
            </div>

        const randomSeat = isWebCheckIn && !chkUI.randomlyPicked &&
            !pnr.currentFlightAllSeatsPaid && <div className={"warning-sticky"}>
            <div className={"row"} onClick={()=> {
                s.setFetchSeatsType('getRandomSeatsFetch');
                chkUI.setRandomlyPicked(true)
                s.fetchSeats()}
            }>
                <span className={"return-icon"}/>
                <a className={"return-text"}>חזור לבחירת המערכת</a>
            </div>
        </div>


        const pStore = this.props.passengersStore;
        let flightIndex = isWebCheckIn? chkUI.curDirection : flight.flightIndex;

        return (
            <div className={"passengers-list"}>
                {pStore.passengersExcludeInfants.map((p, index) =>
                    flightIndex === 0 ? (
                            <CurrentFlight
                                key={'passenger-' + p.id}
                                passenger={p}
                                index={index}
                                orderedSeat={pnr.seat1IsPaid(p.id-1)}
                                activeIndex={flight.activeIndex}
                                flightIndex={flight.flightIndex}
                                flight={flight}
                                curSymbol={pnr.currencySymbol}/>
                        ) :
                        <CurrentFlight
                            key={'passenger-' + p.id}
                            passenger={p}
                            index={index}
                            orderedSeat={pnr.seat2IsPaid(p.id-1)}
                            activeIndex={flight.activeIndex}
                            flightIndex={flight.flightIndex}
                            flight={flight}
                            curSymbol={pnr.currencySymbol}/>
                )}
                {paidText}
                {randomSeat}
            </div>
        )
    }
}

export {Passengers}