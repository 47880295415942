import React from 'react';
import {ConfirmationWebCheck} from "../confirmation/conformation.web.check";

class ConfirmScreen extends React.Component {
    render() {
        return (
            <div className={"chk-baggage"}>
                <ConfirmationWebCheck/>
            </div>
        )
    }
}

export default ConfirmScreen;