import React from 'react';
import 'app/desktop/ui/pages/login.page.scss';
import {inject, observer} from "mobx-react";
import {LoginForm} from "app/desktop/ui/components/login-form/login.form";
import {clearActiveSession} from "app/services/session.service"; 

const Loading = () => {
    return (
        <div className={"preloader"}/>
    )
}
@inject("authStore", "pnrStore") @observer
class LoginPage extends React.Component {

    componentDidMount() {
        const auth = this.props.authStore;
        let { encryptedPnr } = this.props.match?.params;

        if (encryptedPnr) {
            console.log("perform auto login")
            clearActiveSession();
            return auth.performAutoLogin(encryptedPnr);
        }

        const encryptedPnrStorage = window.sessionStorage.getItem("encryptedPnr");
        if (encryptedPnrStorage) {
            encryptedPnr = encryptedPnrStorage;
            auth.encryptedPnr = encryptedPnr;
        }
    }

    render() {
        const auth = this.props.authStore;
        let { encryptedPnr } = this.props.match?.params;

       if (auth.isLoading && encryptedPnr) {
         return(
             <Loading/>
         )
       }


       return (
            <div className={"login-page"}>
                <LoginForm/>
            </div>
        )
   }
}

export {LoginPage}