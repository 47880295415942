import { observable, action } from "mobx";

class AlertsStore {
    @observable isVisible = false;
    @observable message = "";
    @observable type = ""; // 'warning' or 'error'

    constructor(root) {
        this.root = root;
    }

    @action
    showAlert(message, type) {
        this.message = message;
        this.type = type;
        this.isVisible = true;
    }

    @action
    hideAlert() {
        this.isVisible = false;
    }
}

export {AlertsStore};
