import {inject, observer} from "mobx-react";
import React from "react";
import {TxtFieldSmall} from "app/desktop/ui/components/txt-field-small/txt.field.small";
import {Dropdown} from "app/desktop/ui/components/dropdown-with-label/dropdown.with.label";

@inject('paymentStore', 'isWebCheckIn') @observer
class PaymentPageContent extends React.Component {

    render() {

        const p = this.props.paymentStore;

        return (
            <div className={
                "payment-page"
            }

            >
                {/*TODO: bring the commented code back when we need to get rid of payer details due to kv-29 task*/}
                {/*{this.props.isWebCheckIn &&*/}
                    <div className={"payment-form"}>
                        <div className={"row"}>
                            <div className={"col col-1"}>
                                <label>שם מלא (שם פרטי ושם משפחה באנגלית)</label>
                                <TxtFieldSmall value={p.fullName}
                                               onChange={(e) => {
                                                   p.setFullName(e.target.value);
                                               }}
                                               isError={!p.isFullNameValid}
                                               autoComplete={"cc-name"}
                                               isEng={true}
                                />
                            </div>

                            <div className={"col col-2"}>
                                <label>מס' הטלפון</label>
                                <TxtFieldSmall value={p.phone}
                                               type={"number"}
                                               onChange={(e) => {
                                                   p.setPhone(e.target.value);
                                               }}
                                               isError={!p.isPhoneValid}
                                               autoComplete={"tel"}
                                               isEng={true}
                                               placeholder={""}
                                               maxLength={12}
                                />
                            </div>
                        </div>

                        <div className={"row"}>
                            <div className={"col col-1"}>
                                <label>כתובת אי-מייל</label>
                                <TxtFieldSmall value={p.email}
                                               onChange={(e) => {
                                                   p.setEmail(e.target.value);
                                               }}
                                               isError={!p.isEmailValid}
                                               autoComplete={"email"}
                                               type={"email"}
                                               isEng={true}
                                />
                            </div>

                            <div className={"col col-2"}>
                                <label>סוג התשלום</label>
                                <Dropdown selectedText={p.selectedPaymentOptionName}
                                          options={p.paymentOptions}
                                          onSelect={(o) => {
                                              // console.log("o", o);
                                              p.setPaymentOption(o);
                                          }}
                                />
                            </div>
                        </div>
                    </div>
                {/*}*/}
            </div>
        )
    }
}

export {PaymentPageContent};