import React from 'react'
import {inject, observer} from "mobx-react";
import {Popup} from "app/desktop/ui/components/popup/popup";
import 'app/desktop/ui/components/dialogs/dlg-payment/dlg.payment.scss';
import {PageSuccess} from "app/desktop/ui/pages/page-success/page.success";
import {history} from "app/services/custom.router";
import {LoadError} from "app/desktop/ui/components/dialogs/dlg-payment/load.error";
import {ButtonContinue} from "app/desktop/ui/components/buttons/button-continue/button.continue";
import iframeOnLoad from "../../../../../services/iframe.service";

export class Loader extends React.Component {
    render() {
        return (
            <div className={"ui-loader"}/>
        )
    }
}

@inject("paymentStore") @observer
class Helper extends React.Component {

    componentDidMount() {
        this.timer = setTimeout(() => {
            this.props.paymentStore.setHelperVisibility(false);
        }, 15000);
    }

    componentWillUnmount() {
        clearTimeout(this.timer);  // Clear timer if component is unmounted prematurely
    }

    render() {
        const pay = this.props.paymentStore;
        if (pay.iframeResult === 'success') {
            return null;
        }

        if (!pay.isHelperVisible) {
            return <div className={"hidden"}/>
        }
        return <div className={"helper"}>אם אינך רואה את מסך התשלום, <a href={"#"} onClick={(e) => {
            e.preventDefault();
            pay.sendPaymentStatus("cancelledByRefresh");
            pay.iframeResult = "cancelledByRefresh";
            pay.performPayment();
        }}>לחץ כאן</a></div>
    }
}

@inject("paymentStore", "isWebCheckIn", "checkInUIStore") @observer
class PaymentScreen extends React.Component {

    constructor(props) {
        super(props);

        this.myRef = React.createRef();
    }

    render() {
        const pay = this.props.paymentStore;
        const chk = this.props.checkInUIStore;
        const {isWebCheckIn} = this.props;
        if (!pay.paymentUrl) {
            return null;
        }

        if (pay.iframeResult === 'success') {
            if (isWebCheckIn) {
                chk.setLastScreen();
                return <Loader/>;
            } else {
                return <PageSuccess/>
            }
        }

        const style = {
            "border": "none",
            "width": "100%",
            "minHeight": "600px"
        }
        return pay.iframeResult !== "success" && <iframe ref={this.myRef} style={style} src={pay.paymentUrl} onLoad={() => {iframeOnLoad(pay, this.myRef)}}/>;
    }
}


@inject("dashboardUIStore", "paymentStore", "baggageStore", "isWebCheckIn", "authStore") @observer
class PaymentPopup extends React.Component {

    render() {
        const dlg = this.props.dashboardUIStore.paymentDlg;
        const pay = this.props.paymentStore;
        const bg = this.props.baggageStore;
        const auth = this.props.authStore;
        const s = this.props.dashboardUIStore;


        const detailsButton = this.props.paymentStore.iframeResult === 'success' &&
            <div className={"bottom-left-button"}>
            <ButtonContinue onClick={(e) => {
                //  this.props.pnrStore.loadData(getActivePNR());
                //TODO: check why do we only fetch baggage
                this.props.baggageStore.fetchBaggageOptions();

                dlg.closePopup();
                s.setStep(0);
                history.push('/dashboard');

            }}>הקלק לפרטי הזמנה</ButtonContinue>
        </div>

        return (
            <Popup isOpen={dlg.isOpen} onClose={() => {
                dlg.closePopup();
                if ( pay.disablePayment) {
                    pay.disablePaymentGlobal = true;
                }
                pay.disablePayment = false;
                pay.errMsg = null;
                if (pay.shouldLogOut) {
                    auth.logout();
                    window.location.reload();
                }
                if (this.props.paymentStore.iframeResult === 'success') {
                    // this.props.pnrStore.loadData(getActivePNR());
                    bg.fetchBaggageOptions();
                    this.props.dashboardUIStore.setStep(0);
                    history.push('/dashboard');
                } else {
                    pay.sendPaymentStatus("iframeClosed");
                }
            }}>{this.props.children}
                {detailsButton}
            </Popup>
        )
    }

}

@inject("dashboardUIStore", "paymentStore","isWebCheckIn") @observer
class Wrapper extends React.Component {
    render() {
        const {isWebCheckIn} = this.props;

        return (
            isWebCheckIn ? <div>{this.props.children}</div> : <PaymentPopup>{this.props.children}</PaymentPopup>
    )}
}


@inject("dashboardUIStore", "paymentStore", "pnrStore", "baggageStore", "authStore") @observer
class DlgPayment extends React.Component {
    render() {
        const ps = this.props.paymentStore;

        let body = "";

        if (ps.isPaymentInProcess) {
            body = <Loader/>
        } else {
            if (ps.showError) {
                body = <LoadError/>
            } else {
                    body = <PaymentScreen/>
            }
        }

        let closeWarning = !ps.showError && <span className={"dlg-payment-header-warning"}>נא לא לסגור את חלונית התשלום!</span>;
        let header = ps.iframeResult !== 'success' && <div className={"dlg-checkin-header"}>דף תשלום מאובטח {closeWarning}</div>

        const alert = (ps.showHelper) ? <Helper/> : "";

        return (
            <Wrapper>
                <div className={"dlg-body"}>
                    {header}
                    <div className={"dlg-payment-body"}>
                        {alert}
                        {body}
                    </div>
                </div>
            </Wrapper>
        )
    }
}

export {DlgPayment};