import {ModalWarning} from "app/desktop/ui/components/modal-warning/modal.warning";
import React from "react";
import {inject, observer} from "mobx-react";


@inject ("dashboardUIStore", "checkInUIStore", "paymentStore") @observer
class TicketModal extends React.Component {
    render() {
        const dlg = this.props.dashboardUIStore.ticketCheckIn;
        const chk = this.props.checkInUIStore;
        const pay = this.props.paymentStore;

        const ticketImg = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMwAAACCCAMAAAA9rtfcAAAABGdBTUEAALGPC/" +
            "xhBQAAAAFzUkdCAK7OHOkAAABvUExURUdwTGZ4pOHh4eDg4D4+Pl9fX7W1td7e3oiIiBkZGaampkVFRdTU1MDF0d/f36auw9/f397e397e3tPW2h88gP///" +
            "+Xl5uvr67C7077G2/Hy83OFr/r6+4uavChEhdPZ556qyFtwojJNiz5Xkk1kmqoVaDsAAAAUdFJOUwD+K" +
            "DooKCiZKCkLGBfTaPVRfoimXaJYagAABcJJREFUeNrtnIt6q6wShsUmaVdqDm1BUM7i/V/jP6CxmkOje3VlGx8+NWkT0Hm" +
            "dYUCNJklQtv98nYs+jlnSKR1h2Oc+TXo1drKu8FxU1fKrte64407dr+D47uXEspcKz0vqcAS7tl9odAX+0bIgPD9JoPmqJ1SwgSaVeI46ZHs7qQL3kbZTs4" +
            "Spvg4TQ3MHeYzjecrYiRV4muzrmcLYqRHj9slnNVOYyXapz+QVL0avESbCRJipMIYtRAZgKFmIaISJMBFmKTBlnosIE2GeGqYUouB1uQSYosaKEcLl8OOczhim4sFyVZ" +
            "yzKFkgrIn03zvbfY6bPzXPAcyKh8BoqcfBYORfGT6Dyb1XBDaEI1MSac5hUAUeK81DPKPhAFmPhNFMexgNOGFhBayTe5s9jGbWCcNKk0smjNQNTKmKShDBSJEzA7TEL4xqY3Qg1A" +
            "U1Jv8lGH82SY6DqRTGDGCsgv2tiMRW1YKEsCsw93vEaVVrKIdq7FQDYzhxjOSYcIdkzWDnGQ+IjFG6gqrWVIaZX4Th42AcparuYHLYBwyzHPtChZFOEsGJh6lLYCOtZ1xBpA0wEKUS" +
            "EQOtioVvkIFWRpVW+a+F2QQYsKZWHQzDVVVhk+M2I5eV1iLABMi2zWgFR0aYehjp/xO2UAIZYqytjFYl8JgK6WkworzRC6DxMGCccwCDsACqAhtKKRFddoNoIh0MxFKA4VZKcFoLA2Uq" +
            "YpkqDXRK3ECMgeOIkO6XMsMEz2CEsAQYiZHFqqycYZwShJri3IkOplSKOw9ThuTC3AlGVt4TlsiaFpUhrHKQAUr2eBjEeC2FtrqEd4ZIzuuaC2gbBWUy5GW/OkkteEhba" +
            "6SPq7Dq0mpoLN5xuS0IhUXwmsMHAtI25fXUMLup8WF2S/KiGx3fMatfHSWU/tz41VHV+OGMUfx/3LUS/SZL0ZyCroq/Gmhq6ZyVmjxKJaWXqVl8XxiT4skPAXT/8kutn" +
            "xrGDK/YKPO8MOXldWRUPimMbls+96FmeeOkSj8nTGj6lom20xQsXPKUzwoDA6bBCCCX7llhiuLKCKAofoAR+SiV/59sNnFsFmEeDDN+oFnSUdMDYG4enPG/HjXP" +
            "SIuCQQuCCSMBpxcB0w42lVkAjPn+BdczwYhrB2dhUKOFPhvI3IChOaX3lyZp3i7xz/oZoUNjgS+0vg8zrtOkd8r+007z9pmTRcPQUSrvlJ0JzLxPaESYCBNhIszzwcDB2XJ" +
            "gpivCRJgHwbBiIWLx5/MRJsJEmKXAfMz1Bjo3+Y67j+RoZwrDp9LUx2R7mCnMYerdsDJLkk83S5Z6P/E+5erVP9dgljc3V7uJd5CrQ3gkQnpQM2QB045yCkv7eINsN7MkoNA" +
            "u7OaXw9gmUO++nzxx3EmO5iIud8fWru3HGMOgwn7be4pGsk1f5qJ0YFh237A0S6KioqKioqKioqKioqKioqKioqKioqKioqKiov5eWz81evfzu58vtWrn0/s1hbphNUHJ9h" +
            "9Z3CjbZqA3P3Va+6mn1Xrl50Z/Vn/uCco0CvV8/U6w5p7CdkFgRcM6xfosaLNJYfJz0Nvmzc9huUITzOmh3MNZDbTuozTrPW2k3XCYGqWNYY2V19k8QjrQqVZfzbp7LH3vrA" +
            "ae+YkmfLc6+abxaYfToPR80wMJ9gS7hvJYHUh6oU16YkmHrjm9hg2exdi6h3IvyFZ/Bq5ZnUjWF47xKB1NEy1XeLw8UI/l5cwz3yitXzZvtz3Tp7nnmj5OU/MszIY8A5oGZX" +
            "NhcftrgFsxlp5F2KDF3AAZlQBW3+3/jGQAchFmm+thFiJte9lwsjTrEQ0aTY9nfema1boDatyy+omlLXIFaMizGUZaOoyzLG2ywJic3Oa1bNOurJ/M1mfZbOiaUxu/zTIAG" +
            "bpm6Bm/1VbZ5pTCJmXon7ud0Edu3zPo797CtH5f+3ndS88/h9owL6+b+mFVfs5CJ/rebW28gf8BDsSca3VaoCMAAAAASUVORK5CYII=";

        return (
            <div className={"add-baggage-wrap"}>
                <ModalWarning
                    title={"ticket"}
                    isOpen={dlg.isOpen}
                    onClose={() => {
                        dlg.closePopup()
                    }}
                    image={ticketImg}
                    buttonText1="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;בצע צ'ק אין &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                    buttonText2="חזור"
                    extraClassName={"ticket-text-red"}
                    dashboardStore={dlg}
                    initPayment={()=> chk.isPaymentScreen? chk.navigateNextAndInitiatePayment() : pay.performPayment()}
                >
                    <p><strong>שימו לב,</strong>  עם סיום תהליך הצ&apos;ק אין, לא<br/> ניתן יהיה לבצע שינויים בכבודה ובהושבה,<br/> אלא בשדה התעופה בלבד.</p>
                </ModalWarning>
            </div>
        )
    }
}

export {TicketModal};