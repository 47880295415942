import {inject, observer} from "mobx-react";
import React from "react";
import {ModalSendMessage} from "app/desktop/ui/components/modal-send-message/modal.send.message";


@inject("baggageStore", "checkInUIStore", "checkInPopup", "seatsStore", "dashboardUIStore", "paymentStore") @observer
class SendRecipeForm extends React.Component {
    render() {

        const chk = this.props.checkInUIStore;
        const pay = this.props.paymentStore;
        const {sentRecipeDialog} = this.props.dashboardUIStore;


        return (
            <ModalSendMessage
                type={pay.recipe.type}
                inputValue={pay.recipe.inputValue}
                performSendMessage={() => pay.performSendRecipe()}
                handleSentMessage={() => {
                    pay.setRecipeMessageDetails();
                    sentRecipeDialog.closePopup();
                }}
                label={pay.recipe.label}
                updateValue={value => pay.setRecipientValue(value)}
                //error handler
                isOpen={sentRecipeDialog.isOpen}
                closePopup={() => sentRecipeDialog.closePopup()}
                boardingPassLoading={chk.boardingPassLoading}
                title={pay.recipe.title}
                btnText={pay.recipe.btnText}
                sendAgain={"לא קיבלתי, שילחו אלי את האישור שוב"}
            />
        )
    }
}

export {SendRecipeForm};