import {observable, action, computed} from "mobx";

const singleStep = (num, title, type, path, active, marked) => {
    return {
        num : num,
        title : title,
        type : type,
        path : path,
        active : active,
        marked : marked
    }
}

const StepTypes = {
    "INFORMATION" : "INFORMATION",
    "BAGGAGE" : "BAGGAGE",
    "SEATS" : "SEATS",
    "EXTRA_SERVICES" : "EXTRA_SERVICES",
    "PAYMENT" : "PAYMENT"
}

const stepIndex = (type) => {
    return Object.keys(StepTypes).indexOf(type);
};


class StepsStore {

    @observable currentStep = StepTypes.INFORMATION;
    constructor(root) {
        this.root = root;
    }

    @action setStep (step) {
        this.currentStep = step;
    }

    @computed get currentStepIndex() {
        let c = 0;

        this.allSteps.forEach((step, index) => {
            if (step.type === this.currentStep) {
                c = index;
            }
        });

        return c;
    }

    @computed get nextStep () {
        const availableSteps = this.allSteps;
        let nextStep = null;
        availableSteps.forEach((step, index) => {
            if (step.type === this.currentStep) {
                if (index+1 < availableSteps.length) {
                    nextStep = availableSteps[index+1];
                }
            }
        });

        return nextStep;
    }

    @computed get previousStep () {
        const availableSteps = this.allSteps;
        let prevStep = null;
        availableSteps.forEach((step, index) => {
            if (step.type === this.currentStep) {
                if (index-1 >= 0) {
                    prevStep = availableSteps[index-1];
                }
            }
        });

        return prevStep;
    }

    @computed get allSteps () {
        const a = [];
        const baggageStore = this.root.baggageStore;
        const seatsStore = this.root.seatsStore;
        const extraServicesStore = this.root.extraServicesStore;
        let ind = 0;

        a.push(singleStep(
            ind,
            "פירוט הזמנה",
            StepTypes.INFORMATION,
            "/dashboard",
            this.currentStep === StepTypes.INFORMATION
        ));
        ind ++;

        if (baggageStore.isBaggageAvailable) {
            a.push(singleStep(
                ind,
                "הוספת\n כבודה",
                StepTypes.BAGGAGE,
                "/dashboard/baggage",
                this.currentStep === StepTypes.BAGGAGE,
                stepIndex(this.currentStep) > stepIndex("BAGGAGE")
            ));
            ind ++;
        }

        if (seatsStore.areSeatsAvailable) {
            a.push(singleStep(
                ind,
                "הוספת\n הושבה",
                StepTypes.SEATS,
                "/dashboard/seats",
                this.currentStep === StepTypes.SEATS,
                stepIndex(this.currentStep) > stepIndex("SEATS")
            ));
            ind ++;
        }

        if (extraServicesStore.areExtraServicesAvailable) {
            a.push(singleStep(
                ind,
                "שירותים\n נוספים",
                StepTypes.EXTRA_SERVICES,
                "/dashboard/extra-services",
                this.currentStep === StepTypes.EXTRA_SERVICES,
                stepIndex(this.currentStep) > stepIndex("EXTRA_SERVICES")
            ));
            ind++;
        }

        if (baggageStore.isBaggageAvailable || seatsStore.areSeatsAvailable || extraServicesStore.areExtraServicesAvailable) {
            a.push(singleStep(
                ind,
                "תשלום",
                StepTypes.PAYMENT,
                "/dashboard/payment",
                this.currentStep === StepTypes.PAYMENT
            ));
            ind ++;
        }

        return a;
    }
}

export {StepTypes, StepsStore}