import {AuthStore} from "./auth.store";
import Transport from '../services/transport'
import {PNRStore} from "./pnr.store";
import {PassengersStore} from "app/stores/passengers.store";
import {AuthUIStore} from "app/stores/auth.ui.store";
import {DashboardUIStore} from "app/stores/dashboard.ui.store";
import {SidebarStore} from "app/stores/sidebar.store";
import {BaggageStore} from "app/stores/baggage.store";
import {PaymentStore} from "app/stores/payment.store";
import {HotelStore} from "app/stores/hotel.store";
import {SeatsStore} from "app/stores/seats.store";
import {ExtraServicesStore} from "app/stores/extra-services.store";
import {StepsStore} from "app/stores/steps.store";
import {PasswordResetStore} from "app/stores/password.reset.store";
import {PopupStore} from "./popup.store";
import CheckInUIStore from "./checkin.ui.store";
import PassengerDetailsStore from "./passenger.details.store";
import {AlertsStore} from "app/stores/alerts.store";

class RootStore {
    constructor() {
        this.isWebCheckIn = false;
        this.authStore =  new AuthStore(this, Transport);
        this.passengersStore = new PassengersStore(this);


        this.authUIStore = new AuthUIStore(this);
        this.dashboardUIStore = new DashboardUIStore(this);

        this.pnrStore = new PNRStore(this, Transport, this.authStore);
        this.sidebarStore = new SidebarStore(this);
        this.baggageStore = new BaggageStore(this, Transport);
        this.extraServicesStore = new ExtraServicesStore(this);

        this.paymentStore = new PaymentStore(this, Transport);

        this.hotelStore = new HotelStore(this);
        this.seatsStore = new SeatsStore(this, Transport);
        this.stepsStore = new StepsStore(this);

        this.passwordResetStore  = new PasswordResetStore(this, Transport);

        this.checkInPopup = new PopupStore();
        this.passengerDetailsStore = new PassengerDetailsStore(this);
        this.alertsStore = new AlertsStore(this);

        this.checkInUIStore = new CheckInUIStore(this, Transport);

    }
}


const allStores = new RootStore();

export default allStores;