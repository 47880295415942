import {inject, observer} from "mobx-react";
import React from "react";
import {ModalDialog} from "app/desktop/ui/components/modal/modal";
import 'app/desktop/ui/components/dialogs/dlg-baggage-details/dlg.baggage.details.scss';
import {RegularButtonSmall} from "app/desktop/ui/components/buttons/regular-button/regular.button";
import {history} from 'app/services/custom.router'
import {StepTypes} from "app/stores/steps.store";

@inject ("dashboardUIStore", "stepsStore", "baggageStore") @observer
class DlgBaggageDetails extends React.Component {
    render() {
        const dlg = this.props.dashboardUIStore.baggageDetailsDialog;
        const s = this.props.baggageStore;
        // style={{"left": dlg.x, "top": dlg.y}}

        const handleBtnClick = (e) => {
            e.preventDefault();
            dlg.closePopup();

            this.props.stepsStore.setStep(StepTypes.BAGGAGE);
            history.push('/dashboard/baggage');
        }
        return (
            <ModalDialog isOpen={dlg.isOpen} modal={false} onClose={() => {
                dlg.closePopup();
            }}>
                <div className={"dlg-baggage-details"} >
                    <div className={"wrap"}>
                        <div className={"row header"}>
                            פירוט כבודה

                            <div className={"btn-popup-close"} onClick={(e) => {e.preventDefault();dlg.closePopup();}}/>
                        </div>
                        <div className={"row body"}>
                            <div className={"row table-header"}>
                                <div className={"col name"}>
                                    שם הנוסע
                                </div>

                                <div className={"col dir"}>
                                    כיוון
                                </div>

                                <div className={"col count"}>
                                    כמות/סוג כבודה
                                </div>

                                <div className={"col price"}>
                                    סה״כ
                                </div>
                            </div>

                            {s.totalTable.map((p,index) => {
                                return (
                                    <React.Fragment key={"p"+index}>
                                    {(p.selectedBaggageCount > 0) ?
                                        <div className={"row"}>
                                            <div className={"col name"}>
                                                <span className={"num"}>{index + 1}.</span> {p.fullName}
                                            </div>

                                            <div className={"col dir"}>
                                                {p.selectedBaggageDirection}
                                            </div>

                                            <div className={"col count"}>
                                                <span className={"num"}>{p.selectedBaggageCount}</span> מזוודות לבטן
                                                המטוס
                                            </div>

                                            <div className={"col price"}>
                                                {s.currency}{p.selectedBaggagePrice}
                                            </div>

                                        </div>
                                        : ""
                                    }

                                        {(p.selectedTrolleyCount > 0) ?
                                            <div className={"row"} key={"p" + index}>
                                                <div className={"col name"}>
                                                    <span className={"num"}>{index + 1}.</span> {p.fullName}
                                                </div>

                                                <div className={"col dir"}>
                                                    {p.selectedBaggageDirection}
                                                </div>

                                                <div className={"col count"}>
                                                    <span className={"num"}>{p.selectedTrolleyCount}</span> טרולי
                                                </div>

                                                <div className={"col price"}>
                                                    {s.currency}{p.selectedTrolleyPrice}
                                                </div>

                                            </div>
                                            : ""
                                        }
                                    </React.Fragment>

                            )
                            })}
                        </div>
                        <div className={"row footer"}>
                            <div className={"col-1"}>
                                <RegularButtonSmall onClick={handleBtnClick}>ערוך / הסר כבודה</RegularButtonSmall>
                            </div>
                            <div className={"col-2"}>
                                מחיר כבודה כולל <span className={"price"}>{s.currency}{s.totalBaggagePrice}</span>
                            </div>
                        </div>
                    </div>
                </div>

            </ModalDialog>
        );
    }
}

export {DlgBaggageDetails}