import React from "react";
import {inject, observer} from "mobx-react";
import {DealStatus} from "app/desktop/ui/components/deal-status/deal.status";

@inject("dashboardUIStore","hotelStore", "pnrStore") @observer
class DlgHotelDetailsInner extends React.Component {
    render() {
        const s = this.props.hotelStore;
        if (!s.isHotelData) {
            return null;
        }

        const remark = (s.hotelRemark) ? <div className={"remark"}>הערות:  <br/> {s.hotelRemark}</div> : "";

        return (
            <div className={"dlg-content dlg-hotel-details"}>
                <div className={"row"}>
                    <div className={"col-1"}>
                        <div className={"large-title hotel-name"}><span>{s.hotelName}</span></div>

                        <div className={"hotel-stars"}>
                            <div className={"wrap"}>
                                {s.starsArray.map((star, index) => {
                                    return <div className={"icon star " + star} key={'star'+ index}/>
                                })}
                            </div>
                        </div>
                    </div>

                    <div className={"col-2 main-status"}>
                        <DealStatus {...this.props.pnrStore.hotelsStatusData}/>
                    </div>

                    <div className={"col-3"}>
                        {remark}
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"dlg-block"}>
                        <div className={"icon-marker"}/>

                        <div className={"hotel-dates"}>
                            <span>כניסה: יום</span>&nbsp;
                            <span>{s.day1Short}’</span>&nbsp;
                            <span className={"date"}>{s.checkInDate}</span>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span>יציאה: יום</span>&nbsp;
                            <span>{s.day2Short}’</span>&nbsp;
                            <span className={"date"}>{s.checkOutDate}</span>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span>לילות: </span>&nbsp;
                            <span className={"date"}>{s.numberOfNights}</span>
                        </div>
                    </div>
                </div>

                {s.fullRooms.map((room, ind) => {
                    console.log("room!",room);
                    return (
                        <div key={"room-"+ind} className={"dlg-block"}>
                            <div className={"icon-hotel"}/>
                            <div className={"row room-header"}>
                                חדר מספר <span>&nbsp;{ind+1}</span>
                                <div className={"large-title"}>
                                    <DealStatus {...this.props.pnrStore.hotelStatusData(ind)}/>
                                </div>
                            </div>

                            <div className={"row header"}>
                                <div className={"col-board-base"}>בסיס אירוח</div>
                                <div className={"col-type"}>סוג חדר</div>
                            </div>
                            <div className={"row"}>
                                <div className={"col-board-base"}>{room.boardBase}</div>
                                <div className={"col-type"}>{room.roomType}</div>
                            </div>
                            <div className={"row header names"}>
                                <div className={"col-name"}>שמות הנוסעים</div>
                            </div>
                            <div className={"row no-flex"}>
                                {room.passengers.map((p, pind) => {
                                    return <div key={"p"+pind} className={"p-name"}><span>{pind+1}.</span> &nbsp;{p}</div>
                                })}
                            </div>
                        </div>
                    )
                })}




            </div>
        )
    }
}

export {DlgHotelDetailsInner}