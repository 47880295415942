import React from 'react';
import {DlgPayment} from "app/desktop/ui/components/dialogs/dlg-payment/dlg.payment";

class IframeScreen extends React.Component {
    render() {
        return (
            <div>
                <DlgPayment/>
            </div>
        )
    }
}

export default IframeScreen;