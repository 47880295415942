import React from 'react';
import './App.css';
import {inject, observer} from 'mobx-react'
import {LoginPage} from "app/desktop/ui/pages/login";

import 'app/desktop/styles/app.scss';
import 'app/desktop/styles/elements.scss';

import {history} from 'app/services/custom.router'

import {
     Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import {Dashboard} from "app/desktop/ui/pages/dashboard/dashboard";
import {DlgFlightDetails} from "app/desktop/ui/components/dialogs/dlg-flight-details/dlg.flight.details";
import {DlgPayment, Loader} from "app/desktop/ui/components/dialogs/dlg-payment/dlg.payment";
import {PageSuccess} from "app/desktop/ui/pages/page-success/page.success";
import {PageError} from "app/desktop/ui/pages/page-error/page.error";
import {PageCancel} from "app/desktop/ui/pages/page-cancel/page.cancel";
import {DlgAddBaggage} from "app/desktop/ui/components/dialogs/dlg-add-baggage/dlg.add.baggage";
import {DlgSeatSelection} from "app/desktop/ui/components/dialogs/dlg-seat-selection/dlg.seat.selection";
import {DlgHotelDetails} from "app/desktop/ui/components/dialogs/dlg-hotel-details/dlg.hotel.details";
import {DlgAddSeats} from "app/desktop/ui/components/dialogs/dlg-add-seats/dlg.add.seats";
import {DlgBaggageDetails} from "app/desktop/ui/components/dialogs/dlg-baggage-details/dlg.baggage.details";
import {DlgExtraServices} from "app/desktop/ui/components/dialogs/dlg-extra-services/dlg.extra.services";
import {DlgExtraServicesDetails} from "app/desktop/ui/components/dialogs/dlg-extra-services-details/dlg.extra.services.details";
import {DlgSeatsDetails} from "app/desktop/ui/components/dialogs/dlg-seats-details/dlg.seats.details";
import {DlgSeatsBooking} from "app/desktop/ui/components/dialogs/dlg-seat-booking/dlg.seats.booking";
import {DlgRestrictedSeats} from "app/desktop/ui/components/dialogs/dlg-restricted-seats/dlg.restricted.seats";
import {AgentLogin} from "app/desktop/ui/pages/agent/agent.login";
import {SearchPage} from "app/desktop/ui/pages/search/search.page";
import {PasswordResetPage} from "app/desktop/ui/pages/password-reset/password.reset.page";
import {PasswordChangePage} from "app/desktop/ui/pages/change-password/password.change.page";
import {MaintainPage} from "app/desktop/ui/pages/page-maintain/page.maintain";
import WebcheckinModal from "app/desktop/ui/pages/web-check-in/webcheckin.modal";
import PdfPage from "app/desktop/checkin/confirmation/PdfPage";
import {ClosePopupModal} from "app/desktop/ui/components/modal-close-popup/modal.close.popup";
import {eventEmitter} from "app/services/data.service";
import { parseAndSetDataFromRedirect } from 'app/services/redirect.service';
import {SendBoardingPassForm} from "app/desktop/ui/components/modal-send-message/send.boarding.pass.form";
import {SendRecipeForm} from "app/desktop/ui/components/modal-send-message/send.recipe.form";


console.log("load desktop");
@inject ("authStore", "pnrStore") @observer
class App extends React.Component {
    componentDidMount() {
        eventEmitter.on('unauthorized', this.handleUnauthorized);
    }

    componentWillUnmount() {
        eventEmitter.off('unauthorized', this.handleUnauthorized);
    }

    handleUnauthorized = () => {
        this.props.authStore.logout();
    };

    render() {
        const authStore = this.props.authStore;

        return (
            <React.Fragment>
            <Router history={history}>

                <Switch>
                    <Route
                        exact
                        path="/"
                        render={() => {
                            return (
                                    <Redirect to="/login" />
                            )
                        }}
                    />
                    <Route
                        path="/rd-login/:redirectData"
                        render={(props) => {
                            
                            parseAndSetDataFromRedirect(authStore, props);

                            return <Redirect
                            to={{
                              pathname: "/login",
                            }}
                          />
                        }}
                    >
                    </Route>

                    <Route
                        path="/login/:encryptedPnr?"
                        render={(props) => <LoginPage {...props}/>}
                    >
                    </Route>

                   

                    <Route path="/maintenance">
                        <MaintainPage/>
                    </Route>

                    <Route path="/dashboard">
                        <Dashboard/>
                    </Route>

                    <Route path={"/final/success"}>
                        <PageSuccess/>
                    </Route>

                    <Route path={"/final/error"}>
                        <PageError/>
                    </Route>

                    <Route path={"/final/cancel"}>
                        <PageCancel/>
                    </Route>

                    <Route path={'/agent'}>
                        <AgentLogin/>
                    </Route>

                    <Route path={'/search'}>
                        <SearchPage/>
                    </Route>

                    <Route path={"/password-reset"}>
                        <PasswordResetPage/>
                    </Route>

                    <Route path={"/SetPassword"}>
                        <PasswordChangePage/>
                    </Route>

                    <Route path={`/boarding/:encryptedPnr`}>
                        <PdfPage/>
                    </Route>

                    <Route path={`/booking/loading`}>
                        <Loader/>
                    </Route>




                </Switch>
            </Router>
                <DlgFlightDetails/>
                <DlgPayment/>
                <DlgAddBaggage/>
                <DlgAddSeats/>
                <DlgSeatSelection/>
                <DlgHotelDetails/>
                <DlgBaggageDetails/>
                <DlgSeatsDetails/>
                <DlgSeatsBooking/>
                <DlgRestrictedSeats/>
                <DlgExtraServices />
                <DlgExtraServicesDetails />
                <WebcheckinModal/>
                <SendBoardingPassForm/>
                <SendRecipeForm/>
                <ClosePopupModal/>
            </React.Fragment>

        );
    }
}

export default App;
