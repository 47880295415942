import React from "react";
import {TxtField} from "app/desktop/ui/components/txt-field/txt.field";
import {MainButton} from "app/desktop/ui/components/buttons/main-button/main.button";
import {inject, observer} from "mobx-react";
import {Preloader} from "app/desktop/ui/components/preloader/preloader";



@inject("passwordResetStore") @observer
class ErrorMessage extends React.Component {
    render() {
        const {passwordResetStore} = this.props;

        return (passwordResetStore.error) ? <div className={"error"}>{passwordResetStore.error}</div> : "";
    }
}

const SuccessMesssage = (props) => {
    return <div className={"success"}>בקשה לאיפוס סיסמא נשלחה בהצלחה.<br/>נא לפעול לפי ההוראות במייל.</div>;
}

@inject("passwordResetStore") @observer
class PasswordResetPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleSubmit(event) {
        event.preventDefault();
        if (this.props.passwordResetStore.isLoading) {
            console.log("already loading...")
        } else {
            this.props.passwordResetStore.performPasswordReset();
        }

    }

    render() {

        const {passwordResetStore} = this.props;
        let status = (passwordResetStore.isLoading) ? <Preloader/> : (passwordResetStore.error) ? <ErrorMessage/>  : "";

        if (passwordResetStore.isSuccess) {
            status = <SuccessMesssage/>
        }

        return (
            <div className={"login-page"}>
                <div className={"cs-login-form"}>
                    <div className={"header-image"}/>
                    <div className={"form-body"}>
                        <div className={"form-header"}>
                            <div className={"line-1"}>שחזור סיסמא</div>
                            <p>שכחתם את סיסמתכם? הזינו את כתובת הדואר האלקטרוני שלכם והמתינו לקבלת אימייל עם לינק להכנסת סיסמא חדשה</p>
                            <form action={"#"} onSubmit={this.handleSubmit}>
                                <div className={"rows"}>
                                    <div className={"row"}>
                                        <TxtField placeholder={"כתובת הדואר אלקטרוני"}
                                                  value={passwordResetStore.email}
                                                  isError={passwordResetStore.error}
                                                  disabled={passwordResetStore.isLoading || passwordResetStore.isSuccess}
                                                  maxLength={64}
                                                  type={"email"}
                                                  tooltip = {"כתובת הדואר אלקטרוני"}
                                                  name={"agent-email"}
                                                  onChange = {(e) => {
                                                      passwordResetStore.setEmail(e.target.value);
                                                  }}/>
                                    </div>


                                    <div className={"row"}>
                                        {status}
                                    </div>

                                    <div className={"row"}>
                                        <div className={"button-placeholder"}>
                                            <MainButton disabled={passwordResetStore.isSuccess || passwordResetStore.isLoading} onClick={() => {passwordResetStore.performPasswordReset();}}>שליחה</MainButton>
                                        </div>
                                    </div>
                                </div>

                                <input type={"submit"} style={{"visibility":"hidden", "display":"none"}}/>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export {PasswordResetPage}