import React from 'react'
import {BaggageTableWebCheck} from "app/desktop/ui/pages/dashboard/components/baggage-table/baggage.table";
import {inject, observer} from "mobx-react";

@inject("baggageStore") @observer
class BaggageScreen extends React.Component {
    render() {

        return (
            <div className={"chk-baggage"}>
                <BaggageTableWebCheck/>
            </div>
        )
    }
}

export default BaggageScreen;