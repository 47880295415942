import access from 'safe-access';
import {observable, computed, action} from "mobx";
import {isEmailValid, isBlankString, isEnglishOnlyProd, isPhoneNumberValid} from "app/services/validator";
import {getActivePNR} from "app/services/session.service";
import {isValidHttpUrl} from "app/services/url.utils";
import transport from "app/services/transport";

const paymentTypeOptions = [
    {"name": "תשלום רגיל", "value": 1},
    {"name": "תשלומים", "value": 2}
];

class PaymentStore {
    @observable fullName = "";
    @observable phone = "";
    @observable email = "";
    @observable paymentOption = paymentTypeOptions[0];

    @observable isFullNameValid = true;
    @observable isPhoneValid = true;
    @observable isEmailValid = true;

    @observable isPopupOpen = false;
    @observable isPaymentInProcess = false;

    @observable paymentUrl = null;
    @observable iframeResult = null;

    @observable showError = false;
    @observable showHelper = false;

    @observable disablePayment = false;
    @observable disablePaymentGlobal = false;
    @observable shouldLogOut = false;
    @observable errMsg = null;

    @observable isHelperVisible = true;


    @observable recipe = {
        title: "",
        inputValue: "",
        type: "",
        btnText: "שלח"
    };

    @observable sendMessageLoading = false;

    constructor(root, transport) {
        this.root = root;
        this.transport = transport;

        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            this.fullName = "Full Name";
            this.phone = "0555555555";
            this.email = "test@test.com";
        }

    }

    @action reset() {
        this.disablePayment = false;
        this.disablePaymentGlobal = false;
        this.shouldLogOut = false;
        this.errMsg = null;
    }

    @computed get isFormValid() {
        if (isBlankString(this.fullName) || !isEnglishOnlyProd(this.fullName)) {
            return false;
        }

        if (!isEmailValid(this.email)) {
            return false;
        }

        if (isBlankString(this.phone) || !isPhoneNumberValid(this.phone)) {
            return false;
        }

        return true;
    }

    @action validateForm() {
        if (isBlankString(this.fullName) || !isEnglishOnlyProd(this.fullName)) {
            this.isFullNameValid = false;
        }

        if (!isEmailValid(this.email)) {
            this.isEmailValid = false;
        }

        if (isBlankString(this.phone) || !isPhoneNumberValid(this.phone)) {
            // console.log("isPhoneValid", isBlankString(this.phone), this.phone.length);
            this.isPhoneValid = false;
        }
    }

    @action setPaymentOption(option) {
        this.paymentOption = option;
    }

    @computed get selectedPaymentOptionName() {
        return this.paymentOption ? this.paymentOption.name : "";
    }

    @computed get totalPrice() {
        const baggagePrice = this.root.baggageStore.totalBaggagePrice;
        const seatsPrice = this.root.passengersStore.totalSeatsPrice;
        return baggagePrice + seatsPrice;
    }

    @computed get buttonTooltip() {
        return this.totalPrice > 0 ? "שים לב, אנא הזן פרטי תשלום" : "לא נבחרו תוספות לתשלום";
    }

    @computed get paymentOptions() {
        return paymentTypeOptions;
    }

    @action openPaymentPopup() {
        this.isPopupOpen = true;
    }

    @action setFullName(name) {
        this.fullName = name;
        this.isFullNameValid = true;
    }

    @action setPhone(val) {
        this.phone = val;
        this.isPhoneValid = true;
    }

    @action setEmail(val) {
        this.email = val;
        this.isEmailValid = true;
    }

    @action sendPaymentStatus(status) {
        const statusList = {
            //TODO: add send button clicked status
            success: "1",
            cancel: "2",
            error: "3",
            iframeClosed: "4",
            cancelledByRefresh: "5"
        };

        const getObjectFromSessionStorage = (key) => {
            const storedValue = sessionStorage.getItem(key);
            if (!storedValue) return null;

            const {id, expirationTime} = JSON.parse(storedValue);
            if (new Date().getTime() >= expirationTime) {
                sessionStorage.removeItem(key);
                return null;
            }
            return id;
        };
        const pnr = getActivePNR();
        let payId = getObjectFromSessionStorage("paymentid");

        if (payId === null) {
            // console.log("the payment id is not found or expired");
            // this.showError = true;
            return;
        }

        const data = {
            "paymentid": payId.toString(),
            "bookingid": pnr,
            "redirectedurl": statusList[status]
        };

        this.transport.notifyPaymentStatus(data)
            .then(response => {
                // console.log(response);
                const res = access(response, 'data.status');
                if (res && res === "ERROR") {
                    console.log("notifyPaymentStatus error");
                //    TODO: do we need to handle the error here?
                }

            })
            .catch(err => {
                // console.log(err);
                // console.log("an error on sending data");
            }).finally(()=> {
            sessionStorage.removeItem("paymentid");
        });

    }

    @action performPayment() {
        const {baggageStore, seatsStore, passengerDetailsStore, extraServicesStore, checkInUIStore, isWebCheckIn } = this.root;
        const baggageData = baggageStore.selectedBaggageData;
        const dashboardBaseUrl = window.location.origin;
        this.root.seatsStore.selectSeatsProcess(true);
        // console.log("dashboardBaseUrl", dashboardBaseUrl);
        const data = {
            "Seats": seatsStore.postData,
            "ssrs": baggageData,
            "resultUrls": {
                "success": isWebCheckIn? dashboardBaseUrl + "/booking/loading" : dashboardBaseUrl + "/final/success",
                "fail": dashboardBaseUrl + "/final/error",
                "cancel": dashboardBaseUrl + "/final/cancel",
            }
        };

        const totalPrice = baggageStore.baggageTotalPriceByDirection + seatsStore.totalPriceByDirection + (isWebCheckIn? 0 : extraServicesStore.totalPrice);
        if(totalPrice !== 0) {
            data.payer = {
                "name": this.fullName,
                "phone": this.phone,
                "email": this.email,
                "payment": this.paymentOption ? this.paymentOption.value : 1
            }
        }
        if (this.root.isWebCheckIn) {
            data.WebCheckInInfo = {
                "Guid": checkInUIStore.getCheckInGUID,
                "PassengersPassportData" : passengerDetailsStore.createDataForRequest
            }
        }

        // console.log("performPayment", data);

        const pnr = getActivePNR();
        this.isPaymentInProcess = true;
        this.showError = false;
        this.showHelper = false;

        this.transport.performInitialPayment(pnr, data)
            .then((response) => {
                const url = access(response, 'data.data.paymentLink.url');
                const payId = access(response, 'data.data.paymentLink.paymentID');

                const paymentDisabled = access(response, 'data.requiredOperations.clientSideActions.doBlockPayment');
                const logOut = access(response, 'data.requiredOperations.clientSideActions.doLogOff');
                const errorMsgClient = access(response, 'data.requiredOperations.clientMessage');
                
                
                if (access(response,'data.status') && response.data.status === "ERROR") {
                    //Handling unknown error message from the server
                    const errorMsg = access(response,'.data.error[0].message');
                    if (errorMsg && errorMsg === "TOP_30326") {
                        const screens = checkInUIStore.availableScreens;
                        checkInUIStore.setActiveScreen(screens[screens.length - 1]);

                        this.root.seatsStore.showErrorMessage(true, "Booking was successful but some error occurred");
                        this.root.alertsStore.showAlert("Booking was successful but some error occurred", 'warning');
                        return;
                    }

                    this.errMsg = errorMsgClient ? errorMsgClient : null;
               //     this.root.seatsStore.showErrorMessage(true, response.data.status);
                //    this.root.alertsStore.showAlert(response.data.status, 'error');
                    this.showError = true;
                    return;
                }

              

                if (isValidHttpUrl(url)) {

                    this.paymentUrl = process.env.REACT_APP_PAYMENT_URL ? process.env.REACT_APP_PAYMENT_URL : url;
                    setTimeout(() => {
                        this.showHelper = true;
                    }, 5000);

                    if (payId) {
                        this.saveToSessionStorage(payId, "paymentid");
                    }
                } else {
                    this.showError = true;
                    this.disablePayment = paymentDisabled;
                    this.disablePaymentGlobal = false;
                    this.shouldLogOut = logOut;
                    this.errMsg = errorMsgClient;
                }


                if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                    //   const dashboardBaseUrl = window.location.origin;
                    //   this.paymentUrl = dashboardBaseUrl + "/final/success"
                }

                //web check functionality run when payment process done
                // if (this.root.isWebCheckIn && (baggageStore.baggageTotalPriceByDirection + seatsStore.totalPriceByDirection <=0)) {
                if (this.root.isWebCheckIn && (totalPrice <=0)) {
                    if (access(response.data,'status') === "ERROR") {
                        console.log("some error happened")
                        this.root.seatsStore.showErrorMessage(true, response.data.status);
                        this.root.alertsStore.showAlert(response.data.status, 'error');
                        return;
                    }
                    //go to last screen if everything was fine
                    checkInUIStore.setLastScreen();
                }
            })
            .catch((e) => {
                console.error(e);
                this.isPaymentInProcess = false;
                this.showError = true;
                // this.root.seatsStore.showErrorMessage(true, e.response.data.status);
            }).finally(()=> {
            this.root.seatsStore.selectSeatsProcess(false);
            this.isPaymentInProcess = false;
        })
    }

    saveToSessionStorage(id, key) {
        const expirationTime = new Date().getTime() + 5 * 60 * 1000;
        const value = JSON.stringify({ id, expirationTime });
        sessionStorage.setItem(key, value);
    }

    @action initiatePayment() {
        this.validateForm();
        if (this.isFormValid && (this.root.baggageStore.totalBaggagePrice > 0 || this.root.seatsStore.totalPrice > 0 || this.root.extraServicesStore.totalPrice > 0)) {
            !this.root.isWebCheckIn && this.root.dashboardUIStore.paymentDlg.openPopup();
            this.iframeResult = null;
            this.performPayment();
        } else {
            console.log("some of data is not provided")
        }
    }

    @action setHelperVisibility(visible) {
        this.isHelperVisible = visible;
    }

    @action setRecipientValue(value) {
        return this.recipe = {...this.recipe, inputValue: value};
    }

    @action setRecipeMessageDetails(inputType) {
        const detailsMap = {
            email: {
                type: "email",
                id: 2,
                title: "הכנס כתובת דוא\"ל",
                inputValue: "",
                btnText: "שלח",
                label: "כתובת דוא\"ל"
            },
            sent: {
                type: "sent",
                title: "אישור הרכישה נשלח\n בהצלחה",
                btnText: "סיום",
            },
            default: {
                title: "",
                inputValue: "",
                type: "",
                btnText: "שלח"
            }
        }

        const details = detailsMap[inputType] || detailsMap.default;
        this.recipe = {...this.recipe, ...details};
    }

    @action setSendMessageLoading(status) {
        this.sendMessageLoading = status;
    }

    @action performSendRecipe() {
        this.setSendMessageLoading(true);
        transport.sendRecipeToEmail(getActivePNR(), this.recipe.inputValue)
            .then((response) => {
                console.log("response", response.data);
                if (response.data.status && response.data.status === "ERROR") {
                    const errMsg = access(response.data,'.error[0].message');
                    this.root.alertsStore.showAlert(errMsg,'error');
                    return this.root.seatsStore.showErrorMessage(true, errMsg)
                }
                this.setRecipeMessageDetails("sent");
            })
            .catch((e) => {
                console.log("error", e);
                this.setSendMessageLoading(false);
                const msg = access(e.response,'.data.error[0].message') ? e.response.data.error[0].message: "Unknown error";
                this.root.alertsStore.showAlert(msg,'error');
                this.root.seatsStore.showErrorMessage(true, msg);
            })
            .finally(() => {
                this.setSendMessageLoading(false);
            })
    }
}

export {PaymentStore}