import { inject, observer } from 'mobx-react';
import React from 'react';


@inject("pnrStore") @observer
class BaggageWarning extends React.Component {
    render () {
        if (this.props.pnrStore.showBaggageWarning) {
            return <span className='baggage-warn'>שים לב – כל נוסע רשאי להביא פריט אישי או טרולי (במשקל של עד 7 ק"ג ובמידות של עד 23X40X51)</span>
        } else {
            return null;
        }
        
    }
}

export default BaggageWarning