import React from "react";
import {inject, observer} from "mobx-react";

@inject("baggageStore", "isWebCheckIn")@observer
class InfoPopup extends React.Component {
    render() {
        const {baggageStore, isWebCheckIn} = this.props;
        const passengersExist = baggageStore.baggageTable.length > 0;
        const isBlueBird = this.props.airline && this.props.airline === "Blue Bird Airways";
        const shouldDisplayPopup = !isWebCheckIn && passengersExist && isBlueBird;
        return (
            shouldDisplayPopup ?
            <div className={"baggage-info mobile-font"}>
                <div className={"icon-info-popup"}></div>
                <div onClick={() => baggageStore.informationPopup()} className={"baggage-info-text"}>מידע מפורט על סוגי הכבודה</div>
            </div>
                : null
        )
    }
}

export {InfoPopup}