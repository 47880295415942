import moment from "moment";

const isEmailValid = (emailStr) => {
    const re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return re.test(emailStr);
}

const isPassportValid = (passportNumber) => {
    const regex = /^[A-Za-z0-9]{6,20}$/;
    return regex.test(passportNumber);
};

const isValidPassportExpiry = (expiryDateString) =>
    moment(expiryDateString, "DD/MM/YYYY").isAfter(moment().add(6, 'months'));

const isBirthDayValid = (birthday) => {
    let parsedBirthday = moment(birthday, "DD/MM/YYYY");
    return parsedBirthday.isValid() && parsedBirthday.isBefore(moment());
}

const isBirthdayValidAndAgeInRange = (birthday, minAge = null, maxAge = null) => {
    let minAgeValid = true;
    let maxAgeValid = true;
    if (!isBirthDayValid(birthday)) {
        return false;
    }

    let parsedBirthday = moment(birthday, "DD/MM/YYYY");
    const ageReferenceDate = moment().add(1,'days').set({hour:0,minute:0,second:0,millisecond:0});
    const age = ageReferenceDate.diff(parsedBirthday,'years',true);

    if (minAge !== null) {
        minAgeValid = age >= minAge;
    }

    if (maxAge) {
        maxAgeValid = age < maxAge;
    }

   // console.log("isAgeInRange", age, minAge, maxAge);
    return (minAgeValid && maxAgeValid);

}

const isPhoneNumberValid = (phoneStr) => {
    //const pattern = /^\d{2,3}\-?\d{7}$/;
    const pattern = /^(?:(?:\+?972-?)5\d{8}|05\d{8}|(?!972|05)\d{11,13})$/;

    const r = pattern.test(phoneStr);
    return r;
}

const isBlankString = (str) => {
    return (!str || /^\s*$/.test(str));
}

const isEnglishOnlyProd = (str) => {
    var r = /^[\sa-zA-Z]+$/;
    return r.test(str);
}

const truncatedText = (str, length = 15) => {
    return str.length > length ? str.slice(0, length) + "..." : str;
}

const cleanedPhoneNumber = (phoneNumber) => phoneNumber.replace(/\D/g, "");

export {
    isBlankString,
    isEmailValid,
    isPhoneNumberValid,
    isEnglishOnlyProd,
    truncatedText,
    isPassportValid,
    isValidPassportExpiry,
    isBirthDayValid,
    cleanedPhoneNumber,
    isBirthdayValidAndAgeInRange
};