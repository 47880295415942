import React from 'react'
import {inject, observer} from "mobx-react";
import PassengerDetails from "app/desktop/checkin/passenger-details/passenger.details";
import {SingleFlightCheckIn} from "app/desktop/ui/components/dialogs/dlg-flight-details/dlg.flight.details";

const ImpInfo = (process.env.REACT_APP_MODE === 'mobile') ?
    require('../../../mobile/ui/components/important-info/important-info').default
    :
    require('../../ui/components/important-info/important-info').default;

@inject("passengerDetailsStore", "countriesStore", "pnrStore", "checkInUIStore") @observer
class PassengerDetailsScreen extends React.Component {
    render() {
        const infoText = "לצורך ביצוע הרשמה מוקדמת לטיסה (צ'ק-אין), יש למלא את כל השדות (כולל פרטי הדרכון).\n" +
            "באחריות הנוסע לוודא כי כל הפרטים נכונים. \n" +
            "אנא שימו לב כי תוקף הדרכון הינו לפחות חצי שנה מיום הכניסה ליעד.";

        const pnr = this.props.pnrStore;
        const chk = this.props.checkInUIStore;

        // if (!chk.curDirection) {
        //     return (
        //         <ScreenWrapper>
        //             <div className={"popup-loader"}>
        //                 <Preloader/>
        //             </div>
        //         </ScreenWrapper>
        //     )
        // }

        const autofillDetails = (e) => {
            e.preventDefault();
            chk.autofillDetails();
        }

        const autofillButton = process.env.REACT_APP_SHOW_CHECKIN_DEBUG_BUTTON === "1" &&
            <button onClick={autofillDetails} className={"chk-autofill"}>Autofill</button>;

        return (
            <div className={"passenger-details-wrapper"}>
                <div className={"flight-info-wrapper"}>
                    <div className={"cs-section"}>
                    <div className={"dlg-content dlg-flight-details"}>
                    <div className={"cs-flight-data cs-flight-data-webcheck"}>
                        {chk.curDirection === 0 ?
                            <SingleFlightCheckIn
                                statusData={pnr.flight1StatusData}
                                direction={"טיסת הלוך"}
                                departureDay={pnr.flight1DepartureDayShort}
                                departureDate={pnr.flight1DepartureDate}
                                arrivalDay={pnr.flight1ArrivalDayShort}
                                arrivalDate={pnr.flight1ArrivalDate}
                                departureAirport={pnr.date1DepartureAirport}
                                departureTime={pnr.date1DepartureTime}
                                arrivalAirport={pnr.date1ArrivalAirport}
                                arrivalTime={pnr.date1ArrivalTime}
                                icon={"icon-departure"}
                                departureCity={pnr.date1DepartureCity}
                                arrivalCity={pnr.date1ArrivalCity}
                                airline={pnr.date1Airline}
                                flightData={pnr.flight1Data}
                            /> :
                            <SingleFlightCheckIn
                                statusData={pnr.flight1StatusData}
                                direction={"טיסת חזור"}
                                departureDay={pnr.flight1DepartureDayShort}
                                departureDate={pnr.flight1DepartureDate}
                                arrivalDay={pnr.flight1ArrivalDayShort}
                                arrivalDate={pnr.flight1ArrivalDate}
                                departureAirport={pnr.date1DepartureAirport}
                                departureTime={pnr.date1DepartureTime}
                                arrivalAirport={pnr.date1ArrivalAirport}
                                arrivalTime={pnr.date1ArrivalTime}
                                icon={"icon-departure"}
                                departureCity={pnr.date1DepartureCity}
                                arrivalCity={pnr.date1ArrivalCity}
                                airline={pnr.date1Airline}
                                flightData={pnr.flight1Data}
                            />
                        }
                    </div>
                    </div>
                    </div>
                </div>
                <h3>פרטי נוסעים</h3>
                {autofillButton}
                <ImpInfo infoText={infoText}/>
                <div className={"chk-passengers"}>
                    {this.props.passengerDetailsStore.passengers.map((p,ind) => {
                        return <PassengerDetails countries={this.props.countriesStore} passenger={p} key={"p"+ind}/>
                    })}
                </div>
            </div>
        )
    }
}

export default PassengerDetailsScreen;