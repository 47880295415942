import React from 'react';
import {inject, observer} from "mobx-react";
import {RegularButtonBig} from "app/desktop/ui/components/buttons/regular-button/regular.button";
import {ButtonContinue} from "app/desktop/ui/components/buttons/button-continue/button.continue";
import {InitiatePayment} from "app/desktop/ui/pages/dashboard/components/payment/content/payment.button";
import classnames from "classnames";
import {NotAvailableSeats} from "app/desktop/ui/components/dialogs/dlg-seat-selection/dlg.seat.selection";

require('./checkin.footer.scss');


@inject("checkInUIStore", "passengerDetailsStore", "seatsStore", "pnrStore", "dashboardUIStore", "baggageStore", "checkInPopup") @observer
class Continue extends React.Component {
    render() {
        const chk = this.props.checkInUIStore;
        const dshb = this.props.dashboardUIStore;
        const iframeScreen = chk.activeScreen === chk.availableScreens[4];
        const detailsScreen = chk.activeScreen === chk.availableScreens[0];
        const seatsScreen = chk.activeScreen === chk.availableScreens[2];
        const baggageScreen = chk.activeScreen === chk.availableScreens[1];
        const css = classnames("continue-wrapper", {"continue-hidden": iframeScreen}, {"continue-disabled": !chk.allPassengersSelectedSeats && seatsScreen});

        const handleContinueClicked = () => {

            if (baggageScreen) {
                if (chk.shouldOpenBaggagePopup) {
                    return dshb.addBaggageCheckIn.openPopup();
                }
            }
            chk.moveToNextScreen();
        }

        let buttonText = detailsScreen ? "שמור והמשך" : chk.isConfirmationScreen ? "סיים" : "המשך";
        const content = chk.isPaymentScreen ? <InitiatePayment/> :
            <div className={"col-3"}>
                <ButtonContinue onClick={() => {
                    handleContinueClicked();
                }}>{buttonText}
                    <div className="icon-arrow-next"></div>
                </ButtonContinue>
            </div>

        return (

            <div className={css}>
                {content}
            </div>

        )
    }
}

@inject("checkInUIStore", "seatsStore", "paymentStore") @observer
class BackButton extends React.Component {
    render() {
        const chk = this.props.checkInUIStore;
        const s = this.props.seatsStore;
        const pay = this.props.paymentStore;
        const detailsScreen = chk.activeScreen !== chk.availableScreens[0];
        const paymentScreen = chk.activeScreen === chk.availableScreens[3];

        const css = classnames("col-1", {"visible-back": detailsScreen && !chk.isConfirmationScreen && !pay.isPaymentInProcess});
        return (
            <div className={css}>
                <RegularButtonBig onClick={() => {
                    if (paymentScreen) {
                        s.setFetchSeatsType("webCheckFetchSeatsOnBack");
                        // this.props.checkInUIStore.setRandomlyPicked(true);
                    }
                    chk.moveToPrevScreen();
                }}>
                    <div className={"icon-back"}/>
                    חזור </RegularButtonBig>
            </div>

        )
    }
}

@inject("checkInUIStore", "pnrStore", "passengerDetailsStore", "baggageStore", "seatsStore") @observer
class FooterSlider extends React.Component {

    render() {
        const pnr = this.props.pnrStore;
        const chk = this.props.checkInUIStore;
        const bg = this.props.baggageStore;
        const s = this.props.seatsStore;

        const seatErrorsTransform = {transform: "translate(50%, -50%)"};

        let cssSlide = "";
        switch(chk.activeScreen) {
            case chk.availableScreens[1]:
                cssSlide = "baggage";
                break;
            case chk.availableScreens[2]:
                cssSlide = "seats";
                break;
            case chk.availableScreens[3]:
                cssSlide = "payment";
                break;
            case chk.availableScreens[4]:
                cssSlide = "iframe";
                break;
            case chk.availableScreens[5]:
                cssSlide = "finished";
                break;
            default:
                cssSlide = "";
                break;
        }
        const css = classnames("col-btn-continue slider-wrapper", {[cssSlide]: !!cssSlide});
        const totalPrice =  (this.props.baggageStore.totalBaggagePrice +
            this.props.seatsStore.totalPrice).toFixed(2);

        return (
            <div className={css}>

                <div className={"slider-content"}>
                    <div className={"price-col price-height"}>
                        <div className={"validation-error-msg"}>{chk.invalidText}</div>
                    </div>
                </div>

                <div className={"slider-content"}>
                    <div className={"price-col price-height"}>
                        <div className="text">סה״כ תוספת תשלום<br/> עבור כבודה</div>
                        <div className="price">{pnr.currencySymbol}
                            {bg.baggageTotalPriceByDirection}
                        </div>
                    </div>
                </div>

                <div className={"slider-content"}>
                    <NotAvailableSeats text={s.errText} transform={seatErrorsTransform}/>
                    <div className={"price-col price-height"}>
                        <div className="text">סה״כ תוספת תשלום<br/> עבור הושבה</div>
                        <div className="price">{pnr.currencySymbol}
                            {s.totalPriceByDirection}
                        </div>
                    </div>
                </div>

                <div className={"slider-content"}>
                    <div className={"price-col price-height"}>
                        <div className="text">סה״כ תוספת תשלום</div>
                        <div className="price">{pnr.currencySymbol}
                            {totalPrice}
                        </div>
                    </div>
                </div>

                <div className={"slider-content"}>
                    <div className={"price-col price-height"}/>
                </div>

                <div className={"slider-content"}>
                    <NotAvailableSeats text={s.errText} transform={seatErrorsTransform}/>
                    <div className={"price-col price-height"}/>
                </div>

            </div>
        )
    }
}


@inject("checkInUIStore", "isWebCheckIn", "seatsStore") @observer
class CheckinFooter extends React.Component {

    render() {
        const {isWebCheckIn} = this.props;
        const s = this.props.seatsStore;
        const css = classnames("checkin-footer", {"disabled": isWebCheckIn && s.isBooking})

        return (
            <div className={css}>
                <BackButton/>
                <FooterSlider/>
                <Continue/>
            </div>
        )
    }
}

export default CheckinFooter;