import React from "react";
import {TxtField} from "app/desktop/ui/components/txt-field/txt.field";
import {MainButton} from "app/desktop/ui/components/buttons/main-button/main.button";
import {inject, observer} from "mobx-react";
import {Preloader} from "app/desktop/ui/components/preloader/preloader";
import {getQSParameterByName} from "app/services/qs.utils";
import {history} from 'app/services/custom.router'



@inject("passwordResetStore") @observer
class ErrorMessage extends React.Component {
    render() {
        const {passwordResetStore} = this.props;

        return (passwordResetStore.changePasswordError) ? <div className={"error"}>{passwordResetStore.changePasswordError}</div> : "";
    }
}

const SuccessMesssage = (props) => {
    return <div className={"success"}>שינוי סיסמא בוצע בהצלחה. <br/> <a href={"/agent"}><strong>כניסה למערכת</strong></a></div>;
}

@inject("passwordResetStore") @observer
class PasswordChangePage extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.props.passwordResetStore.changePasswordLoading) {
            console.log("already loading...")
        } else {
            this.props.passwordResetStore.performChangePassword();
        }
    }

    componentDidMount() {
        const {passwordResetStore} = this.props;

        const guid = getQSParameterByName("id");
        const token = getQSParameterByName("token");

        // redirect in case of missing ID or TOKEN
        if (!guid || !token) {
            history.push('/agent');
        }

        // console.log("guid, token",guid, token);

        passwordResetStore.setGuid(guid);
        passwordResetStore.setToken(token);
    }

    render() {

        const {passwordResetStore} = this.props;
        let status = (passwordResetStore.changePasswordLoading) ? <Preloader/> : (passwordResetStore.changePasswordError) ? <ErrorMessage/>  : "";

        if (passwordResetStore.changePasswordSuccess) {
            status = <SuccessMesssage/>
        }

        const isFirstTime = getQSParameterByName("welcome");

        let line1;
        let txt;

        if (isFirstTime === '1') {
            line1 = "קביעת סיסמא";
            txt = "על מנת לסיים את הרישום לאתר, יש לקבוע סיסמא. אורך מינימלי של הסיסמא הינו 6 תווים.";
        } else {
            line1 = "שינוי סיסמא";
            txt = "אנא רשמו סיסמא חדשה פעמיים. אורך המינימלי של הסיסמא הוא 6 תווים.";
        }

        return (
            <div className={"login-page"}>
                <div className={"cs-login-form"}>
                    <div className={"header-image"}/>
                    <div className={"form-body"}>
                        <div className={"form-header"}>
                            <div className={"line-1"}>{line1}</div>
                            <p dangerouslySetInnerHTML={{__html:txt}}/>
                            <form action={"#"} onSubmit={this.handleSubmit}>
                                <div className={"rows"}>
                                    <div className={"row"}>
                                        <TxtField placeholder={"סיסמא חדשה"}
                                                  value={passwordResetStore.newPassword}
                                                  isError={passwordResetStore.error}
                                                  disabled={passwordResetStore.changePasswordLoading || passwordResetStore.changePasswordSuccess}
                                                  maxLength={64}
                                                  type={"password"}
                                                  tooltip = {"סיסמא חדשה"}
                                                  name={"new-password"}
                                                  onChange = {(e) => {
                                                      passwordResetStore.setNewPassword(e.target.value);
                                                  }}/>
                                    </div>

                                    <div className={"row"}>
                                        <TxtField placeholder={"אישור סיסמא חדשה"}
                                                  value={passwordResetStore.newPasswordConfirm}
                                                  isError={passwordResetStore.error}
                                                  disabled={passwordResetStore.changePasswordLoading || passwordResetStore.changePasswordSuccess}
                                                  maxLength={64}
                                                  type={"password"}
                                                  tooltip = {"אישור סיסמא חדשה"}
                                                  name={"new-password-2"}
                                                  onChange = {(e) => {
                                                      passwordResetStore.setNewPasswordConfirm(e.target.value);
                                                  }}/>
                                    </div>


                                    <div className={"row"}>
                                        {status}
                                    </div>

                                    <div className={"row"}>
                                        <div className={"button-placeholder"}>
                                            <MainButton disabled={passwordResetStore.changePasswordSuccess || passwordResetStore.changePasswordLoading} onClick={() => {passwordResetStore.performChangePassword();}}>שמור</MainButton>
                                        </div>
                                    </div>
                                </div>

                                <input type={"submit"} style={{"visibility":"hidden", "display":"none"}}/>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export {PasswordChangePage}