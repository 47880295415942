import React from 'react';
import 'app/desktop/ui/components/dashboard-steps/dashboard.steps.scss';
import {DashboardStep} from "app/desktop/ui/components/dashboard-steps/step";
import {inject, observer} from "mobx-react";
import {history} from 'app/services/custom.router'
import {StepTypes} from "../../../../stores/steps.store";
import { getPNRSource } from "app/services/session.service";

@inject ("dashboardUIStore", "baggageStore", "stepsStore") @observer
class DashboardSteps extends React.Component {
    render() {
        if (this.props.stepsStore.allSteps.length === 1) {
            return  null;
        }
        const pnrSource = getPNRSource();
        const diabledAEROSRC = (pnrSource) && pnrSource === "AEROCRS";

        return (
            <div className={"dashboard-steps"}>
                {this.props.stepsStore.allSteps.map((step,index) => {
                    return <DashboardStep num={index}
                                          key={"step"+index}
                                          marked={step.marked}
                                          active={step.active}
                                          disabled={diabledAEROSRC && step.type !=="INFORMATION"}
                                          onClick={() => {
                                              this.props.stepsStore.setStep(step.type);
                                              history.push(step.path);
                                              /*
                                              const currentStep = stepsStore.currentStep;
                                              if (step.type === StepTypes.SEATS || step.type === StepTypes.PAYMENT && currentStep === StepTypes.BAGGAGE) {
                                                  if (baggageStore.isBaggageAvailable && !baggageStore.isBaggageSelected) {
                                                      s.addBaggageDialog.openPopup();
                                                  } else {
                                                      this.props.stepsStore.setStep(step.type);
                                                      history.push(step.path);
                                                  }
                                              } else {
                                                  this.props.stepsStore.setStep(step.type);
                                                  history.push(step.path);
                                              }
                                            */

                                              // TODO: Temporary disabled popup
                                              /*
                                              if (cur !== 4) {
                                                  if (baggageStore.isBaggageAvailable && !baggageStore.isBaggageSelected) {
                                                      s.addBaggageDialog.openPopup();
                                                  } else {
                                                      s.setStep(4);
                                                      history.push('/dashboard/payment');
                                                  }
                                              }
                                               */
                                          }}
                                          title={step.title}
                                          />
                })}
            </div>
        )
    }
}

DashboardSteps.defaultProps = {
    currentStep : 0
}

export {DashboardSteps}