import React from 'react'
import {observer, inject} from "mobx-react";
import { ReactComponent as StarPlusIcon } from "app/desktop/images/icons/star-plus.svg";

@inject ("extraServicesStore", "pnrStore","dashboardUIStore", "passengersStore") @observer
class ExtraServicesSidebarTable extends React.Component {

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  render() {
    const s = this.props.extraServicesStore;
    const { totalPrice, areExtraServicesAvailable } = s;
    const dlg = this.props.dashboardUIStore.extraServicesDialog;

    if (totalPrice === 0 && areExtraServicesAvailable) {
      return <div className={"alert"}>
          לא נבחרו שירותים נוספים</div>
    }

    if (totalPrice <= 0) {
      return null;
    }
    
    return (
      <div className={"sidebar-table"}>
          <div className={"st-row"}>
              <div className={"col1"}>שרות בלובירד פריוריטי פס</div>
              <div className={"col2"}>{this.props.pnrStore.currencySymbol}{totalPrice}</div>
          </div>
          {(totalPrice > 0) && (
                <div className={"st-row total"}>
                    <div className={"col1"}>
                        <a href={"#"} ref={this.myRef} onClick={(e) => {
                            e.preventDefault();
                            const v = this.myRef.current.getBoundingClientRect();
                            console.log("click event", JSON.stringify(v));
                            dlg.setPosition(v.x, v.y);
                            dlg.openPopup();
                        }}>הקלק לפירוט מלא</a>
                    </div>
                    <div className={"col2 total-price"}>
                        <span className={"heb"}>סה״כ</span> {this.props.pnrStore.currencySymbol}{totalPrice}
                    </div>
                </div>
            )
        }
      </div>
    )
  }
}

export {ExtraServicesSidebarTable}