import React from 'react'
import {inject, observer} from "mobx-react";

@inject ("pnrStore") @observer
class SeatsInfo extends React.Component {
    render() {
        const pnr = this.props.pnrStore;
        return (
            <div className={"dlg-section"}>
                <div className={"row destinations"}>
                    <div className={"icon-seats"}/>
                    <div className={"dst"}>פירוט הושבה</div>
                </div>

                <div className={"baggage-row header"}>
                    <div className={"b-num"}>&nbsp;</div>
                    <div className={"b-name"}>שם נוסע</div>
                    <div className={"b-type"}>מס' מושב</div>
                </div>

                <div>
                    {this.props.passengers.map((i,index)=>{
                        return (
                            <div className={"baggage-row"} key={"b"+index}>
                                <div className={"b-num"}>{index+1}.</div>&nbsp;
                                <div className={"b-name"}>{i.fullName}</div>&nbsp;
                                <div className={"b-type"}>
                                    {i.seats.map((b,bi) => {
                                        return pnr.checkInfant(index) || <div key={"b"+index+"-"+bi}>{b.name}</div>
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }
}

export {SeatsInfo}