import React from 'react'
import {RegularButton, RegularButtonBig} from "app/desktop/ui/components/buttons/regular-button/regular.button";
import {BlueButtonBig} from "app/desktop/ui/components/buttons/blue-button/blue.button";
import {inject, observer} from "mobx-react";
require('app/desktop/ui/components/dialogs/dlg-payment/load.error.scss');
const iconError = require('app/desktop/images/icon-error.svg');

@inject("paymentStore") @observer
class LoadError extends React.Component {
    render() {
        const ps = this.props.paymentStore;
        let errorMsg = ps.errMsg? <p className={"err-msg"}>{ps.errMsg}</p> :
            <p>נראה שקרתה תקלה<br/>
            אנא נסה שוב עוד מספר דקות</p>;
        let buttonDisabled = ps.disablePayment;

        return (
            <div className={"load-error"}>
                <div className={"title"}>אופס</div>
                {errorMsg}
                <div>
                    <img src={iconError} width={400}/>
                </div>

                <BlueButtonBig disabled={buttonDisabled} onClick={() => {
                    this.props.paymentStore.performPayment();
                }}>נסה שוב</BlueButtonBig>
            </div>
        )
    }
}

export {LoadError}