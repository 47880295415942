import {observable, computed, action} from "mobx";
import access from 'safe-access';
import {getActivePNR, setPNRSource} from "app/services/session.service";
import {datesArray} from "app/services/dates.array";
import {createMomentDateWithFixedOffset} from "app/services/time.service";
import moment from "moment";
import transport from "app/services/transport";
import ServiceTypes from "app/enums/service.types";

/*
const getPassengerNameById = (passengers, paxId) => {
    let n = "";
    const o = passengers.find((p) => {
        return (p.id == paxId);
    });

    if (o) {
        return o.fullName;
    }

    return n;
}
*/


const getServicesByType = (services, paxId, type, guid, defaultName, trolleyWasInluded) => {
    let prefix = "";

    switch (type) {
        case ServiceTypes.CarryOnBag:
            prefix = trolleyWasInluded ? "" : "נרכש ";
            break;
        case ServiceTypes.FirstBaggage:
            prefix = "נרכשה ";
            break;
        default:
    }
    const result = services
        .filter(service => service.paxId.toString() === paxId && service.type === type && service.product === guid)
        .map(service => ({ name: prefix + service.name, id: service.paxId }));
    if (result.length === 0) {
        result.push({ name: defaultName });
    }

    return result;
};

const getPassengerServicesArrayForDate = (passengers, services, guid, trolleyWasInluded) => {
    return passengers.map((passenger) => {
        const baggage = getServicesByType(services, passenger.id, ServiceTypes.FirstBaggage, guid, 'טרם נרכשה מזוודה');
        const trolleyBaggage = getServicesByType(services, passenger.id, ServiceTypes.CarryOnBag, guid, 'טרם נרכש טרולי', trolleyWasInluded);
        const seats = getServicesByType(services, passenger.id, ServiceTypes.Seat, guid, 'טרם הוזמן');

        const arr = (passenger.title === 'Infant') ? [] : trolleyBaggage;
        return {
            id: passenger.id,
            fullName: `${passenger.title} ${passenger.fullName}`,
            baggage,
            trolleyBaggage: arr,
            seats,
            title : passenger.title
        };
    });
};

class PNRStore {
    @observable data = null;
    @observable isLoading = false;


    constructor(root, transport, authStore) {
        this.root = root;
        this.transport = transport;
        this.authStore = authStore;
    }

    @action setData(data) {
        this.data = data;
    }

    @action downloadTicket() {
        this.transport.downloadTicket(getActivePNR())
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'file.pdf');
                document.body.appendChild(link);
                link.click();
            }).catch(e => {
            console.log(e);
        });
    }

    @action loadData(pnr) {
        this.isLoading = true;
        this.data = null;
        this.root.hotelStore.setData(null);
        this.transport.getPNRData(pnr)
            .then((response) => {
                this.setData(response.data);
                setPNRSource(access(response, 'data.data.pnrSource'));
                

                this.root.passengersStore.createPassengers(access(response, 'data.data.passengersInfo'));
                // hotel
                this.root.hotelStore.setData(access(response.data, 'data.hotels'));
                this.isLoading = false;
                //  this.root.seatsStore.createPassengers();

                // set flightCheckInInfo from pnr and pass guid from here to the function below
                this.root.checkInUIStore.setTitle();
                this.checkInCurrentDirection(access(this.checkInData, '.guid'));

                this.root.baggageStore.fetchBaggageOptions();
                this.root.seatsStore.fetchSeats();
                window.sessionStorage.setItem("encryptedPnr", access(response.data, '.data.encryptedPnr'));

                // dummy access to computed to force it to recalculate
                const showWarn = this.showBaggageWarning;
                console.log("ShowWarning",showWarn);
            })
            .catch((e) => {
                console.log("error", e);
                this.data = null;
                this.isLoading = false;
            })
    }

    @action reset() {
        this.data = null;
        this.isLoading = false;
    }

    @computed get isOneWayFlight() {
        if (!this.data) {
            return false;
        }
        //TODO: test it when access(this.data, 'data.flights' === undefined
        return (access(this.data, 'data.flights') || []).length === 1;
    }

    @computed get loggedUserFullName() {
        if (!this.data) {
            return "";
        }

        const firstName = access(this.data, 'data.pax[0].firstName'),
            lastName = access(this.data, 'data.pax[0].lastName');

        return (firstName && lastName) ? (firstName + " " + lastName) : "";

    }

    @computed get isTwoWayFlight() {
        return !!access(this.data, 'data.flights[1]');
    }

    @computed get flight1DepartureDayShort() {
        if (!this.data) {
            return "";
        }

        const d = createMomentDateWithFixedOffset(access(this.data, 'data.flights[0].legs[0].departureTime'));
        return datesArray[d.format('d')];
    }

    @computed get flight1ArrivalDayShort() {
        if (!this.data) {
            return "";
        }

        const d = createMomentDateWithFixedOffset(access(this.data, 'data.flights[0].legs[0].arrivalTime'));
        return datesArray[d.format('d')];
    }

    @computed get flight1DepartureDate() {
        if (!this.data) {
            return "";
        }
        const d = createMomentDateWithFixedOffset(access(this.data, 'data.flights[0].legs[0].departureTime'));
        return d.format('DD.MM.YY');
    }

    @computed get flight1ArrivalDate() {
        if (!this.data) {
            return "";
        }

        const d = createMomentDateWithFixedOffset(access(this.data, 'data.flights[0].legs[0].arrivalTime'));
        return d.format('DD.MM.YY');
    }

    @computed get flight2DepartureDayShort() {
        if (!this.data) {
            return "";
        }

        const d = createMomentDateWithFixedOffset(access(this.data, 'data.flights[1].legs[0].departureTime'));
        return datesArray[d.format('d')];
    }

    @computed get flight2ArrivalDayShort() {
        if (!this.data) {
            return "";
        }

        const d = createMomentDateWithFixedOffset(access(this.data, 'data.flights[1].legs[0].arrivalTime'));
        return datesArray[d.format('d')];
    }

    @computed get flight2DepartureDate() {
        if (!this.data) {
            return "";
        }

        const d = createMomentDateWithFixedOffset(access(this.data, 'data.flights[1].legs[0].departureTime'));
        return d.format('DD.MM.YY');
    }

    @computed get flight2ArrivalDate() {
        if (!this.data) {
            return "";
        }

        const d = createMomentDateWithFixedOffset(access(this.data, 'data.flights[1].legs[0].arrivalTime'));
        return d.format('DD.MM.YY');
    }

    @computed get day1Short() {
        // console.warn("day1Short call");
        if (!this.data) {
            return "";
        }

        const d = createMomentDateWithFixedOffset(access(this.data, 'data.flights[0].legs[0].departureTime'));
        return datesArray[d.format('d')];
    }

    @computed get date1() {
        // console.warn("date1 call");
        if (!this.data) {
            return "";
        }

        const d = createMomentDateWithFixedOffset(access(this.data, 'data.flights[0].legs[0].departureTime'));

        return d.format('DD.MM.YY');
    }

    @computed get date1Short() {
        if (!this.data) {
            return "";
        }

        const d = createMomentDateWithFixedOffset(access(this.data, 'data.flights[0].legs[0].departureTime'));

        return d.format('DD.MM.YY');
    }

    @computed get day2Short() {
        if (!this.data || !access(this.data, 'data.flights[1].legs[0].arrivalTime')) {
            return "";
        }

        const d = createMomentDateWithFixedOffset(access(this.data, 'data.flights[1].legs[0].arrivalTime'));

        return datesArray[d.format('d')];
    }

    @computed get date2() {
        if (!this.data || !access(this.data, 'data.flights[1].legs[0].arrivalTime')) {
            return "";
        }

        const d = createMomentDateWithFixedOffset(access(this.data, 'data.flights[1].legs[0].arrivalTime'));

        return d.format('DD.MM.YY');
    }

    @computed get date2Short() {
        if (!this.data || !access(this.data, 'data.flights[1].legs[0].arrivalTime')) {
            return "";
        }

        const d = createMomentDateWithFixedOffset(access(this.data, 'data.flights[1].legs[0].arrivalTime'));

        return d.format('DD.MM.YY');
    }

    @computed get numberOfPaxes() {
        if (!this.data) {
            return 0;
        }

        const d = (access(this.data, 'data.pax') || []).length;

        return d;
    }

    @computed get flight1Data() {
        const d = access(this.data, 'data.flights[0]');
        return d;
    }

    @computed get flight2Data() {
        const d = access(this.data, 'data.flights[1]');
        return d;
    }

    @computed get date1DepartureAirport() {
        const d = access(this.data, 'data.flights[0].legs[0].departureAirport');
        return d;
    }

    @computed get date1DepartureTime() {
        const d = createMomentDateWithFixedOffset(access(this.data, 'data.flights[0].legs[0].departureTime'));

        return d.format('HH:mm');
    }

    @computed get date1ArrivalAirport() {
        const d = access(this.data, 'data.flights[0].legs[0].arrivalAirport');
        return d;
    }

    @computed get date1ArrivalTime() {
        const d = createMomentDateWithFixedOffset(access(this.data, 'data.flights[0].legs[0].arrivalTime'));

        return d.format('HH:mm');
    }


    //

    @computed get date2DepartureAirport() {
        const d = access(this.data, 'data.flights[1].legs[0].departureAirport');
        return d;
    }

    @computed get date2DepartureTime() {
        const d = createMomentDateWithFixedOffset(access(this.data, 'data.flights[1].legs[0].departureTime'));

        return d.format('HH:mm');
    }

    @computed get date2ArrivalAirport() {
        const d = access(this.data, 'data.flights[1].legs[0].arrivalAirport');
        return d;
    }

    @computed get date2ArrivalTime() {
        const d = createMomentDateWithFixedOffset(access(this.data, 'data.flights[1].legs[0].arrivalTime'));

        return d.format('HH:mm');
    }

    @computed get downloadTicketURL() {
        const url = access(window, 'pageConfig.API_PROTOCOL') + "://" +
            access(window, 'pageConfig.API_HOST') + "/api/bookings/" + getActivePNR() + "/files?docType=Itinerary";

        return url;
    }

    @computed get passengers() {
        if (!this.data) {
            return []
        }

        return access(this.data, 'data.passengersInfo') || [];

    }

    @computed get date1DepartureCity() {
        return access(this.data, 'data.flights[0].legs[0].departureCity');
    }

    @computed get date1ArrivalCity() {
        return access(this.data, 'data.flights[0].legs[0].arrivalCity');
    }

    @computed get date2DepartureCity() {
        return access(this.data, 'data.flights[1].legs[0].departureCity');
    }

    @computed get date2ArrivalCity() {
        return access(this.data, 'data.flights[1].legs[0].arrivalCity');
    }

    @computed get date1Airline() {
        return access(this.data, 'data.flights[0].legs[0].airline');
    }

    @computed get date2Airline() {
        return access(this.data, 'data.flights[1].legs[0].airline');
    }

    @computed get date1Baggage() {
        const services = access(this.data, 'data.additionalServices') || [];
        const guid = access(this.data, 'data.flights[0].guid');
        const passengers = access(this.data, 'data.passengersInfo') || [];

        const final = getPassengerServicesArrayForDate(passengers, services, guid, this.showBaggageWarning);
        console.log("date1Baggage", final);
        return final;
    }


    @computed get date2Baggage() {
        const services = access(this.data, 'data.additionalServices') || [];
        const guid = access(this.data, 'data.flights[1].guid');
        const passengers = access(this.data, 'data.passengersInfo') || [];

        const final = getPassengerServicesArrayForDate(passengers, services, guid, this.showBaggageWarning);
        console.log("date2Baggage", final);
        return final;
    }

    @computed get currencySymbol() {
        return access(this.data, 'data.currency.symbol');

    }

    @computed get flight1Guid() {
        return access(this.data, 'data.flights[0].guid')
    }

    @computed get flight2Guid() {
        return access(this.data, 'data.flights[1].guid')
    }

    @computed get flightStatusData() {
        return {
            "warn": access(this.data, 'data.flightsStatus.id') !== 0,
            "text": access(this.data, 'data.flightsStatus.name') || ""
        }
    }

    @computed get flight1StatusData() {
        return {
            "warn": access(this.data, 'data.flights[0].status.id') !== 0,
            "text": access(this.data, 'data.flights[0].status.name') || ""
        }
    }

    @computed get flight2StatusData() {
        return {
            "warn": access(this.data, 'data.flights[1].status.id') !== 0,
            "text": access(this.data, 'data.flights[1].status.name') || ""
        }
    }

    @computed get hotelsStatusData() {
        return {
            "warn": access(this.data, 'data.hotelsStatus.id') !== 0,
            "text": access(this.data, 'data.hotelsStatus.name') || ""
        }
    }

    @computed get hotelStatusData() {
        return (indx => {
            return {
                "warn": access(this.data, `data.hotels[${indx}].status.id`) !== 0,
                "text": access(this.data, `data.hotels[${indx}].status.name`) || ""
            }
        })
    }

    @computed get seat1IsPaid() {
        return (passIndx => {
            let seatName = access(this.date1Baggage, `[${passIndx}].seats[0].name`);
            let hasSeatNumber = /\d/.test(seatName);
            return hasSeatNumber ? seatName : ""
        })
    }

    @computed get seat2IsPaid() {
        return (passIndx => {
            let seatName = access(this.date2Baggage, `[${passIndx}].seats[0].name`);
            let hasSeatNumber = /\d/.test(seatName);
            return hasSeatNumber ? seatName : ""
        })
    }

    @computed get anySeatIsPaid() {
        return this.root.passengersStore.passengersExcludeInfants.some(pass => !!this.seat1IsPaid(pass.id - 1) || this.seat2IsPaid(pass.id - 1));
    }

    @computed get oneDirectionSeatPaid() {
        return this.root.passengersStore.passengersExcludeInfants.some(pass => this.root.seatsStore.activeFlight === 0 ? this.seat1IsPaid(pass.id - 1) : this.seat2IsPaid(pass.id - 1))
    }

    @computed get checkInfant() {
        return (index =>  {
            let name = access(this.data, `data.passengersInfo[${index}].title`);
            return name && name.toLowerCase() === 'infant';
        });
    }

    // @computed get flightSeatsByIndex() {
    //     if (this.root.seatsStore.flights.length === 1) {
    //         return this.root.seatsStore.flights[0].flightIndex === 0 ?
    //             this.date1Baggage.reduce((acc, cur) => {
    //                 return [...acc, cur.seats[0].name]
    //             }, []) :
    //             this.date2Baggage.reduce((acc, cur) => {
    //                 return [...acc, cur.seats[0].name]
    //             }, []);
    //     }
    //
    //     return this.root.seatsStore.activeFlight === 0 ?
    //         this.date1Baggage.reduce((acc, cur) => {
    //             return [...acc, cur.seats[0].name]
    //         }, []) :
    //         this.date2Baggage.reduce((acc, cur) => {
    //             return [...acc, cur.seats[0].name]
    //         }, []);
    // }

    @computed get flightSeatsByIndex() {
        const flightArr = this.root.isWebCheckIn ? this.root.seatsStore.paidFlights : this.root.seatsStore.flights;
        //web checkin functionality
        const baggage = flightArr.length === 1 ?
            flightArr[0].flightIndex === 0 ?
                this.date1Baggage :
                this.date2Baggage :
            this.root.seatsStore.activeFlight === 0 ?
                this.date1Baggage :
                this.date2Baggage;
        return baggage.reduce((acc, cur) => {
            return [...acc, cur.seats[0].name];
        }, []);
    }

    @computed get reservedSeats() {
        return this.root.passengersStore.passengersExcludeInfants
            .reduce((acc, obj) => {
                const seatNumber = access(obj.seats, `[${this.root.seatsStore.currentFlight?.flightIndex}].number`);
                if (seatNumber) {
                    acc.push(seatNumber);
                }
                return acc;
            }, []);
    }

    @computed get isBaggageExistsInDeal() {
        const services = access(this.data, 'data.additionalServices') || [];
        let found = false;

        services.forEach((service) => {
            if ((service.type === 1 || service.type === 4)) {
                found = true;
            }
        });

        return found;
    }

    @computed get areSeatsExistsInDeal() {
        const services = access(this.data, 'data.additionalServices') || [];
        let found = false;

        services.forEach((service) => {
            if ((service.type === 3)) {
                found = true;
            }
        });

        return found;
    }

    @computed get orderStatus() {
        let firstDate = moment(access(this.data, 'data.flights[0].legs[0].arrivalTime')).isAfter(moment());
        let secondDate = moment(access(this.data, 'data.flights[1].legs[0].arrivalTime')).isAfter(moment());
        return access(this.data, "data.status.id") !== 0 && firstDate && secondDate;
    }

    @computed get extraServicesInDeal() {
        const services = access(this.data, 'data.additionalServices') || [];
        const uniqueTypes = [];
        return services.filter((service) => {
            if (uniqueTypes.includes(service.type)) {
                return false;
            }
            uniqueTypes.push(service.type);
            return [8, 9].includes(service.type)
        }); // 8 = ASVC, 9 = PRIO
    }

    @computed get checkInData() {
        return access(this.data, '.data.flightCheckInInfo');
    }

    @action checkInCurrentDirection(guid) {
        const flightsArr = access(this.data, 'data.flights');
        if (flightsArr && flightsArr.length > 0) {
            const flightInd = flightsArr.findIndex(flight => flight.guid === guid);
            return this.root.checkInUIStore.setCurrentDirection(flightInd);
        }
    }

    @computed get currentFlightAllSeatsPaid() {
        let flightIndex = this.root.isWebCheckIn ?
            this.root.checkInUIStore.curDirection : access(this.root.seatsStore.currentFlight, '.flightIndex');
        return this.root.passengersStore.passengersExcludeInfants.every(p => {
            return flightIndex === 0 ?
                this.seat1IsPaid(p.id - 1) : this.seat2IsPaid(p.id - 1);
        });
    }

    @action performDownloadBoardingPass(encrypPnr) {
        const now = Date.now();
        if (this.root.checkInUIStore.lastClicked && now - this.root.checkInUIStore.lastClicked < 2000) {
            // Preventing multiple clicks within 2 seconds
            console.log("downloading pdf is still in the process")
            return;
        }
        this.root.checkInUIStore.setLastClicked(now);
        this.root.checkInUIStore.setIsLoading(true);
        const encryptedPnr = encrypPnr || access(this.data, '.data.encryptedPnr');
        transport.downloadBoardingPass(encryptedPnr)
            .then((response) => {
                const blob = new Blob([response.data]);
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'boarding-pass.pdf');
                document.body.appendChild(link);
                link.click();
                console.log("response", response.data);
            })
            .catch((e) => {
                console.log(access(e.response, '.status'));
                // this.root.seatsStore.showErrorMessage(true, e.response.data);
            })
            .finally(() => {
                this.root.checkInUIStore.setIsLoading(false);
            })
    }

    @action performSendBoardingPass() {
        this.root.checkInUIStore.setBoardingPassLoading(true);
        const encryptedPnr = access(this.data, '.data.encryptedPnr');
        const bpDetails = access(this.root.checkInUIStore, '.boardingPass');
        const email = bpDetails.type === "email" || bpDetails.id === 2 ? bpDetails.inputValue : null;
        const phoneNumber = bpDetails.type === "phone" || bpDetails.id === 1 ? bpDetails.inputValue : null;
        const data = {
            "encryptedPnr": encryptedPnr,
            "email": email,
            "phoneNumber": phoneNumber
        }
        transport.sendBoardingPass(data, {responseType: 'arraybuffer'})
            .then((response) => {
                console.log("response", response.data);
                if (response.data.status && response.data.status === "ERROR") {
                    const errMsg = access(response.data,'.error[0].message');
                    this.root.alertsStore.showAlert(errMsg,'error');
                    return this.root.seatsStore.showErrorMessage(true, errMsg)
                }
                this.root.checkInUIStore.setBoardingPassDetails("sent");
            })
            .catch((e) => {
                console.log("error", e);
                this.root.checkInUIStore.setBoardingPassLoading(false);
                const msg = access(e.response,'.data.error[0].message') ? e.response.data.error[0].message: "Unknown error";
                this.root.alertsStore.showAlert(msg,'error');
                this.root.seatsStore.showErrorMessage(true, msg);
            })
            .finally(() => {
                this.root.checkInUIStore.setBoardingPassLoading(false);
                // this.email = null;
                // this.phoneNumber = null;
            })
    }

    @computed get showBaggageWarning() {
        if (!this.data) {
            return false;
        }

        const pnr = getActivePNR();
        if (!pnr) {
            return false;
        }
        
        let showWarning = false;
        try {
            if (parseInt(getActivePNR(),10) <= 50381067) {
                showWarning = true;
            }
        }
        catch(e) {
            console.error("can't parse pnr")
        }

        return showWarning;
    }
}

export {PNRStore}