import React from 'react';
import 'app/desktop/ui/components/sidebar/sidebar.scss';
import {inject, observer} from "mobx-react";
import {history} from 'app/services/custom.router'
import {BaggageSidebarTable} from "app/desktop/ui/pages/dashboard/components/baggage-table/sidebar.table";
import {
    SeatsSidebarTable,
    WebCheckSeatsSidebarTable
} from "app/desktop/ui/pages/dashboard/components/seats/seats.sidebar.table";
import {ExtraServicesSidebarTable} from "app/desktop/ui/pages/dashboard/components/extra-services/extra-services.sidebar.table";
import {StepTypes} from "app/stores/steps.store";
import {Scrollbar} from "react-scrollbars-custom";
import access from "safe-access";
import {SidebarRow} from "app/desktop/ui/components/sidebar/components/sidebar.row";
import {Continue, FinalSidebar} from "app/desktop/ui/components/sidebar/components/steps-buttons/sidebar.buttons";



@inject ('sidebarStore', 'pnrStore', 'baggageStore', 'extraServicesStore', 'stepsStore', 'seatsStore') @observer
class Sidebar extends React.Component {

    render() {
        //console.log("render sidebar", this.props.final);

        if (!(this.props.baggageStore.isBaggageAvailable || this.props.seatsStore.areSeatsAvailable || this.props.extraServicesStore.areExtraServicesAvailable)) {
            return null;
        }
        const stepsStore = this.props.stepsStore;

        const s = this.props.sidebarStore,
              ps = this.props.pnrStore;

        const button = (stepsStore.currentStep === StepTypes.PAYMENT) ? <FinalSidebar/> : <Continue/>

        const baggageRow = (this.props.baggageStore.isBaggageAvailable) ?
            <SidebarRow title={"כבודה"}>
                <BaggageSidebarTable/>
        </SidebarRow> : "";

        const seatsRow = (this.props.seatsStore.areSeatsAvailable) ? <SidebarRow title={"הושבה"}>
            <SeatsSidebarTable/>
        </SidebarRow> : "";

        const extraServicesRow = this.props.extraServicesStore.areExtraServicesAvailable ? <SidebarRow title={"שירותים נוספים"}>
            <ExtraServicesSidebarTable />
        </SidebarRow> : null;

        return (
            <div className={"cs-sidebar"}>
                <div className={"cs-sidebar-area"}>
                <Scrollbar noDefaultStyles>
                    <div className={"sidebar-row header"}>
                        <div className={"cs-sidebar-title"}>פרטי ההזמנה</div>
                        <div className={"order-type"}>{s.orderType}</div>

                    <div className={"dates"}>
                        <div>יציאה: יום {ps.day1Short}' <span className={"num"}>{ps.date1Short}</span> </div>
                        {!ps.isOneWayFlight && <div>חזרה: יום {ps.day2Short}' <span className={"num"}>{ps.date2Short}</span> </div>}
                    </div>
                    </div>
                    <div className={"sidebar-row body"}>
                        {baggageRow}
                        {seatsRow}
                        {extraServicesRow}
                    </div>

                </Scrollbar>
                </div>
                <div className={"cs-sidebar-footer"}>
                    {button}
                </div>
            </div>
        )
    }
}

export {Sidebar}


@inject ('sidebarStore', 'pnrStore', 'baggageStore', 'extraServicesStore', 'seatsStore', 'passengersStore', 'checkInUIStore') @observer
class SidebarWebCheck extends React.Component {

    render() {
        const chk = this.props.checkInUIStore;
        const pnr = this.props.pnrStore;
        const bg = this.props.baggageStore;
        const s = this.props.seatsStore;

        // todo: move the logic to store
        const passengers = access(this.props.baggageStore, '.passengers');

        const isSeatAdded = pnr.reservedSeats.length > 0;

        const baggageRow = (bg.isBaggageAvailable && bg.baggageTotalPriceByDirection > 0 || true) &&
            <SidebarRow title={"כבודה"}>
                <BaggageSidebarTable/>
            </SidebarRow>;

        const seatsRow = (s.areSeatsAvailable && isSeatAdded) &&
            <SidebarRow title={"הושבה"}>
                <WebCheckSeatsSidebarTable/>
            </SidebarRow>

        if (!baggageRow && !seatsRow) {
            return null;
        }

        return (
            <div className={"web-check-wrapper"}>
                <div className={"cs-deal"}>
                    <div className={"cs-deal-title"}>
                        <div className={"main-checkin"}>פירוט התשלום</div>
                    </div>
                    <div className={"cs-sidebar-webcheck"}>
                        {seatsRow}
                        {baggageRow}
                </div>
                </div>
            </div>
        )
    }
}

export {SidebarWebCheck};