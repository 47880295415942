import {inject, observer} from "mobx-react";
import React from "react";
import {ModalDialog} from "app/desktop/ui/components/modal/modal";
import 'app/desktop/ui/components/dialogs/dlg-add-baggage/dlg.add.baggage.scss';
import {RegularButton} from "app/desktop/ui/components/buttons/regular-button/regular.button";
import {BlueButton} from "app/desktop/ui/components/buttons/blue-button/blue.button";
import {history} from 'app/services/custom.router'
import {StepTypes} from "app/stores/steps.store";


@inject("dashboardUIStore", "stepsStore") @observer
class DlgAddBaggage extends React.Component {
    render() {
        const dlg = this.props.dashboardUIStore.addBaggageDialog;
        const s = this.props.dashboardUIStore;
        const stepsStore = this.props.stepsStore;

        return (
            <ModalDialog isOpen={dlg.isOpen} onClose={() => {
                dlg.closePopup();
            }}>
                <div className={"dlg-add-baggage"}>
                    <div className={"wrap"}>
                        <div className={"header-image"}/>
                        <div className={"text"}>
                            אין בעיה, שימו לב<br/>
                            עלות הוספת מזוודה בשדה התעופה<br/>
                            <strong>גבוהה משמעותית מהזמנה באתר</strong>
                        </div>
                        <div className={"buttons"}>
                            <div className={"btn-1"}>
                                <RegularButton onClick={() => {
                                    dlg.closePopup();
                                    const nextStep = stepsStore.nextStep;
                                    stepsStore.setStep(nextStep.type);
                                    history.push(nextStep.path);
                                }}>הבנתי, אני מגיע בלי מזוודה</RegularButton>
                            </div>
                            <div className={"btn-2"}>
                                <BlueButton onClick={() => {
                                    dlg.closePopup();
                                    s.setStep(StepTypes.BAGGAGE);
                                    history.push('/dashboard/baggage');
                                }}><span className={"symbol"}>+</span> <span>הזמן מזוודה</span></BlueButton>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalDialog>
        )
    }
}

export {DlgAddBaggage}