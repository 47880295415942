import React from 'react'
import 'app/desktop/ui/components/txt-field-small/txt.field.small.scss'
import {observer} from "mobx-react";
import classnames from 'classnames';

@observer
class TxtFieldSmall extends React.Component {
    render() {
        const css = classnames("txt-field-small", {"eng" : this.props.isEng}, {"error" : this.props.isError}, {"disabled" : this.props.disabled});

        return (
            <div className={css}>
                <input type={this.props.type}
                        value={this.props.value}
                       placeholder={this.props.placeholder}
                       onChange={this.props.onChange}
                       disabled={this.props.disabled}
                       maxLength={this.props.maxLength}
                       autoComplete={this.props.autoComplete}
                       pattern={this.props.pattern}
                />
            </div>
        )
    }
}

TxtFieldSmall.defaultProps = {
    isEng : false,
    type : "text",
    placeholder : "",
    value : "",
    isError : false,
    disabled : false,
    maxLength : 524288,
    autoComplete : "",
    pattern : "",
    onChange : () => {}
}

export {TxtFieldSmall}