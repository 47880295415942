import React from 'react'
import GenericModal from "app/desktop/ui/components/generic-modal/generic.modal";
import {inject, observer, Provider} from "mobx-react";
import allCheckinStores from "app/stores/all.checkin.stores";
import CheckinFooter from "app/desktop/checkin/footer/checkin.footer";
import ActiveScreen from "app/desktop/checkin/active-screen/active.screen";
import {CheckInHeader} from "app/desktop/checkin/active-screen/checkin.header";
import {DlgRestrictedSeats} from "app/desktop/ui/components/dialogs/dlg-restricted-seats/dlg.restricted.seats";
import {SeatsModal} from "app/desktop/checkin/modals/seats.modal";
import {BaggageModal} from "app/desktop/checkin/modals/baggage.modal";
import {TicketModal} from "app/desktop/checkin/modals/ticket.modal";

@inject ("checkInPopup", "seatsStore", "checkInUIStore", "dashboardUIStore") @observer
class WebCheckInModal extends React.Component {
    render() {
        const dlg = this.props.checkInPopup;
        const chk = allCheckinStores.checkInUIStore;
        const dshb = this.props.dashboardUIStore.closeCheckInConfirmation;

        const handleClosePopup = () => {
            if (!chk.isConfirmationScreen) {
                return dshb.openPopup();
            }

            dlg.closePopup();
            this.props.seatsStore.deleteAllReservedSeats();
            // // if (chk.isConfirmationScreen) {
            // //     pnr.loadData(getActivePNR());
        }

        return (
            <Provider {...allCheckinStores}>
            <GenericModal.Popup isOpen={dlg.isOpen} onClose={() => {
                handleClosePopup();
            }}>
                <GenericModal.Header>
                    <CheckInHeader/>
                    <div className={"text"}>ביצוע צ’ק אין אונליין</div>
                </GenericModal.Header>
                <GenericModal.Section>
                    <ActiveScreen/>

                </GenericModal.Section>

                <GenericModal.Footer>
                    <CheckinFooter/>
                </GenericModal.Footer>

                <DlgRestrictedSeats/>
                <SeatsModal/>
                <BaggageModal/>
                <TicketModal/>

            </GenericModal.Popup>
            </Provider>
        )
    }
}

export default WebCheckInModal