import { inject, observer } from "mobx-react";
import React from "react";
import {Popup} from "app/desktop/ui/components/popup/popup";
import { history } from 'app/services/custom.router';
import { BlueButton } from "app/desktop/ui/components/buttons/blue-button/blue.button";
import Service from 'app/desktop/ui/components/dialogs/dlg-extra-services/ExraService/extra-serivce';
import {MainButton} from "app/desktop/ui/components/buttons/main-button/main.button";
import { StepTypes } from "app/stores/steps.store";
import {ButtonContinue} from "app/desktop/ui/components/buttons/button-continue/button.continue";
import InfoIcon from "app/desktop/ui/components/info-icon/info.icon"
import BlueBirdVipLogo from "app/desktop/ui/components/bluebird-vip-logo/bluebird.vip.logo";
import 'app/desktop/ui/components/dialogs/dlg-extra-services/dlg.extra.services.scss';
import access from "safe-access";

@inject ("dashboardUIStore", "stepsStore", "baggageStore", "pnrStore", "passengersStore", "extraServicesStore") @observer
class DlgExtraServices extends React.Component {



  render() {
    const dlg = this.props.dashboardUIStore.extraServicesDialog;
    const passengersNum = this.props.passengersStore.passengersExcludeInfants.length;
    const s = this.props.extraServicesStore;

    return (
      <Popup isOpen={dlg.isOpen}
        closeBtn={false}
        onClose={() => dlg.closePopup()}
        footer={
          <div className="col-btn-continue">
            <div className={"price-col"}>
              <div className="text">סה״כ תוספת תשלום<br/>עבור הוספת הושבה</div>
              <div className="price">{this.props.pnrStore.currencySymbol}{this.props.extraServicesStore.totalPrice}</div>
            </div>
            <ButtonContinue onClick={() => dlg.closePopup()}>המשך לסיום</ButtonContinue>
          </div>
        }
      >
        <div className={"dlg-extra-services sub-wrap"}>
          <button className={"cs-popup-close"} onClick={() => dlg.closePopup()} />
          <div className={"dlg-body"}>
            <div className={"dlg-title wrap"}><BlueBirdVipLogo />בלובירד פריוריטי פס
              <div className={"row little-text"}>רוצים להימנע מהמתנה ארוכה בתורים בטיסתכם חזרה ארצה ? יש לנו פתרון!</div>
            </div>
            <div className={"dlg-content"}>
              <h3>אנא בחרו את השירות המבוקש</h3>
                {s.priorityPRIO && (
                    <Service 
                      title="שירות Priority בדלפקי הצ'ק אין" 
                      text="דלפק צ&apos;ק אין לנוסעי ה-Priority בלבד, בו תוכלו למסור את הכבודה ולקבל כרטיסי עלייה למטוס, ללא המתנה."
                      price={access(s.priorityPRIO, '.price')}
                      totalPrice={access(s.priorityPRIO,'.price') * passengersNum}
                      currencySymbol={this.props.pnrStore.currencySymbol}
                      onToggle={() => s.toggleService(s.priorityPRIO)}
                      added={s.addedServices.some(service => service.code === s.priorityPRIO.code)}
                      disabled={s.addedServices.some(service => service.code === 'ASVC')}
                    />
                )}
              {(s.purchasedPRIO && s.priorityASVS) && (
                  <Service
                      title="שירות Priority בדלפקי הצ'ק אין"
                      text="דלפק צ&apos;ק אין לנוסעי ה-Priority בלבד, בו תוכלו למסור את הכבודה ולקבל כרטיסי עלייה למטוס, ללא המתנה."
                      totalPrice={0}
                      currencySymbol={this.props.pnrStore.currencySymbol}
                      added
                      disabled
                      purchasedPRIO

                  />
              )}
                {s.priorityASVS && (
                  <div className={"dlg-section"}>
                    <Service
                        title="שירות משולב - Priority בדלפקי הצ'ק אין + בידוק ביטחוני מהיר"
                        text={"דלפק צ'ק אין לנוסעי ה-Priority בלבד, בו תוכלו למסור את הכבודה ולקבל כרטיסי עלייה למטוס, ללא המתנה, בשילוב מסלול בידוק ביטחוני מהיר."}
                      price={(s.purchasedPRIO) ?  access(s.priorityASVS,'.price') : access(s.priorityASVS,'.price') + access(s.priorityPRIO,'.price')}
                      priceASVC={access(s.priorityASVS,'.price')}
                      totalPrice={access(s.priorityASVS,'.price') * passengersNum}
                      currencySymbol={this.props.pnrStore.currencySymbol}
                      onToggle={() => s.toggleService(s.priorityASVS)}
                      added={this.props.extraServicesStore.addedServices.some(service => service.code === s.priorityASVS.code)}
                        purchasedPRIO={s.purchasedASVC}
                        disabled={s.purchasedASVC}
                      // disabled={s.addedServices.some(service => service.code === 'PRIO')}
                    />
                  </div>
                )}
                <div className={"dlg-section dlg-extra-services__note"}>
                  <InfoIcon />
                  <p>השירות ניתן על ידי חברת התעופה  <span className={"strong"}> Blue Bird Airways </span><br />
                    בשל סיבות מבצעיות שונות ייתכן ולא ניתן יהיה לספק את השירות בטיסות מסוימות.
                      במקרה כזה יינתן החזר כספי מלא בגובה הסכום ששולם, ובאישור ההזמנה מאשר הלקוח כי הוא מודע לכך ומקבל תנאי זה במלואו.
                      מובהר כי השירותים ניתנים במלואם מחוץ לישראל ושיעור דמי הביטול בקשר אליהם עומד על 100%
                      . לפיכך, לא יינתן כל החזר כספי בגין אי מימוש השירות מצד הלקוח מכל סיבה שהיא, למעט בשל סיבה הקשורה לחברת התעופה.
                  </p>
                </div>
            </div>
          </div>
          {/* <div className={"sticky-footer"}>
            <div>סה״כ תוספת תשלום <br />עבור השירות</div>
            <div className="dlg-extra-services__total-price">{this.props.pnrStore.currencySymbol}{this.props.extraServicesStore.totalPrice}</div>
            <ButtonContinue onClick={() => dlg.closePopup()}>סיים</ButtonContinue>
          </div> */}
        </div>
        {/* <div className="sticky-footer">
          <div className="wrap">
            <div className="col-btn-continue">
              <ButtonContinue onClick={() => dlg.closePopup()}>סיים</ButtonContinue>
            </div>
          </div>
        </div> */}
      </Popup>
    )
  }

  
}

export { DlgExtraServices }