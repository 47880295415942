import {inject, observer} from "mobx-react";
import React from "react";
import {TableFooter} from "app/desktop/ui/pages/dashboard/components/deal-details/table.footer";

@inject("baggageStore") @observer
class Footer extends React.Component {
    render() {
        const {baggageStore} = this.props;

        return (
            <TableFooter currency={baggageStore.currency} price={baggageStore.totalBaggagePrice}>
                סה״כ תוספת תשלום<br/>
                עבור הוספת כבודה
            </TableFooter>
        )
    }
}

export {Footer};