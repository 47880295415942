import React from 'react';
import { inject, observer } from "mobx-react";
import { StepTypes } from "app/stores/steps.store";
import { BlueButton } from "app/desktop/ui/components/buttons/blue-button/blue.button";
import { ReactComponent as StarPlusIcon } from "app/desktop/images/icons/star-plus.svg";
import BlueBirdVipLogo from "app/desktop/ui/components/bluebird-vip-logo/bluebird.vip.logo";
import 'app/desktop/ui/pages/dashboard/components/extra-services/extra-services.page.scss';

@inject('dashboardUIStore', 'stepsStore', 'extraServicesStore') @observer
class ExtraServicesPage extends React.Component {

  componentDidMount() {
    this.props.stepsStore.setStep(StepTypes.EXTRA_SERVICES);    
  }

  render() {
    const { addedServices } = this.props.extraServicesStore;
    const mainBtnLabel = addedServices.length ? 'עדכן / הסר' : (
      <React.Fragment>
        <span className={"plus-sign"}>+</span>
        <span>הוסף שירות</span>
      </React.Fragment>
    );

    return (
      <div className={"cs-extra-services"}>
        <div className={"cs-extra-services__title"}>
            <StarPlusIcon />
            <span className={"main"}>שירותים נוספים</span>
            <span>אנחנו כאן בשבילכם</span>
        </div>
        <BlueBirdVipLogo />
        <div className={"cs-extra-services__content"}>
            <div>
              <h3>בלובירד פריוריטי פס</h3>
              <p>כחלק מהמאמץ לקצר עבורכם את זמני ההמתנה, חברת התעופה Blue Bird Airways מאפשרת לרכוש שירות VIP למעבר מהיר וקיצור התורים בשדה התעופה 
                 בדרככם חזרה ארצה.<br/>
              </p>
                <p className={"little-text"}>את השירות ניתן לרכוש עבור כל הנוסעים בהזמנה <u>בלבד</u> (מבוגרים וילדים, ללא תינוקות).<br/> שימו לב כי בשלב זה השירות אינו פעיל בשדה התעופה בן- גוריון.</p>
            </div>
            <BlueButton onClick={() => this.props.dashboardUIStore.extraServicesDialog.openPopup()}>{mainBtnLabel}</BlueButton>
          </div>
      </div>
    )
  }

}

export {ExtraServicesPage};