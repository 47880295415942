import React from 'react';
import classnames from "classnames";


//conditional classes gives moduleness and prevents clashes
if (process.env.REACT_APP_MODE === 'mobile') {
    require('app/mobile/ui/components/modal-baggage/modal.baggage.scss');
} else {
    require('app/desktop/ui/components/modal-baggage/modal.baggage.scss');
}



class ModalBaggage extends React.Component {


    render() {
        const { isOpen , children } = this.props;
        const css = classnames('popup-wrapper popup-wrapper-resolution',{'boarding-pass-resolution': this.props.boardingPass}, {'popup-wrapper popup-wrapper--hidden': isOpen});

        const externalClick = (e) => {
            e.stopPropagation();
            if (this.props.closeOnExternal) {

                this.props.onClose();
            }
        }

        return(
            <>
                <div className={"modal-baggage-wrapper"}>
                {isOpen &&
                    <>
                    <div className="overlay" onClick={externalClick} />
                        <div className={css}>
                            {this.props.closeBtn && (
                            <div className={"modal-baggage-close"} onClick={this.props.onClose}/>
                        )}
                            {children}
                        </div>
                    </>
                }
                </div>
            </>
        )
    }
}

ModalBaggage.defaultProps = {
    isOpen : false,
    modal : true,
    onClose : () => {},
    closeOnExternal: true,
    closeBtn: true,
    boardingPass: false
}

export default ModalBaggage;