import React from 'react';
import {inject, observer} from "mobx-react";
import 'app/desktop/ui/pages/dashboard/components/seats/seats.table.scss';
import {BlueButton} from "app/desktop/ui/components/buttons/blue-button/blue.button";
import classnames from "classnames";
import {truncatedText} from "app/services/validator";

@inject("seatsStore","pnrStore","dashboardUIStore","passengersStore") @observer
class SeatsTable extends React.Component {
    render() {
        const s = this.props.seatsStore,
              pnr = this.props.pnrStore,
              pStore = this.props.passengersStore;

        const orderedRowClass = id => classnames("row", { "row-disabled":
                pnr.seat1IsPaid(id) && pnr.seat2IsPaid(id)});
        const greyBoxClass = id => classnames("grey-box", { "box-disabled":
                pnr.seat1IsPaid(id) && !pnr.seat2IsPaid(id)});
        const greyBoxReturnClass = id => classnames("grey-box", { "box-disabled":
                pnr.seat2IsPaid(id) && !pnr.seat1IsPaid(id)});
        const buttonDisabled = id => classnames("col c-button", { "blue-button-disabled":
                pnr.seat1IsPaid(id) && pnr.seat2IsPaid(id)});

        const backTitle = pnr.isTwoWayFlight && <div className={"col c-seat"}>חזור</div>;
        const backFlightContainer = (p) => pnr.isTwoWayFlight && <div className={"col c-seat"}>
            <div className={greyBoxReturnClass(p.id-1)}>{pnr.seat2IsPaid(p.id-1) || p.seat2Number}</div>
        </div>

        return (
            <div className={"seats-table"}>
                {pStore.passengersExcludeInfants.map((p, index) => {
                    const btnText = (p.seat1 || p.seat2) ? "ערוך / הסר" :<React.Fragment><span className={"plus-sign"}>+</span> בחר מושב </React.Fragment>;
                    return (
                        <React.Fragment key={"row_"+index}>
                            <div className={"row header"}>
                                <div className={"col c-num"}/>
                                <div className={"col c-name"}>שם הנוסע</div>
                                <div className={"col c-seat"}>הלוך</div>

                                {/*render if it's round flight*/}
                                {backTitle}

                                <div className={"col c-price"}>סה״כ</div>
                                <div className={"col c-button"}>&nbsp;</div>
                            </div>
                            <div className={orderedRowClass(p.id-1)}>
                                <div className={"col c-num"}>
                                    <div className={"p-num num"}>{index+1}</div>
                                </div>
                                <div className={"col c-name"}>
                                    <div className={"grey-box"} title={p.displayName}>{truncatedText(p.displayName)}</div>
                                </div>

                                <div className={"col c-seat"}>
                                    <div className={greyBoxClass(p.id-1)}>{pnr.seat1IsPaid(p.id-1) || p.seat1Number}</div>
                                </div>

                                {/*render if it's round flight*/}
                                {backFlightContainer(p)}

                                <div className={"col c-price"}>
                                    <span className={"num"}>{pnr.currencySymbol}{p.selectedSeatsPrice}</span>
                                </div>

                                <div className={buttonDisabled(p.id-1)}>
                                    <BlueButton
                                        onClick={(e) => {
                                        // console.log("click");
                                        e.preventDefault();
                                        s.setFlight1ActiveIndex(s.findFirstNotPaid(p.id-1));
                                        s.setFlight2ActiveIndex(s.find2FirstNotPaid(p.id-1));
                                        // s.setActiveFlight(s.isFlightAllSeatsPaid ? 1 : 0);
                                        s.setActiveFlight(0);
                                        this.props.dashboardUIStore.seatSelectionDialog.openPopup();
                                    }}> {btnText}</BlueButton>
                                </div>


                            </div>
                        </React.Fragment>
                        )

                })}
            </div>
        );
    }
}

export {SeatsTable}