import React from 'react'
import {DlgSeatSelectionWebCheck} from "app/desktop/ui/components/dialogs/dlg-seat-selection/dlg.seat.selection";

class SeatsScreen extends React.Component {
    render() {
        return (
            <div>
                <DlgSeatSelectionWebCheck/>
            </div>
        )
    }
}

export default SeatsScreen;