import React from 'react'
import {RegularButton} from "app/desktop/ui/components/buttons/regular-button/regular.button";
import {BlueButton} from "app/desktop/ui/components/buttons/blue-button/blue.button";
import {inject, observer} from "mobx-react";
import {ModalDialog} from "app/desktop/ui/components/modal/modal";

import 'app/desktop/ui/components/dialogs/dlg-restricted-seats/dlg.restricted.seats.scss';

@inject ("dashboardUIStore", "seatsStore", "isWebCheckIn") @observer
class ClosePopupModal extends React.Component {
    render() {
        const dlg = this.props.dashboardUIStore.closeCheckInConfirmation;
        const s = this.props.seatsStore;

        return (
            <ModalDialog isOpen={dlg.isOpen} onClose={() => {
                dlg.closePopup();
            }}>
                <div className={"dlg-restricted-seats dlg-close-popup-wrapper"}>
                    <div className={"wrap"}>
                        <div className={"icon"}/>
                        <div className={"title centered"}>שים לב, </div>
                        <div className={"sub-title centered"}> ביציאה מתהליך הצ'ק אין, הנתונים אינם נשמרים <br/>ויש לבצע את התהליך מחדש</div>

                        <div className={"buttons"}>
                            <div className={"btn-1"}>
                                <RegularButton onClick={() => {
                                    dlg.closePopup();
                                }}>חזור לביצוע צ'ק אין</RegularButton>
                            </div>
                            <div className={"btn-2 close-popup-btn"}>
                                <BlueButton onClick={() => {
                                    dlg.closePopup();
                                    s.deleteAllReservedSeats();
                                }}>הבנתי, לא נבצע צ'ק אין</BlueButton>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalDialog>
        )
    }
}

export {ClosePopupModal}