import {inject, observer} from "mobx-react";
import React from "react";
import {ModalDialog} from "app/desktop/ui/components/modal/modal";
import 'app/desktop/ui/components/dialogs/dlg-seats-details/dlg.seats.details.scss';
import {RegularButtonSmall} from "app/desktop/ui/components/buttons/regular-button/regular.button";
import {history} from 'app/services/custom.router'
import {StepTypes} from "app/stores/steps.store";

@inject ("dashboardUIStore", "stepsStore", "baggageStore", "seatsStore", "pnrStore", "passengersStore") @observer
class DlgSeatsDetails extends React.Component {
    render() {
        const dlg = this.props.dashboardUIStore.seatsDetailsDialog;
        const s = this.props.passengersStore;
        // style={{"left": dlg.x, "top": dlg.y}}

        const handleBtnClick = (e) => {
            e.preventDefault();
            dlg.closePopup();

            this.props.stepsStore.setStep(StepTypes.SEATS);
            history.push('/dashboard/seats');
        }
        return (
            <ModalDialog isOpen={dlg.isOpen} modal={false} onClose={() => {
                dlg.closePopup();
            }}>
                <div className={"dlg-seats-details"} >
                    <div className={"wrap"}>
                        <div className={"row header"}>
                            פירוט הושבה

                            <div className={"btn-popup-close"} onClick={(e) => {e.preventDefault();dlg.closePopup();}}/>
                        </div>
                        <div className={"row body"}>
                            <div className={"row table-header"}>
                                <div className={"col name"}>
                                    שם הנוסע
                                </div>

                                <div className={"col dir"}>
                                    הלוך
                                </div>

                                <div className={"col dir"}>
                                    חזור
                                </div>

                                <div className={"col price"}>
                                    סה״כ
                                </div>
                            </div>

                            {s.passengers.map((p,index) => {
                                return (
                                    <div className={"row"} key={"p"+index}>
                                        <div className={"col name"}>
                                            <span className={"num"}>{index+1}.</span> {p.fullName}
                                        </div>

                                        <div className={"col dir"}>
                                            {p.seat1Number}
                                        </div>

                                        <div className={"col dir"}>
                                            {p.seat2Number}
                                        </div>

                                        <div className={"col price"}>
                                            {this.props.pnrStore.currencySymbol}{p.selectedSeatsPrice}
                                        </div>

                                    </div>
                                )
                            })}
                        </div>
                        <div className={"row footer"}>
                            <div className={"col-1"}>
                                <RegularButtonSmall onClick={handleBtnClick}>ערוך / הסר הושבה</RegularButtonSmall>
                            </div>
                            <div className={"col-2"}>
                                מחיר הושבה כולל <span className={"price"}>{this.props.pnrStore.currencySymbol}{s.totalSeatsPrice}</span>
                            </div>
                        </div>
                    </div>
                </div>

            </ModalDialog>
        );
    }
}

export {DlgSeatsDetails}