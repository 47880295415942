import React from 'react';
import {inject, observer} from "mobx-react";
import InputMask from 'react-input-mask';
import Gender from "app/desktop/checkin/passenger-details/gender";
import {Dropdown} from "app/desktop/ui/components/dropdown-with-label/dropdown.with.label";
import classnames from "classnames";
import PassengerType from "app/enums/passenger.types";
import access from "safe-access";

require('./passenger.details.scss');


@inject("passengerDetailsStore", "checkInUIStore") @observer
class PassengerDetails extends React.Component {
    constructor(props) {
        super(props);
        this.genderRef = React.createRef();
        this.birthdayRef = React.createRef();
        this.nationalityRef = React.createRef();
        this.countryRef = React.createRef();
        this.passportRef = React.createRef();
        this.expirationRef = React.createRef();
        this.mobilePhoneRef = React.createRef();
        this.emailRef = React.createRef();
    }

    scrollToFirstInvalidField(passenger) {
        const refs = [
            { ref: this.genderRef, isValid: passenger.gender.isValid },
            { ref: this.birthdayRef, isValid: passenger.birthDay.isValid },
            { ref: this.nationalityRef, isValid: passenger.nationality.isValid },
            { ref: this.countryRef, isValid: passenger.country.isValid },
            { ref: this.passportRef, isValid: passenger.passport.isValid },
            { ref: this.expirationRef, isValid: passenger.passportExpirationDate.isValid },
            { ref: this.mobilePhoneRef, isValid: passenger.mobilePhone.isValid },
            { ref: this.emailRef, isValid: passenger.email.isValid },
        ];

        const firstInvalidRef = refs.find(r => !r.isValid);
        if (firstInvalidRef) {
            const currentRef = access(firstInvalidRef,'.ref.current');
            currentRef && currentRef.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }
    }

    render() {
        const dtl = this.props.passengerDetailsStore;
        const p = this.props.passenger;
        const chk = this.props.checkInUIStore;
        const {countries} = this.props;

        const splitName = p.fullName.split(' ');
        let firstName = "",
            lastName = "";

        if (splitName.length === 2) {
            firstName = splitName[0];
            lastName = splitName[1];
        } else if (splitName.length > 2) {
            lastName = splitName.pop();
            firstName = splitName.join(' ');
        }

        if (chk.invalidPassengerId === p.id) {
            this.scrollToFirstInvalidField(p);
        }

        const isInfant = p.passengerType === PassengerType.INFANT;

       const cssCheckbox = classnames("checkbox", { "checked": p.allowReceivingAds });

        return (
            <div className={"p-details"}>
                <div className={"col-num"}>
                    <div className={"p-num"}>{p.number}</div>
                </div>
                <div className={"col-details"}>
                    <div className={"row"}>
                        <div className={"col-field"}>
                            <div className="title">שם הנוסע</div>
                            <div className={"static-field"}>{firstName}</div>
                        </div>

                        <div className={"col-field"}>
                            <div className="title">שם משפחה</div>
                            <div className={"static-field"}>{lastName}</div>
                        </div>

                        <div className={"col-field"} ref={this.genderRef}>
                            <div className="title">&nbsp;</div>
                            <Gender gender={p.gender.value} passenger={p} passengers={dtl}/>
                            <div className={"validation-msg"}>{p.gender.errMsg}</div>
                        </div>

                        <div className={"col-field"} ref={this.birthdayRef}>
                            <div className="title">תאריך לידה</div>
                            <InputMask mask="99/99/9999"
                                       value={p.birthDay.value}
                                       placeholder="dd/mm/yyyy"
                                       onChange={(event)=> dtl.setAttributeField("birthDay", event.target.value, p)}
                                       onBlur={() => dtl.validateField("birthDay", p)}
                                       maskChar=" "
                                       className={classnames("ltr", {"input-warning": p.birthDay.errMsg})}/>
                            <div className={"validation-msg"}>{p.birthDay.errMsg}</div>
                        </div>

                    </div>

                    <div className={"row"} ref={this.nationalityRef}>
                        <div className={"col-field"}>
                            <div className="title">אזרחות</div>
                            <Dropdown selectedText={p.nationality?.value.name || "בחר"}
                                      options={countries.filteredCountries}
                                      isDisabled={true}
                                      onSelect={(o) => {
                                          console.log("o", o);
                                          dtl.setAttributeField("nationality", o, p)
                                      }}/>
                            <div className={"validation-msg"}>{p.nationality.errMsg}</div>
                        </div>

                        <div className={"col-field"} ref={this.countryRef}>
                            <div className="title">הונפק ב:</div>
                            <Dropdown selectedText={p.country?.value.name || "בחר"}
                                      options={countries.allCountries}
                                      onSelect={(o) => {
                                           console.log("o", o);
                                          dtl.setAttributeField("country", o, p)
                                      }}/>
                            <div className={"validation-msg"}>{p.country.errMsg}</div>
                        </div>

                        <div className={"col-field"} ref={this.passportRef}>
                            <div className="title">מספר דרכון</div>
                            <input
                                maxLength={20}
                                value={p.passport.value}
                                onChange={(event)=> dtl.setAttributeField("passport", event.target.value, p)}
                                onBlur={() => dtl.validateField("passport", p)}
                                className={classnames("ltr", {"input-warning": p.passport.errMsg})}/>
                            <div className={"validation-msg"}>{p.passport.errMsg}</div>
                        </div>

                        <div className={"col-field"} ref={this.expirationRef}>
                            <div className="title">תוקף דרכון</div>
                            <InputMask mask="99/99/9999"
                                       value={p.passportExpirationDate.value}
                                       placeholder="dd/mm/yyyy"
                                       onChange={(event)=> dtl.setAttributeField("passportExpirationDate", event.target.value, p)}
                                       onBlur={() => dtl.validateField("passportExpirationDate", p)}
                                       maskChar=" "
                                       className={classnames("ltr", {"input-warning": p.passportExpirationDate.errMsg})}
                            />
                            <div className={"validation-msg"}>{p.passportExpirationDate.errMsg}</div>
                        </div>
                    </div>

                    {!isInfant &&
                        <div>
                            <div className={"row"}>
                                <div className={"col-field"} ref={this.mobilePhoneRef}>
                                    <div className="title">טלפון נייד</div>
                                    <input
                                        disabled={isInfant}
                                        type={"text"}
                                        pattern={"^[0-9]+$"}
                                        className={p.mobilePhone.errMsg && "input-warning"}
                                        maxLength={12}
                                        value={p.mobilePhone.value}
                                        onChange={(event) => dtl.setAttributeField("mobilePhone", event.target.value.replace(/[^0-9]/g, ''), p)}
                                        onBlur={() => dtl.validateField("mobilePhone", p)}
                                    />
                                    <div className={"validation-msg"}>{p.mobilePhone.errMsg}</div>
                                </div>

                                <div className={"col-field email"} ref={this.emailRef}>
                                    <div className="title">כתובת דוא״ל</div>
                                    <input
                                        type={"email"}
                                        disabled={isInfant}
                                        className={classnames("ltr", {"input-warning": p.email.errMsg})}
                                        maxLength={64}
                                        value={p.email.value}
                                        onChange={(event) => dtl.setAttributeField("email", event.target.value, p)}
                                        onBlur={() => dtl.validateField("email", p)}
                                    />
                                    <div className={"validation-msg"}>{p.email.errMsg}</div>
                                </div>

                            </div>

                            <div className={"row row-ads"}>
                                <span onClick={() => dtl.updateCheckbox("allowReceivingAds", p)}
                                      className={cssCheckbox}/>
                                <span className={"txt"}>אני מאשר/ת קבלת תוכן פרסומי כאמור&nbsp;
                                     <a rel="noopener noreferrer" target="_blank" href={"https://www.kavei.co.il/GeneralInfo/Privacy.aspx"}> בתנאים המפורטים </a>
                                    </span>
                            </div>
                        </div>
                    }

                </div>
            </div>
        )

    }
}

export default PassengerDetails;