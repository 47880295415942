import React from 'react'
import {observer, inject} from "mobx-react";

@inject ("seatsStore","pnrStore","dashboardUIStore", "passengersStore") @observer
class SeatsSidebarTable extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    render() {

        const s = this.props.seatsStore;
        const pStore = this.props.passengersStore;

        if (pStore.totalSeatsPrice === 0 && s.areSeatsAvailable) {
            return <div className={"alert"}>
                <div className={"icon-seats-b"}/>לא נבחרו<br/>
                מושבים</div>
        }

        if (s.totalBaggagePrice === 0) {
            return null;
        }

        let r1 = "",
            r2 = "",
            total = "";

        if (s.flight1SeatsCount>0) {
            r1 = (
                <div className={"st-row"}>
                    <div className={"col1"}>{s.flight1SeatsCount} מושבים נבחרו - לכיוון הלוך </div>
                    <div className={"col2"}>{this.props.pnrStore.currencySymbol}{s.flight1TotalPrice}</div>
                </div>
            )
        }

        if (s.flight2SeatsCount>0) {
            r2 = (
                <div className={"st-row"}>
                    <div className={"col1"}>{s.flight2SeatsCount} מושבים נבחרו - לכיוון חזור </div>
                    <div className={"col2"}>{this.props.pnrStore.currencySymbol}{s.flight2TotalPrice}</div>
                </div>
            )
        }

        const dlg = this.props.dashboardUIStore.seatsDetailsDialog;


        if (s.totalPrice > 0) {
            total = (
                <div className={"st-row total"}>
                    <div className={"col1"}>
                        <a href={"#"} ref={this.myRef} onClick={(e) => {
                            e.preventDefault();
                            const v = this.myRef.current.getBoundingClientRect();
                            // console.log("click event", JSON.stringify(v));
                            dlg.setPosition(v.x, v.y);
                            this.props.dashboardUIStore.seatsDetailsDialog.openPopup();
                        }}>הקלק לפירוט מלא</a>
                    </div>
                    <div className={"col2 total-price"}>
                        <span className={"heb"}>סה״כ</span> {this.props.pnrStore.currencySymbol}{s.totalPrice}
                    </div>
                </div>
            );
        }

        return (
            <div className={"sidebar-table"}>
                <React.Fragment key={"passenger"}>
                    {r1}
                    {r2}
                    {total}
                </React.Fragment>
            </div>
        )
    }
}


@inject ("seatsStore","pnrStore", "checkInUIStore") @observer
class WebCheckSeatsSidebarTable extends React.Component {
    render() {
        const s = this.props.seatsStore;
        const chk = this.props.checkInUIStore;
        const pnr = this.props.pnrStore;

        if (!s.selectedSeatsTotalPriceIsZero && s.areSeatsAvailable) {
            return <div className={"alert"}>
                <div className={"icon-seats-b"}/>לא נבחרו<br/>
                מושבים</div>
        }

        let r1 = "",
            r2 = "";

        if (s.selectedSeatsTotalPriceIsZero && chk.curDirection === 0) {
            r1 = (
                <div className={"st-row"}>
                    <div className={"col1"}>{s.selectedSeatsCount} מושבים נבחרו - לכיוון הלוך </div>
                    <div className={"col2"}>{pnr.currencySymbol}{s.flight1TotalPrice}</div>
                </div>
            )
        }

        if (s.selectedSeatsTotalPriceIsZero && chk.curDirection === 1) {
            r2 = (
                <div className={"st-row"}>
                    <div className={"col1"}>{s.selectedSeatsCount} מושבים נבחרו - לכיוון חזור </div>
                    <div className={"col2"}>{pnr.currencySymbol}{s.flight2TotalPrice}</div>
                </div>
            )
        }

        return (
            <div className={"sidebar-table"}>
                <React.Fragment key={"passenger"}>
                    {r1}
                    {r2}
                </React.Fragment>
            </div>
        )
    }
}

export {SeatsSidebarTable, WebCheckSeatsSidebarTable};