import React from 'react'
import {inject, observer} from "mobx-react";
import {history} from 'app/services/custom.router'

import {
    Router,
    Switch,
    Route
} from "react-router-dom";

import 'app/desktop/ui/pages/dashboard/dashboard.scss'
import {DashboardSteps} from "app/desktop/ui/components/dashboard-steps/dashboard.steps";
import {DealDetails} from "app/desktop/ui/pages/dashboard/components/deal-details/deal.details";
import {Sidebar} from "app/desktop/ui/components/sidebar/sidebar";
import {LoginInfo} from "app/desktop/ui/components/login-info/login.info";
import {BaggageTable} from "app/desktop/ui/pages/dashboard/components/baggage-table/baggage.table";
import {PaymentPage} from "app/desktop/ui/pages/dashboard/components/payment/payment.page";
import {SeatsPage} from "app/desktop/ui/pages/dashboard/components/seats/seats.page";
import {ExtraServicesPage} from "app/desktop/ui/pages/dashboard/components/extra-services/extra-services.page";
import {getActivePNR, getPNRSource, isSessionExists} from "app/services/session.service";
import {AgentLoginInfo} from "app/desktop/ui/components/agent-login-info/agent.login.info";
import {CheckinContent, CheckinPopup} from "app/desktop/checkin/checkin-popup/checkin.popup";
import {Preloader} from "app/desktop/ui/components/preloader/preloader";

@inject ("pnrStore", "authStore", "baggageStore", "dashboardUIStore","checkInPopup") @observer
class Dashboard extends React.Component {
    componentDidMount() {
        const isAdmin = this.props.authStore.isAgentAuthorized;

        if (!isSessionExists()) {
            if (isAdmin) {
                history.push('/search');
            } else {
               // history.push('/login');
                window.location.href = "/login";
            }

        } else {
            this.props.pnrStore.loadData(getActivePNR());
        }
    }

    render() {
        const s = this.props.dashboardUIStore;

        if (this.props.pnrStore.isLoading) {
            return <Preloader/>;
        }

        const pnrSource = getPNRSource();
        const diabledAEROSRC = (pnrSource) && pnrSource === "AEROCRS";

        let routes = !diabledAEROSRC &&
                <>
                <Route path="/dashboard/baggage">
                    <BaggageTable/>
                </Route>

        <Route path={"/dashboard/seats"}>
            <SeatsPage/>
        </Route>

        <Route path={"/dashboard/extra-services/"}>
            <ExtraServicesPage />
        </Route>

        <Route path={"/dashboard/payment"}>
            <PaymentPage/>
        </Route>
                    </>

        return (
            <Router history={history}>
                <div className={"cs-dashboard"}>
                    <div className={"cs-header"}>
                        <div className={"cs-background"}/>
                        <div className={"container"}>
                            <div className={"header-row"}>
                                <AgentLoginInfo/>

                                <LoginInfo/>

                                <div className={"main-logo"} onClick={() => {
                                    history.push('/dashboard');
                                    s.setStep(0);
                                }}/>

                            </div>
                        </div>
                    </div>
                    <div className={"container main"}>
                        <div className={"main-col-1"}>
                            <DashboardSteps />
                                <Switch>
                                    <Route
                                        exact
                                        path="/dashboard"
                                        render={() => {
                                            return (
                                                <DealDetails/>
                                            )
                                        }}
                                    />
                                    {routes}
                                </Switch>
                            </div>



                        <div className={"main-col-2"}>
                            <CheckinPopup>
                                <CheckinContent/>
                            </CheckinPopup>
                            <Sidebar/>
                        </div>


                    </div>
                    <div className={"cs-footer"}>
                        <div className={"container"}>
                            <div className={"footer-line"}></div>
                            UX/UI by dezion.today
                            <div className={"footer-links-container"}>
                                <div className={"footer-link"}><a href="https://www.kavei.co.il/GeneralInfo/ContactUs.aspx" target={"_blank"} rel="noopener noreferrer">צור קשר</a></div>
                                <div className={"footer-link"}><a href="https://www.kavei.co.il/GeneralInfo/Terms.aspx" target={"_blank"} rel="noopener noreferrer">תנאים כללים</a></div>
                                {process.env.REACT_APP_SHOW_CHECKIN_DEBUG_BUTTON === "1" ?
                                <button className={"webcheck-link"} onClick={() => {
                                    this.props.checkInPopup.openPopup();
                                }}>CHECK IN</button> : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </Router>
        )
    }
}

export {Dashboard}