import {inject, observer} from "mobx-react";
import React from "react";
import {ModalDialog} from "app/desktop/ui/components/modal/modal";
import 'app/desktop/ui/components/dialogs/dlg-add-seats/dlg.add.seats.scss';
import {RegularButton} from "app/desktop/ui/components/buttons/regular-button/regular.button";
import {BlueButton} from "app/desktop/ui/components/buttons/blue-button/blue.button";
import {history} from 'app/services/custom.router'
import {StepTypes} from "app/stores/steps.store";


@inject ("dashboardUIStore", "stepsStore") @observer
class DlgAddSeats extends React.Component {
    render() {
        const dlg = this.props.dashboardUIStore.addSeatsDialog;
        const s = this.props.dashboardUIStore;
        const stepsStore = this.props.stepsStore;

        return (
            <ModalDialog isOpen={dlg.isOpen} onClose={() => {
                dlg.closePopup();
            }}>
                <div className={"dlg-add-seats"}>
                    <div className={"wrap"}>
                        <div className={"header-image"}/>
                        <div className={"text"}>
                            בטוחים שאתם לא רוצים להזמין הושבה?<br/>
                            <strong> הכי כיף לשבת יחד בטיסה</strong><br/>
                            <strong>ואין אפשרות להזמין הושבה בשדה התעופה</strong>
                        </div>
                        <div className={"buttons"}>
                            <div className={"btn-1"}>
                                <RegularButton onClick={() => {
                                    dlg.closePopup();
                                    const nextStep = stepsStore.nextStep;
                                    stepsStore.setStep(nextStep.type);
                                    history.push(nextStep.path);
                                }}>הבנתי, נשב בנפרד</RegularButton>
                            </div>
                            <div className={"btn-2"}>
                                <BlueButton onClick={() => {
                                    dlg.closePopup();
                                    //TODO: check the steps here
                                    s.setStep(StepTypes.BAGGAGE);
                                    history.push('/dashboard/seats');
                                }}><span className={"symbol"}>+</span> <span>הזמן הושבה</span></BlueButton>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalDialog>
        )
    }
}

export {DlgAddSeats}