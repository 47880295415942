import {inject, observer} from "mobx-react";
import React from "react";
import {ModalSendMessage} from "app/desktop/ui/components/modal-send-message/modal.send.message";


@inject("baggageStore", "checkInUIStore", "pnrStore", "checkInPopup", "seatsStore", "dashboardUIStore") @observer
class SendBoardingPassForm extends React.Component {
    render() {

        const chk = this.props.checkInUIStore;
        const pnr = this.props.pnrStore;


        return (
            <ModalSendMessage
                type={chk.boardingPass.type}
                inputValue={chk.boardingPass.inputValue}
                performSendMessage={() => pnr.performSendBoardingPass()}
                handleSentMessage={() => {
                    chk.setBoardingPassDetails();
                    chk.closePopup();
                }}
                label={chk.boardingPass.label}
                updateValue={value => chk.setBoardingPassValue(value)}
                //error handler
                isOpen={chk.infoPopupOpened}
                closePopup={() => chk.closePopup()}
                boardingPassLoading={chk.boardingPassLoading}
                title={chk.boardingPass.title}
                btnText={chk.boardingPass.btnText}
            />
        )
    }
}

export {SendBoardingPassForm};