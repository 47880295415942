import React from 'react'
import 'app/desktop/ui/components/dialogs/dlg-seat-selection/components/legend/legend.scss'
import {inject, observer} from "mobx-react";

@inject("flight", "isWebCheckIn") @observer
class Legend extends React.Component {
    render() {
        const flight = this.props.flight;
        if (!flight && !this.props.isWebCheckIn) {
            return null;
        }

        return (
          <div className={"seats-legend"}>
              <div className={"seat"}>
                  <div className={"seat-icon legend type-a"}/>
                  <span> מושב פנוי ב <span className={"num"}>{flight.currencySymbol}{flight.seatPriceA}</span></span>
              </div>

              <div className={"seat"}>
                  <div className={"seat-icon legend type-b"}/>
                  <span>מושב פנוי ב <span className={"num"}>{flight.currencySymbol}{flight.seatPriceB}</span></span>
              </div>

              <div className={"seat"}>
                  <div className={"seat-icon legend type-c"}/>
                  <span>מושב מועדף (מרווח רגליים משודרג) ב-<span className={"num"}>{flight.currencySymbol}{flight.seatPriceC}</span></span>
              </div>

              <div className={"seat"}>
                  <div className={"seat-icon legend selected"}/>
                  <span>מושב נבחר</span>
              </div>

              <div className={"seat"}>
                  <div className={"seat-icon legend"}/>
                  <span>מושב תפוס</span>
              </div>
          </div>
        );
    }
}

export {Legend}