import {computed} from "mobx";
import access from 'safe-access';

class SidebarStore {

    constructor(root) {
        this.root = root;
    }

    @computed get orderType() {
        const d = this.root.pnrStore.data;
        if (!d) {
            return "";
        }

        return access(d, 'data.orderType') + " ל" + access(d, 'data.flights[0].legs[0].arrivalCity');
    }

    @computed get baggage() {
        return [];
    }

    @computed get seats() {
        return [];
    }

    @computed get other() {
        return [];
    }
}

export {SidebarStore}