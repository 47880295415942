import {ModalWarning} from "app/desktop/ui/components/modal-warning/modal.warning";
import SeatsCheckin from "app/desktop/images/seats-checkin.png";
import React from "react";
import {inject, observer} from "mobx-react";


@inject ("dashboardUIStore", "checkInUIStore", "baggageStore") @observer
class SeatsModal extends React.Component {
    render() {
        const dlg = this.props.dashboardUIStore.addSeatsCheckIn;
        const ticketPopup = this.props.dashboardUIStore.ticketCheckIn;
        const chk = this.props.checkInUIStore;
        const bg = this.props.baggageStore;

        return (
            <div className={"add-baggage-wrap"}>
                <ModalWarning
                    title={"seats"}
                    isOpen={dlg.isOpen}
                    onClose={() => {
                        dlg.closePopup()
                    }}
                    image={SeatsCheckin}
                    buttonText1='הזמן הושבה'
                    buttonText1Icon
                    buttonText2='הבנתי, נשב בנפרד'
                    dashboardStore ={dlg}
                    // open the boarding popup modal after this modal if no need to pay anything
                    activeScreen ={()=> bg.baggageTotalPriceByDirection <= 0? ticketPopup.openPopup(): chk.moveToNextScreen("forceNext")}>
                    <p>בטוחים שאתם לא רוצים לשבת יחד בטיסה?<br/> <strong>זוהי הזדמנות אחרונה להזמין הושבה, לאחר
                        סיום <br/>תהליך הצ&apos;ק-אין לא ניתן לבצע שינויים. </strong></p>
                </ModalWarning>
            </div>
        )
    }
}

export {SeatsModal};