import React from 'react'
import 'app/desktop/ui/pages/dashboard/components/seats/seats.page.scss'
import {StepTypes} from "app/stores/steps.store";
import {inject, observer} from "mobx-react";
import {SeatsTable} from 'app/desktop/ui/pages/dashboard/components/seats/seats.table.jsx';
import {Scrollbar} from "react-scrollbars-custom";
import {TableFooter} from "app/desktop/ui/pages/dashboard/components/deal-details/table.footer";

@inject("stepsStore", "pnrStore", "passengersStore") @observer
class SeatsPage extends React.Component {
    componentDidMount() {
        this.props.stepsStore.setStep(StepTypes.SEATS)
    }

    render() {

        const paidText = () => this.props.pnrStore.anySeatIsPaid &&
            <div className={"seats-table"}>
                <div className={"row header paid-text"}>
                    שים לב! לא ניתן לבצע שינויים לאחר רכישת הושבה.
                </div>
            </div>

        return (
            <div className={"cs-deal"}>
                <div className={"cs-deal-title"}>
                    <div className={"icon-seats-page"}/>
                    <span className={"main"}>בחירת הושבה</span>
                </div>

                <div className={"cs-deal-content"}>
                    <Scrollbar noDefaultStyles>
                        <div className={"seats-page"}>
                            <SeatsTable/>
                            {paidText()}
                        </div>
                    </Scrollbar>
                </div>
                <TableFooter currency={this.props.pnrStore.currencySymbol}
                             price={this.props.passengersStore.totalSeatsPrice}>
                    סה״כ תוספת תשלום<br/>
                    עבור הוספת הושבה
                </TableFooter>
            </div>
        )
    }
}

export {SeatsPage}