import {inject, observer} from "mobx-react";
import React from "react";

@inject("baggageStore", "pnrStore", "dashboardUIStore", "isWebCheckIn") @observer
class BaggageSidebarTableInner extends React.Component {
    constructor(props) {
        super(props);

        this.myRef = React.createRef();
    }
    render() {

        const s = this.props.baggageStore;
        const {isWebCheckIn} = this.props;

        if (s.totalBaggagePrice === 0 && s.isBaggageAvailable && !s.didAllUsersSelectAnyOption) {
            return <div className={"alert"}>
                <div className={"icon-baggage-b"}/>לא נבחרה<br/>
                כבודה</div>
        }

        if (s.totalBaggagePrice === 0) {
            return null;
        }

        const statsTo = s.baggageToStats,
            statsBack = s.baggageBackStats;

        const baggage_statsToRow = (statsTo.baggageCount === 0) ? "" : (
            <div className={"st-row"}>
                <div className={"col1"}>{statsTo.baggageCount} מזוודות לכיוון הלוך</div>
                <div className={"col2"}>
                    {this.props.pnrStore.currencySymbol}{statsTo.baggagePrice}
                </div>
            </div>
        );

        const trolley_statsToRow = (statsTo.trolleyCount === 0) ? "" : (
            <div className={"st-row"}>
                <div className={"col1"}>{statsTo.trolleyCount} טרולי לכיוון הלוך</div>
                <div className={"col2"}>
                    {this.props.pnrStore.currencySymbol}{statsTo.trolleyPrice}
                </div>
            </div>
        );

        const baggage_statsBackRow = (statsBack.baggageCount === 0) ? "" : (
            <div className={"st-row"}>
                <div className={"col1"}>{statsBack.baggageCount} מזוודות לכיוון חזור</div>
                <div className={"col2"}>
                    {this.props.pnrStore.currencySymbol}{statsBack.baggagePrice}
                </div>
            </div>
        );

        const trolley_statsBackRow = (statsBack.trolleyCount === 0) ? "" : (
            <div className={"st-row"}>
                <div className={"col1"}>{statsBack.trolleyCount} טרולי לכיוון חזור</div>
                <div className={"col2"}>
                    {this.props.pnrStore.currencySymbol}{statsBack.trolleyPrice}
                </div>
            </div>
        );

        const dlg = this.props.dashboardUIStore.baggageDetailsDialog;


        return (<React.Fragment>
            <div className={"sidebar-table"}>
                {trolley_statsToRow}
                {baggage_statsToRow}
                {trolley_statsBackRow}
                {baggage_statsBackRow}
                {!isWebCheckIn &&
                    <div className={"st-row total"}>
                        <div className={"col1"}>
                            <a href={"#"} ref={this.myRef} onClick={(e) => {

                                e.preventDefault();
                                const v = this.myRef.current.getBoundingClientRect();
                                // console.log("click event", JSON.stringify(v));
                                dlg.setPosition(v.x, v.y);
                                this.props.dashboardUIStore.baggageDetailsDialog.openPopup();
                            }}>הקלק לפירוט מלא</a>
                        </div>
                        <div className={"col2 total-price"}>
                            <span className={"heb"}>סה״כ</span> {s.currency}{s.totalBaggagePrice}
                        </div>
                    </div>
                }
            </div>

        </React.Fragment>)
    }
}


export {BaggageSidebarTableInner}