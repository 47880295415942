import React from 'react';
import './checkin.popup.scss';
import {inject, observer} from "mobx-react";
import classnames from "classnames";
import access from "safe-access";

@inject("checkInUIStore", "pnrStore", "checkInPopup") @observer
class CheckinPopup extends React.Component {
    render() {
        const popup = this.props.checkInPopup;
        const checkInStatus = access(this.props.pnrStore.checkInData, 'checkInStatus.name');

        if (!checkInStatus || checkInStatus === "Invisible") {
        //todo: add check for the case if we get a name which is not supported

        // if (!checkinObj.checkInStatus.name || checkInInvisible) {
            return null;
        }

        // const cssMark = classnames("checkin-mark", { "checkin-mark-green": fullyCheckedIn });
        const cssMark = classnames("checkin-mark", { "checkin-mark-green": checkInStatus === "FullycheckedIn"|| checkInStatus === "PartialCheckedIn" });

        const cssClockWrap = classnames("clock-wrap", {"checkedIn": checkInStatus === "FullycheckedIn" || checkInStatus === "PartialCheckedIn"});
        const cssClockHour = classnames("clock-hour", {"checkedIn": checkInStatus === "FullycheckedIn" || checkInStatus === "PartialCheckedIn"});
        const cssClockMinute = classnames("clock-minute", {"checkedIn": checkInStatus === "FullycheckedIn" || checkInStatus === "PartialCheckedIn"});

        return (
            <div className={"checkin-wrapper"}>
                <div className={cssClockWrap}>
                    <div className={cssClockHour}></div>
                    <div className={cssClockMinute}></div>
                    <div className={cssMark}></div>
                </div>
                {this.props.children}
                {checkInStatus === "Enabled" && <div onClick={()=>popup.openPopup()} className={"chk-enabled-arrow"}><a className={"arrow-left"}/></div>}
            </div>
        );
    }
}

export {CheckinPopup};

@inject("checkInUIStore", "checkInPopup", "pnrStore", "baggageStore") @observer
class CheckinContent extends React.Component {

    render() {
        const chk = this.props.checkInUIStore;
        const popup = this.props.checkInPopup;
        const pnr = this.props.pnrStore;

        const checkInStatus = access(this.props.pnrStore.checkInData, 'checkInStatus.name');

        const cssTitle = classnames("chk-title", {"title-done" : checkInStatus === "FullycheckedIn" || checkInStatus === "PartialCheckedIn"}, {"title-disabled": checkInStatus === "Disabled"})

        const message = access(pnr.checkInData, 'message');

        const downloadText = chk.isLoading? "טוען" : "הורד קובץ";

        const handleDisabled = () => (
            <div>{message}</div>
        );

        const handleFullyCheckedIn = () => (
            <div>
                <a className="chk-link" onClick={()=>{
                    chk.informationPopup();
                    chk.setBoardingPassDetails("phone");
                }}>שלח לנייד</a>
                <a className="chk-link" onClick={()=> {
                    chk.informationPopup();
                    chk.setBoardingPassDetails("email");
                }}>שלח לדוא"ל</a>
                <a className="chk-link" onClick={()=>pnr.performDownloadBoardingPass()}>{downloadText}</a>
            </div>
        );

       const textContent = () => {
            switch (checkInStatus) {
                case "Enabled":
                    return null;
                case "Disabled":
                    return handleDisabled();
                case "FullycheckedIn":
                    return handleFullyCheckedIn();
                case "PartialCheckedIn":
                    return handleFullyCheckedIn();
                default:
                    return null;
            }
        }

        const enabledTitle = checkInStatus === "Enabled"? <div className={"enabled-check-in"}><a onClick={()=>popup.openPopup()}>{chk.title}</a></div> :<div className={cssTitle}>{chk.title}</div>;
        const enabledText = checkInStatus !== "Enabled" && <div className={"chk-text"}>{textContent()}</div>

        return (
            <div className={"chk-content-wrap"}>
                {enabledTitle}
                {enabledText}
            </div>
        )

    }
}

export {CheckinContent};
