import {action, computed, observable} from "mobx";
import ServiceTypes from "app/enums/service.types";

const filter_selected_baggage = (s)  => (s.serviceType === ServiceTypes.FirstBaggage && s.isSelected);
const filter_selected_trolley = (s)  => (s.serviceType === ServiceTypes.CarryOnBag && s.isSelected);

class Passenger {
    @observable selectedToBaggage = null;
    @observable selectedBackBaggage = null;
    @observable chosenToBaggage = null;
    @observable chosenBackBaggage = null;


    constructor(id, fullName, baggageToOptions, baggageFromOptions, hasTrolleyTo, hasTrolleyBack, hasBaggageTo, hasBaggageBack) {
        this.id = id;
        this.fullName = fullName;

        this.baggageToOptions = baggageToOptions;
        this.baggageBackOptions = baggageFromOptions;

        this.hasTrolleyTo = hasTrolleyTo;
        this.hasTrolleyBack = hasTrolleyBack;

        this.hasBaggageTo = hasBaggageTo;
        this.hasBaggageBack = hasBaggageBack;        
    }

    @action resetSelected() {
        this.baggageToOptions.forEach((s) => {
            s.isSelected = false;
        })

        this.baggageBackOptions.forEach((s) => {
            s.isSelected = false;
        })
    }

    @computed get selectedBaggagePriceTo () {
        return this.baggageToOptions.filter(filter_selected_baggage).reduce((acc, s) => {
            return acc + s.price;
        },0);
    }

    @computed get selectedBaggagePriceBack () {
        return this.baggageBackOptions.filter(filter_selected_baggage).reduce((acc, s) => {
            return acc + s.price;
        },0);
    }
    @computed get selectedBaggagePrice () {
        return this.selectedBaggagePriceTo + this.selectedBaggagePriceBack;
    }

    @computed get selectedTrolleyPriceTo () {
        return this.baggageToOptions.filter(filter_selected_trolley).reduce((acc, s) => {
            return acc + s.price;
        },0);
    }

    @computed get selectedTrolleyPriceBack () {
        return this.baggageBackOptions.filter(filter_selected_trolley).reduce((acc, s) => {
            return acc + s.price;
        },0);
    }

    @computed get selectedTrolleyPrice () {
        return this.selectedTrolleyPriceTo + this.selectedTrolleyPriceBack;
    }

    @computed get selectedBaggageDirection () {
        const b1 = this.baggageToOptions.findIndex((s) => s.isSelected) >= 0;
        const b2 = this.baggageBackOptions.findIndex((s) => s.isSelected) >= 0;

        if (b1 && b2) {
            return "הלוך ושוב";
        }

        if (b1) {
            return "הלוך בלבד";
        }

        if (b2) {
            return "חזור בלבד";
        }

        return "";
    }

    @computed get selectedBaggageToCount() {
        return this.baggageToOptions.filter(filter_selected_baggage).reduce((acc, s) => {
            return acc + 1;
        }, 0);
    }

    @computed get selectedBaggageBackCount() {
        return this.baggageBackOptions.filter(filter_selected_baggage).reduce((acc, s) => {
            return acc + 1;
        }, 0);
    }

    @computed get selectedBaggageCount() {
        return this.selectedBaggageToCount + this.selectedBaggageBackCount;
    }

    @computed get selectedTrolleyToCount() {
        return this.baggageToOptions.filter(filter_selected_trolley).reduce((acc, s) => {
            return acc + 1;
        }, 0);
    }

    @computed get selectedTrolleyBackCount() {
        return this.baggageBackOptions.filter(filter_selected_trolley).reduce((acc, s) => {
            return acc + 1;
        }, 0);
    }

    @computed get selectedTrolleyCount() {
        return this.selectedTrolleyToCount + this.selectedTrolleyBackCount;
    }

    @action setBaggageToOption(option) {
        if (option) {
            this.selectedToBaggage = option;
        }
    }

    @action setBaggageBackOption(option) {
        if (option) {
            this.selectedBackBaggage = option;
        }
    }

    @action resetToCurrent() {
        this.selectedToBaggage = null;
        this.chosenToBaggage = null;
    }

    @action resetBackCurrent() {
        this.selectedBackBaggage = null;
        this.chosenBackBaggage = null;
    }


    @action chooseBaggageToOption(option) {
        this.resetToCurrent();
        this.chosenToBaggage = option;
    }

    @action chooseBaggageBackOption(option) {
        this.resetBackCurrent();
        this.chosenBackBaggage = option;
    }

    @computed get selectedBaggageToOptionName() {
        return (this.selectedToBaggage) ? this.selectedToBaggage.text : "";
    }

    @computed get selectedBaggageBackOptionName() {
        return (this.selectedBackBaggage) ? this.selectedBackBaggage.text : "";
    }

    @computed get chosenBaggageToOptionNameWithPrice() {
        return (this.chosenToBaggage) && this.chosenToBaggage.name;
    }

    @computed get chosenBaggageBackOptionNameWithPrice() {
        return (this.chosenBackBaggage) && this.chosenBackBaggage.name;
    }

    //filter baggage options without ללא מזודה
    @computed get baggageToOptionWithGIUD() {
        return this.baggageToOptions.length > 0 && this.baggageToOptions.filter(option => option.guid);
    }

    @computed get baggageBackOptionWithGIUD() {
        return this.baggageBackOptions.length > 0 && this.baggageBackOptions.filter(option => option.guid);
    }

    //return only free options (for now only ללא מזודה)
    @computed get baggageToOptionWithoutGIUD() {
        return this.baggageToOptions.length > 0 && this.baggageToOptions.filter(option => !option.guid);
    }

    @computed get baggageBackOptionWithoutGIUD() {
        return this.baggageBackOptions.length > 0 && this.baggageBackOptions.filter(option => !option.guid);
    }
}

export {Passenger}