import {inject, observer} from "mobx-react";
import React from "react";
import classnames from "classnames";

@inject ( 'isWebCheckIn') @observer
class SidebarRow extends React.Component {
    render() {
        const {isWebCheckIn} = this.props;
        const css = classnames("cs-sidebar-row", {"web-check-row" : isWebCheckIn});
        return (
            <div className={css}>
                <div className={"title"}>{this.props.title}</div>
                {this.props.children}
            </div>
        );
    }
}

SidebarRow.defaultProps = {
    title : ""
}

export {SidebarRow};