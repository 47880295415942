import React from 'react'
import {Popup} from "app/desktop/ui/components/popup/popup";
import 'app/desktop/ui/components/dialogs/dlg-hotel-details/dlg.hotel.details.scss';
import {inject, observer} from "mobx-react";
import {DlgHotelDetailsInner} from "app/desktop/ui/components/dialogs/dlg-hotel-details/dlg.hotel.details.inner";

@inject ("dashboardUIStore","pnrStore") @observer
class DlgHotelDetails extends React.Component {
    render() {
        const dlg = this.props.dashboardUIStore.hotelDetailsDlg;
        return (
            <Popup isOpen={dlg.isOpen} onClose={() => {
                dlg.closePopup();
            }}>
                <div className={"dlg-body"}>
                    <div className={"dlg-title"}><div className={"icon-hotel-details"}/> פרטי מלון</div>
                    <div className={"icon-print"} onClick={(e) => {
                        e.preventDefault();

                        window.print();
                    }}/>
                    <DlgHotelDetailsInner/>
                </div>
            </Popup>
        )
    }
}

export {DlgHotelDetails}