import React from 'react';
import "app/desktop/ui/components/buttons/regular-button/regular.button.scss";

class RegularButton extends React.Component {
    render() {
        return (
            <div className={"btn btn-regular"} onClick={this.props.onClick}>
                {this.props.children}
            </div>
        )
    }
}

RegularButton.defaultProps = {
    onClick : () => {}
}

class RegularButtonBig extends React.Component {
    render() {
        return (
            <div className={"btn btn-regular big"} onClick={this.props.onClick}>
                {this.props.children}
            </div>
        )
    }
}

RegularButtonBig.defaultProps = {
    onClick : () => {}
}


class RegularButtonSmall extends React.Component {
    render() {
        return (
            <div className={"btn btn-regular small"} onClick={this.props.onClick}>
                {this.props.children}
            </div>
        )
    }
}

RegularButtonSmall.defaultProps = {
    onClick : () => {}
}

export {RegularButton, RegularButtonBig, RegularButtonSmall}