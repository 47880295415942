import React from 'react';
import 'app/desktop/ui/pages/dashboard/components/baggage-table/baggage.table.scss';
import {inject, observer} from "mobx-react";
import {StepTypes} from "app/stores/steps.store";
import {Scrollbar} from "react-scrollbars-custom";
import {BaggageTableInfo} from "app/desktop/ui/pages/dashboard/components/baggage-table/modal";
import {Header} from "app/desktop/ui/pages/dashboard/components/baggage-table/baggage.header";
import {Footer} from "app/desktop/ui/pages/dashboard/components/baggage-table/baggage.footer";
import {ImportantInfoComponent} from "app/desktop/ui/pages/web-check-in/information.section";
import {BaggageContent} from "app/desktop/ui/pages/dashboard/components/baggage-table/baggage.content";


// const DropdownDisabled = (props) => {
//     return props.count > 1;
//     // if (props.count > 1) {
//     //     return null;
//     // }
//     //
//     // return (
//     //     <div className={"sub-row message"}>
//     //         לא ניתן לבחור מזוודה לכיוון זה
//     //     </div>
//     // );
// }


class TableWrapper extends React.Component {
    render() {
        return (
            <div className={"baggage-table"}>
                <div className={"section"}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}


@inject("baggageStore", "stepsStore", "passengersStore", "pnrStore") @observer
class BaggageTable extends React.Component {
    componentDidMount() {
        if (!this.props.baggageStore.root.isWebCheckIn) {
            this.props.stepsStore.setStep(StepTypes.BAGGAGE);
        }
    }

    render() {
        const {baggageStore} = this.props;
        const pnr = this.props.pnrStore;

        return (
            <div className={"cs-deal"}>
                <Header/>
                <BaggageTableInfo baggage={baggageStore}/>
                <div className={"cs-deal-content"}>
                    <Scrollbar noDefaultStyles>
                        <TableWrapper>
                                <BaggageContent
                                    direction={"טיסת הלוך"}
                                    airline={pnr.date1Airline}
                                />
                            {!pnr.isOneWayFlight &&
                                <BaggageContent
                                    direction={"טיסת חזור"}
                                    airline={pnr.date1Airline}
                                    secondFlight/>
                            }
                        </TableWrapper>
                    </Scrollbar>
                </div>
                <Footer/>
            </div>
        )
    }
}

@inject("baggageStore", "passengersStore", "checkInUIStore", "pnrStore") @observer
class BaggageTableWebCheck extends React.Component {

    render() {
        const {baggageStore} = this.props;
        const pnr = this.props.pnrStore;
        const {curDirection} = this.props.checkInUIStore;

        return (
            <div className={"cs-deal"}>
                <Header/>
                <ImportantInfoComponent show/>
                <BaggageTableInfo baggage={baggageStore}/>
                <div className={"cs-deal-content"}>
                    <TableWrapper>
                            <BaggageContent
                            secondFlight={curDirection === 1}
                            airline={curDirection === 0? pnr.date1Airline: pnr.date2Airline}
                            />
                    </TableWrapper>
                </div>
            </div>

        )
    }
}


export {BaggageTable, BaggageTableWebCheck};