import {inject, observer} from "mobx-react";
import React from "react";
import './confirmation.web.check.scss';
import {RegularButton} from "app/desktop/ui/components/buttons/regular-button/regular.button";
import {SendBoardingPassForm} from "app/desktop/ui/components/modal-send-message/send.boarding.pass.form";

@inject("dashboardUIStore", "seatsStore", "pnrStore", "baggageStore", "checkInUIStore") @observer
class ConfirmationWebCheck extends React.Component {
    render() {
        const pnr = this.props.pnrStore;
        const chk = this.props.checkInUIStore;

        const handleSendBoardingPassPhone = () => {
            chk.informationPopup();
            chk.setBoardingPassDetails("phone");
        };

        const handleSendBoardingPassEmail = () => {
            chk.informationPopup();
            chk.setBoardingPassDetails("email");

        };

        const handleDownloadBoardingPass = () => {
            pnr.performDownloadBoardingPass();
        };

        const downloadText = chk.isLoading? "טוען" : "הורד קובץ";

        return (
            <>
               <SendBoardingPassForm/>
                <div className={"confirmation-wrapper"}>
                    <div className={"inner-div boarding-pass-img"}></div>
                    <div className={"inner-div text-section"}>
                        <div className={"small-text"}>צ’ק-אין</div>
                        <div className={"large-text"}>בוצע בהצלחה</div>
                        <div className={"small-text"}>לאן לשלוח את המסמכים?</div>
                    </div>
                    <div className={"inner-div buttons-section"}>
                        <RegularButton onClick={() => handleSendBoardingPassPhone()}>
                            <div className={"confirmation-button"}>שלח לנייד</div>
                        </RegularButton>
                        <RegularButton onClick={() => handleSendBoardingPassEmail()}>
                            <div className={"confirmation-button"}>שלח לדוא״ל</div>
                        </RegularButton>
                        <RegularButton onClick={()=> handleDownloadBoardingPass()}>
                            <div className={"confirmation-button"}>{downloadText}</div>
                        </RegularButton>
                    </div>
                </div>
            </>
        );
    }
}

export {ConfirmationWebCheck};