import React from 'react'
import 'app/desktop/ui/pages/dashboard/components/payment/payment.page.scss'
import {inject, observer} from "mobx-react";
import {StepTypes} from "app/stores/steps.store";
import {Scrollbar} from "react-scrollbars-custom";
import {RegularButtonBig} from "app/desktop/ui/components/buttons/regular-button/regular.button";
import {history} from "app/services/custom.router";
import {Header} from "app/desktop/ui/pages/dashboard/components/payment/header/payment.page.header";
import {PaymentPageContent} from "app/desktop/ui/pages/dashboard/components/payment/content/payment.page.content";
import {InitiatePayment} from "app/desktop/ui/pages/dashboard/components/payment/content/payment.button";
import {SidebarWebCheck} from "app/desktop/ui/components/sidebar/sidebar";


@inject('paymentStore', 'dashboardUIStore', 'extraServicesStore', 'baggageStore', 'stepsStore', 'seatsStore') @observer
class Footer extends React.Component {

    render() {
        const onClick = (e) => {

            e.preventDefault();
            const previousStep = this.props.stepsStore.previousStep;
            this.props.stepsStore.setStep(previousStep.type);
            history.push(previousStep.path);
        }

        return (
            <div className={"cs-deal-footer payment"}>
                <div className={"wrap"}>
                    <div className={"cards-icon"}/>

                    <div className={"col-1"}>
                        <RegularButtonBig onClick={onClick}>חזור <div className={"icon-back"}/></RegularButtonBig>
                    </div>
                    <InitiatePayment/>

                </div>
            </div>
        )
    }
}



@inject('paymentStore', 'dashboardUIStore', 'extraServicesStore', 'baggageStore', 'stepsStore') @observer
class PaymentPage extends React.Component {

    componentDidMount() {
        this.props.stepsStore.setStep(StepTypes.PAYMENT)
    }

    render() {


        return (
            <div className={"cs-deal"}>
                <div className={"cs-deal-content"}>
                    <Scrollbar noDefaultStyles>
                        <Header/>
                        <PaymentPageContent/>
                    </Scrollbar>
                </div>
                <Footer/>
            </div>
        )
    }
}

export {PaymentPage}

@inject("dashboardUIStore", "paymentStore", "pnrStore", "baggageStore", "authStore") @observer
class PaymentPageWebCheck extends React.Component {

    render() {
        return (
          <div className={"payment-page-wrapper"}>
                <div className={"main-col-1"}>
                    <div className={"cs-deal"}>
                        <Header/>
                        <PaymentPageContent/>
                    </div>
                </div>
                <SidebarWebCheck/>
            </div>
        )
    }
}

export {PaymentPageWebCheck};