import React from 'react'
require('./gender.scss');

class Gender extends React.Component {

    constructor(props) {
        super(props);
        this.toogleGender = this.toogleGender.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    toogleGender = () => {
        const isMale = this.props.gender.toLowerCase() === "male";
        const newGender = isMale ? 'Female' : 'Male';
        this.props.passengers.setAttributeField("gender", newGender, this.props.passenger);
    };

    handleClick = (gender, innerText) => {
        if (this.props.gender.toLowerCase() === 'undetermined') {
            this.props.passengers.setAttributeField("gender", innerText, this.props.passenger);
            return;
        }
        return !gender ? this.toogleGender() : null;
    };

    render() {
        const isMale = this.props.gender.toLowerCase() ==="male";
        const isFemale = this.props.gender.toLowerCase() ==="female";

        const maleCss = isMale ? "gen active" : "gen"
        const femaleCss = isFemale ? "gen active" : "gen";


        return (
            <div className={"p-gender"}>
                <div className={maleCss} onClick={()=> this.handleClick(isMale, "Male")}>Male</div>
                <div className={femaleCss} onClick={()=> this.handleClick(isFemale, "Female")}>Female</div>
            </div>
        );
    }
}

Gender.defaultProps = {
    "gender" : "Male"
}
export default Gender;