import {action, computed, observable} from "mobx";
import transport from "../services/transport";
import access from 'safe-access';

class CountriesStore {
    @observable isLoading = false;
    @observable data = null;

    constructor(root, transport) {
        this.root = root;
        this.transport = transport;
    }


    @action fetchCountries() {
        this.isLoading = true;
        this.data = null;

        transport.fetchCountriesData()
            .then((response) => {
                // console.log("countries", response.data);
                this.data = response.data;
                this.isLoading = false;
            })
            .catch((e) => {
                // console.log("error",e);
                this.data = null;
                this.isLoading = false;
            })
    }

    @computed get allCountries() {
        const countries = access(this.data,'data');
        return countries && countries.map(({nationalityName, ...rest}) => {
            return {
                ...rest,
                name: nationalityName
            }
        });
    }

    @computed get filteredCountries() {
        const countries = access(this.data,'data');

        return countries && countries.filter((i)=> {
            return i.nationalityCode === 'IL';
        }).map(({nationalityName, ...rest}) => {
            return {
                ...rest,
                name: nationalityName
            }
        });

    }

    @computed get countryNames() {
        const countries = access(this.data,'data');
        return countries && countries.map(obj => obj.nationalityName);
    }

}

export {CountriesStore}