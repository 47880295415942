import {Scrollbar} from "react-scrollbars-custom";
import ModalBaggage from "app/desktop/ui/components/modal-baggage/modal.baggage";
import React from "react";
import {inject, observer} from "mobx-react";

let TrolleyLogo,BackpackLogo,LaptopLogo,SmallBagLogo,BigTrolleyLogo;

if (process.env.REACT_APP_THEME === 'bluebird') {
    TrolleyLogo = require('app/desktop/images/bluebird/trolley.svg');
    BackpackLogo = require('app/desktop/images/bluebird/backpack.svg');
    LaptopLogo = require('app/desktop/images/bluebird/laptop-bag.svg');
    SmallBagLogo = require('app/desktop/images/bluebird/small-bag.svg');
    BigTrolleyLogo = require('app/desktop/images/bluebird/big-trolley.svg');
    } else {
    TrolleyLogo = require('app/desktop/images/trolley.svg');
    BackpackLogo = require('app/desktop/images/backpack.svg');
    LaptopLogo = require('app/desktop/images/laptop-bag.svg');
    SmallBagLogo = require('app/desktop/images/small-bag.svg');
    BigTrolleyLogo = require('app/desktop/images/big-trolley.svg');   
}

@inject("baggageStore", "stepsStore") @observer
class BaggageTableInfo extends React.Component {
    render() {

    return (
    <ModalBaggage isOpen={this.props.baggage.infoPopupOpened} onClose={() => this.props.baggage.closePopup()}>
        <Scrollbar noDefaultStyles>
            <div className={"baggage-table-wrap"}>
                <div className={"bp-section"}>
                    <h1 className={"bp-header"}>פירוט כבודה כלולה</h1>
                    <p className={"bp-text"}>כל נוסע רשאי להביא עמו לטיסה - ללא עלות,<br/>
                        <u className={"bg-underline"}>פריט אישי בודד</u>, שהינו אחד מאלה:</p>
                </div>
                <div className="icon-row">
                    <div className="icon-container">
                        <img alt={"backpack"} src={BackpackLogo} className="icon"/>
                        <h3>תיק גב קטן</h3>
                    </div>
                    <span className="separator">או</span>
                    <div className="icon-container">
                        <img alt={"laptop"} src={LaptopLogo} className="icon"/>
                        <h3>תיק לפטופ</h3>
                        <p className={"bp-text"}></p>
                    </div>
                    <span className="separator">או</span>
                    <div className="icon-container">
                        <img alt={"small-bag"} src={SmallBagLogo} className="icon"/>
                        <h3>תיק צד קטן</h3>
                        <p className={"bp-text"}></p>
                    </div>
                </div>

                <div className={"icon-row text-row"}>
                    פריט אישי נדרש להתאים לאחסון מתחת למושב שלפניך, במידות של עד 20x30x40 ס"מ
                </div>

                <div className={"bp-section"}>
                    <h1 className={"bp-header"}>פירוט כבודה בתשלום</h1>
                    <p className={"bp-text"}>כל פריט שאינו נחשב כפריט אישי <br/>ונדרש לשלם בגינו בנפרד:
                    </p>
                </div>
                <div className="icon-row second-row">
                    <div className="icon-container">
                        <div className={"multi-icons"}>
                            <img alt={"trolley"} src={TrolleyLogo} className="icon"/>
                            <img alt={"big-trolley"} src={BigTrolleyLogo} className="icon"/>
                        </div>
                        <h3>מזוודה</h3>
                        <p className={"bp-text"}>
                            משקל עד 20 ק״ג
                            <br/>
                            מידה מקסימלית <span className={"ew"}>(אורך + רוחב + גובה)</span> 158 ס״מ
                        </p>
                    </div>
                    <div className="icon-container">
                    <img alt={"trolley"} src={TrolleyLogo} className="icon"/>
                        <h3>טרולי</h3>
                        <p className={"bp-text"}> משקל עד 8 ק״ג<br/>
                            מקסימום 23x40x51 ס"מ
                        </p>
                    </div>
                </div>

                <div>
                    <div className={"bp-footer-text"}>
                        <ul>
                            <li> שים לב! הגעה לשדה התעופה עם כבודה במשקל/מידות גבוהים מהאמור לעיל תחויב בתשלום נוסף בשדה התעופה.</li>
                            <li>פריט אישי שאינו מתאים לאחסון מתחת למושב חייב בתשלום.</li>
                            <li>טרולי במשקל העולה על 8 ק"ג ייחשב כמזוודה ויחוייב בהתאם בשדה התעופה.</li>
                            <li>פריטים שנקנו בדיוטי-פרי עשויים להיחשב כפריט אישי ולפיכך הבאתם למטוס יחד עם פריט אישי נוסף תחויב בתשלום.</li>
                            <li>על רקע מגבלות תפוסה בתאי האחסון בתא הנוסעים, ייתכן כי לא תורשו לעלות עם טרולי למטוס (גם אם הוא עומד במידות ובמשקלים המצוינים לעיל).</li>
                            <li>אנא ודאו כי חפצים יקרי ערך ו/או חיוניים (ציוד אלקטרוני, תרופות, תכשיטים ועוד) לא יהיו בכבודה הנשלחת לבטן המטוס.</li>
                            <li>
                                על מנת להימנע מאי נעימויות ועיכובים מיותרים בשדה ועל מנת להבטיח את בטיחות הטיסה, אנא בדקו את <a rel={"noopener noreferrer"} target="_blank"
                                                                                                                                href={"https://booking.bluebirdair.com/TRAVEL-INFORMATION/Dangerous-Goods-and-Restricted-Items"}>רשימת<br/>
                                החומרים האסורים</a> ואת ה<a rel={"noopener noreferrer"} target="_blank" href={"https://booking.bluebirdair.com/TRAVEL-INFORMATION/Liquids-and-Food-Allowance"}>כללים להעלאת נוזלים ומזון לטיסה.</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </Scrollbar>
    </ModalBaggage>
    )}}

export {BaggageTableInfo}
