import { action, computed, observable} from "mobx";

class Passenger {
    @observable id = "";
    @observable fullName = "";
    @observable title = "";
    @observable gender = "";
    @observable passengerType = null;

    @observable passengerServices = [];

    @observable seats = [null, null];


    constructor(id, fullName, title, gender, passengerType) {
        this.id  = id;
        this.fullName = fullName;
        this.title = title;
        this.gender = gender;
        this.passengerType = passengerType;
    }

    @action setSeat(flightIndex, seat) {
        if (flightIndex < 0 || flightIndex > this.seats.length-1) {
            console.error("wrong flightIndex");
        }

        this.seats[flightIndex] = seat;
    }

    @action clearSeat (flightIndex) {
        if (flightIndex < 0 || flightIndex > this.seats.length-1) {
            console.error("wrong flightIndex");
        }

        const seat = this.seats[flightIndex];

        if (seat) {
            seat.setSelected(false);
        }


        //TODO: fix the workaround
        if (this.seats[flightIndex]) {
            this.seats[flightIndex] = null;
        }
        console.log(this.seats)
        // this.seats[flightIndex] = {...this.seats[flightIndex], number: null};
    }

    @computed get seat1 () {
        return (this.seats.length > 0) ? this.seats[0] : null;
    }

    @computed get seat2 () {
        return (this.seats.length > 1) ? this.seats[1] : null;
    }

    @computed get seat1Price () {
        if (!this.seat1) {
            return 0;
        }

        return this.seat1.price;
    }

    @computed get seat2Price () {
        if (!this.seat2) {
            return 0;
        }

        return this.seat2.price;
    }

    @computed get displayName() {
        return this.title + " " + this.fullName;
    }

    @computed get seat1Number() {
        return this.seat1 ? this.seat1.number : "";
    }

    @computed get seat2Number() {
        return this.seat2 ? this.seat2.number : "";
    }

    @computed get selectedSeatsPrice() {
        // console.log("selectedSeatsPrice");

        let totalPrice = this.seat1 ? this.seat1.price : 0;
        totalPrice += (this.seat2) ? this.seat2.price : 0;

        return totalPrice;
    }

}

export {Passenger}