import {inject, observer} from "mobx-react";
import React from "react";
import classnames from "classnames";
import {NavigationButtons} from "app/desktop/ui/components/dialogs/dlg-seat-selection/components/navigation.buttons";

@inject("dashboardUIStore", "seatsStore") @observer
class ScreenWrapper extends React.Component {
    render() {
        const dlg = this.props.dashboardUIStore.seatSelectionDialog;
        const s = this.props.seatsStore;
        const {children} = this.props;
        const css = classnames("cs-popup", {"open": s.isBooking || dlg.isOpen});

        const externalClick = (e) => {
            e.stopPropagation();
            if (this.props.closeOnExternal) {
                dlg.closePopup();
            }
        }

        return (
            <div className={css}>
                <div className="cs-popup-bg" onClick={externalClick}/>
                <div className="cs-popup-wrap">
                    <div className="sub-wrap">
                        {children}
                    </div>
                    {!s.isBooking && <NavigationButtons/>}
                </div>
            </div>
        )
    }
}

ScreenWrapper.defaultProps = {
    closeOnExternal: true
}

export {ScreenWrapper};