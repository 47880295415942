import React from "react";
import {inject, observer} from "mobx-react";
import {history} from 'app/services/custom.router'
import {clearPnrSession, getActiveAccessToken, getAgentAccessToken} from "app/services/session.service";


@inject("authStore") @observer
class AgentLoginInfo extends React.Component {
    render() {
        if (!getAgentAccessToken()) {
            return null;
        }

        return (
            <div className={"cs-header-login agent"}>
                <div className={"icon icon-search"}/>
                <div className={"account"}/>
                <a href={"#"} onClick={(e) => {
                    e.preventDefault();
                    clearPnrSession();

                    //history.push('/search');
                    window.location.href="/search";
                }}>חפש הזמנה חדשה</a>
            </div>
        )
    }
}

export {AgentLoginInfo}