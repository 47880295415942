import React from 'react';
import { ReactComponent as InfoImg } from "app/desktop/images/icons/information-icon.svg";
import { ReactComponent as InfoImgRed } from "app/desktop/images/icons/information-icon-red.svg";
import 'app/desktop/ui/components/info-icon/info.icon.scss';

const InfoIcon = ({ text, red }) => {
    const icon = red ? <InfoImgRed /> : <InfoImg />;
    const textClass = red ? 'cs-info-red-text' : '';

    return (
        <i className="cs-info-icon">
            {icon}
            <span className={textClass}>{text || 'מידע חשוב!'}</span>
        </i>
    );
};

export default InfoIcon;